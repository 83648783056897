import { Table, InputGroup, Button, Dropdown, Form } from "react-bootstrap";
//import ChevronDownIcon from "mdi-react/ChevronDownIcon";
// import ChevronUpIcon from "mdi-react/ChevronUpIcon";
import CachedIcon from "mdi-react/CachedIcon";
import { CSVLink } from "react-csv";
import {
  // CalendarIcon,
  // CubeIcon,
  DeleteIcon,
  // ExportIcon,
  EditIcon,
  FIleUploadLineIcon,
  FilterCollapseIcon,
  FilterTwoIcon,
  ExportIcon,
  ExcelIcon,
  PDFIcon,
  DownloadIcon,
  RecieptIcon,
  ChartPieSliceIcon,
  // PrintIcon,
} from "../../Icons";
import "../../../assets/scss/reports/cashbook.scss";
import { useEffect, useState, useRef } from "react";
// import DateRangePicker from "../../utils/DateRangePicker";
import queryString from "query-string";
import { useMutation, useQuery, useQueryClient } from "react-query";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import { toast } from "react-toastify";
import CubeOutline from "mdi-react/CubeOutlineIcon";
import { Link } from "react-router-dom";
import { useStoreState } from "easy-peasy";
import Select from "react-select";
import { Popover } from "react-tiny-popover";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { isEmpty, truncate, uniqBy } from "lodash";

import { queryActions } from "../../../utils/reactQueryActions";
import useDebounce, {
  useIsAdmin,
  useIsStore,
  useLocalStorage,
  useQueryParams,
  useResolveRootRoles,
  useScrollTop,
} from "../../../utils/hooks";
// import { services } from "../../../config";
import currency from "currency.js";
import { format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import {
  defaultSelectValue,
  paginationOptions,
  qtyFormat,
  qtyFormatToString,
  scrollToTop,
} from "../../../utils/helpers";
import RsDateRangePicker from "../../utils/RsDateRangePicker";
import { useAuth } from "../../../hooks/useAuth";
import BatchesModal from "./BatchesModal";
import LoginDialog from "../../LoginDialog";
import ConfirmDialog from "../../ConfirmDialogue";
import AddNewStock from "./AddNewStock";
import UpdateStockModal from "./UpdateStock";
import NoTableItem from "../../utils/NoTableItem";
import { fetchActionsUtil } from "../../../utils/helpers";
import AddNewAssets from "./AddAssets";
import WarehouseItemTable from "../WarehouseItemTable";
import UpdateWarehouseItemPriceModal from "../UpdateWarehousePriceModal";
import UpdateWarehouseItemModal from "../UpdateWarehouseItemModal";
import GRNDialog from "../../GRNDialog";
import { useDownloadExcel } from "../../../hooks/useDownloadExcel";
import * as yup from "yup";
import { read, utils } from "xlsx";
import { IsPrivileged } from "../../DisplayChildElement";
import { appSettings } from "../../../config";
import ConvertQuantity from "../../utils/ConvertQuantity";
import DepreciationModal from "./DepreciationModal";
import ProjectedDepreciationModal from "./ProjectedDepreciation";
import { HoverDropDown } from "../../Component";
import ModalLoader from "../../utils/ModalLoader";
import CashIcon from "mdi-react/CashIcon";
import CogSyncOutlineIcon from "mdi-react/CogSyncOutlineIcon";

export default function WarehouseInventoryListEntry() {
  const queryClient = useQueryClient();
  const { backendUrl, isIronRod, isCement } = useAuth();
  const isAdmin = useIsAdmin();
  const isStore = useIsStore();
  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showUpdatePriceModal, setShowUpdatePriceModal] = useState(false);
  const [showUpdateItemsModal, setShowUpdateItemsModal] = useState(false);

  useScrollTop();

  const { isPrivileged } = useResolveRootRoles();

  const [showDepreciationModalModal, setShowDepreciationModal] = useState(
    false
  );
  const [
    showProjectedDepreciationModal,
    setShowProjectedDepreciationModal,
  ] = useState(false);

  const [
    showUpdateInventoryAssetModal,
    setShowUpdateInventoryAssetModal,
  ] = useState(false);
  const [showBatchesModal, setShowBatchesModal] = useState(false);

  const [
    showCreateNewInventoryModal,
    setShowCreateNewInventoryModal,
  ] = useState(false);

  const initialFilterParams = {
    page: 1,
    limit: 40,
    barcode: "",
    itemName: "",
    product: "",
    category: "",
    branch: "",
    withProduct: true,
    withCategory: true,
  };
  const [queryParams, setQueryParams] = useQueryParams({
    ...initialFilterParams,
  });
  const [filterParams, setFilterParams] = useState({
    ...queryParams,
    ...initialFilterParams,
  });

  const [excelData, setExcelData] = useState([]);

  const CSVLinkRef = useRef(null);

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(
    excelData,
    CSVLinkRef
  );

  const generalSettings = useStoreState((state) => state.generalSettings);

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({ ...queryParams, ...debouncedFilterParams });
  }, [debouncedFilterParams]);

  const wareshouseLocal = JSON.parse(
    window.localStorage.getItem("warehouseName")
  );

  const fetchStockItems = async (queryParams, ware) => {
    // await waitFor(5000);
    const { data } = await fetchActionsUtil(
      `${backendUrl}/api/warehouse/items/${ware}?&${queryParams === "" ? "" : queryString.stringify(
        queryParams
      )}`,
      "GET"
    );

    data.product = [
      {
        label: "Select",
        value: "",
      },
      ...data?.product?.map((el) => ({
        label: el.Product_Name,
        value: el.Product_Name,
      })),
    ];

    data.category = [
      {
        label: "Select",
        value: "",
      },
      ...data?.category?.map((el) => ({
        label: el.Cat_Name,
        value: el.Cat_Name,
      })),
    ];

    data.branch = [
      {
        label: "Select",
        value: "",
      },
      ...data?.branch?.map((el) => ({
        label: el.Branch,
        value: el.Branch,
      })),
    ];

    return data;
  };

  const fetchAssets = async (queryParams, ware) => {
    // await waitFor(5000);
    const { data } = await fetchActionsUtil(
      `${backendUrl}/api/warehouse/assets/${ware}?&${queryParams === "" ? "" : queryString.stringify(
        queryParams
      )}`,
      "GET"
    );

    data.product = [
      {
        label: "Select",
        value: "",
      },
      ...data?.product?.map((el) => ({
        label: el.Product_Name,
        value: el.Product_Name,
      })),
    ];

    data.category = [
      {
        label: "Select",
        value: "",
      },
      ...data?.category?.map((el) => ({
        label: el.Cat_Name,
        value: el.Cat_Name,
      })),
    ];

    // data.branch = [
    //   {
    //     label: "Select",
    //     value: "",
    //   },
    //   ...data.data.branch.map((el) => ({
    //     label: el.Branch,
    //     value: el.Branch,
    //   })),
    // ];

    return data;
  };

  // console.log(wareshouseLocal);
  const {
    data = { count: 0, warehouses: [] },
    refetch,
    isFetching,
    isSuccess,
  } = useQuery(
    ["queryActions_WAREHOUSE_ITEMS", { queryParams, wareshouseLocal }],
    () =>
      wareshouseLocal?.cater === "Assets" ||
        wareshouseLocal?.cater === "non-stock"
        ? fetchAssets(queryParams, wareshouseLocal?.whID)
        : fetchStockItems(queryParams, wareshouseLocal?.whID),
    {
      keepPreviousData: true,
    }
  );

  // console.log(data);
  const handleShowBatches = (item) => {
    setSelectedItem(item);
    setShowBatchesModal(true);
  };

  useEffect(() => {
    setFilterParams({
      ...queryParams,
      ...initialFilterParams,
    });
  }, [wareshouseLocal?.whID]);

  const deleteStock = async (payload) => {
    let response = await fetch(`${backendUrl}/api/warehouse/stock-delete`, {
      method: "DELETE",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };

  const deleteAssets = async (payload) => {
    let response = await fetch(`${backendUrl}/api/warehouse/assets-delete`, {
      method: "DELETE",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };

  const deleteMutation = useMutation(
    (payload) =>
      wareshouseLocal?.cater === "Assets" ||
        wareshouseLocal?.cater === "non-stock"
        ? deleteAssets(payload)
        : deleteStock(payload),
    {
      onSuccess: ({ message, data: { item } }) => {
        toast.success(message);

        queryClient.setQueryData([queryActions.ITEMS, queryParams], (data) => {
          data.items = data.items.filter((el) => el.Bar_Code !== item.Bar_Code);
          return data;
        });
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  const handleDeleteItem = async (item) => {
    if (await LoginDialog()) {
      if (
        await ConfirmDialog({
          title: "Delete Item",
          description:
            "Are you sure you want to delete this Item from your inventory",
        })
      ) {
        deleteMutation.mutate(item);
      }
    }
  };

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams({
      ...initialFilterParams,
    });
  };

  const handleSelectedItem = (item) => {
    setFilterParams({
      ...filterParams,
      barcode: item.Bar_Code,
      itemName: item.Item_Name,
    });
    setShowItemsPopover(false);
  };

  const editPrice = async (el) => {
    if (await LoginDialog()) {
      setSelectedItem(el);
      setShowUpdatePriceModal(true);
    }
  };

  const printGRN = async (InvoiceNo) => {
    await GRNDialog({
      title: "Transaction posted successfully",
      // description: "...",
      InvoiceNo,
      useAPI: `${backendUrl}/api/warehouse/pdf/grn`,
    });
  };

  const { limit, page, ...rest } = queryParams;
  const onDownloadExcelData = async () => {
    let exData =
      wareshouseLocal?.cater === "Assets" ||
        wareshouseLocal?.cater === "non-stock"
        ? await fetchAssets("", wareshouseLocal?.whID)
        : await fetchStockItems("", wareshouseLocal?.whID);
    // console.log(exData);
    const company = exData.company?.CompName;

    exData = exData.warehouses.map((row) => ({
      ...row,
      Date_Log: format(new Date(row.Date_Log), "dd-MMM-yyyy"),
    }));

    exData = exData.map((d, i) => [
      i + 1,
      d.Bar_Code,
      d.Item_Name,
      d.Item_Desc,
      isIronRod
        ? qtyFormatToString(
          qtyFormat(d.Quantity, d.Item_Desc, itemMeasurements)
        )
        : d.Quantity,
      currency(d?.UnitCost, {
        symbol: "",
      }).format(),
      currency(d?.UnitPrice, {
        symbol: "",
      }).format(),
      d.Date_Log,
    ]);

    const date =
      rest.startDate && rest.endDate
        ? `Date Prepared: Between ${format(
          new Date(rest.startDate),
          "E MMM d yyyy k:mm:ss z"
        )} to ${format(new Date(rest.endDate), "E MMM d yyyy k:mm:ss z")}`
        : "";

    exData = [
      [company],
      [`${wareshouseLocal?.namewh} Warehouse Inventory Entry Report`],
      [date],
      [""],
      [
        "S/N",
        "Item Code",
        "Item Name",
        "Item Desc",
        "QTY",
        "Unit Cost",
        "Unit Price",
        "Post time",
      ],
      ...exData,
      [""],
    ];

    // console.log(exData);
    setExcelData(exData);
  };

  const deleteItemApi = async (payload) => {
    let response = await fetch(`${backendUrl}/api/warehouse/delete-item`, {
      method: "DELETE",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const deleteItemMutation = useMutation((payload) => deleteItemApi(payload), {
    onSuccess: ({ message }) => {
      toast.success(message);
      refetch();
    },
    onError: ({ message = "" }) => {
      toast.error(`Unable to perform action: ${message}`);
    },
  });

  const deleteItem = async (el) => {
    if (
      await ConfirmDialog({
        title: "Delete Purchase",
        description: "Are you sure you want to delete this purchase",
      })
    ) {
      deleteItemMutation.mutate({
        ...el,
      });
    }
  };

  const addMultipleItemsMutation = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/warehouse/add-stock-multiple`,
        "POST",
        "",
        payload
      ),
    {
      onSuccess: ({ message }) => {
        toast.success(message);
        refetch();
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  function handleFile({ eventData: e, type }) {
    /*  toast.info("Service not Available");
    return; */
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async function (e) {
        const workbook = read(e.target.result, {
          cellDates: true,
          dateNF: "yyyy-MM-dd HH:mm:ss.SSS",
        });
        let rows = utils.sheet_to_json(
          workbook.Sheets[workbook.SheetNames[0]],
          {
            defval: "",
            dateNF: "yyyy-MM-dd HH:mm:ss.SSS",
          }
        );
        //  console.log("excel", rows);

        //--
        if (
          !(await ConfirmDialog({
            title: "Are you sure?",
            description: "You want to import",
          }))
        ) {
          return;
        }
        // TODO - validate excel

        let schema = yup.object().shape({
          items: yup.array().of(
            yup.object({
              Vendor_ID: yup.string(),
              vendorName: yup.string(),
              Item_Type: yup.string(),
              Item_Code: yup.string().required(),
              New_Item_Code: yup.string(),
              Batch_Name: yup.string(),
              Item_Name: yup.string().required(),
              InvoiceNo: yup.string(),
              Item_Desc: yup.string(),
              Unit: yup.string(),
              Usage: yup.string(),
              UnitCost: yup.string().required(),
              Freight: yup.string(),
              LandingUnitCost: yup.string(),
              Quantity: yup.string().default("0"),
              ManDate: yup.string().default(new Date().toISOString()),
              ExpireDate: yup.string().default(new Date().toISOString()),
              Date_Log: yup.string().default(new Date().toISOString()),
              Manufacturer: yup.string(),
              Category: yup.string(),
              Branch: yup.string(),
              Minimum_Level: yup.string().default("0"),
              Tax: yup.string(),
              Vat_5: yup.string(),
              UnitPrice: yup.string().required(),
              MaximumLevel: yup.string().default("0"),
              DriverName: yup.string(),
              DriverPhoneNumber: yup.string(),
              TruckNumber: yup.string(),
              DeliveryDate: yup.string(),
              showInStore: yup.string(),
              addDiscount: yup.string().default("0"),
              Profit: yup.string().default("0"),
              Incentive: yup.string().default("0"),
              pcsPriceDistributor: yup.string(),
              marginType: yup.string().default("Fixed"),
              marginPercentage: yup.string().default("0"),
              incentiveType: yup.string().default("Fixed"),
              incentivePercentage: yup.string().default("0"),
              age: yup.string(),
              ageDurationValue: yup.string(),
              parentBatchBarCode: yup.string(),
              parentBatchItemName: yup.string(),
              ItemStatus: yup.string(),
              InvoiceAmount: yup.string().default("0"),
              Total_Freight: yup.string().default("0"),
              totalLandingCost: yup.string().default("0"),
              totalDiscount: yup.string(),
              freightVendor_ID: yup.string(),
              Pack_Size: yup.string(),
              Generic_Configuration: yup.string(),
              Tier: yup.string(),
              Branded_Generic: yup.string(),
              Batch_Number: yup.string(),
              Serial_Number: yup.string(),
              currency: yup
                .string()
                .default(generalSettings?.prevailingCurrency),
              ...(["non-stock", "Assets"].includes(wareshouseLocal?.cater)
                ? { accountDescription: yup.string().required() }
                : {}),
            })
          ),
        });
        try {
          await schema.validate({ items: rows });

          rows = rows.map((el) => ({
            ...el,
            Bar_Code: el.Item_Code,
            ManDate: el?.ManDate ? el?.ManDate : el?.Date_Log,
            ExpireDate: el?.ExpireDate ? el?.ExpireDate : el?.Date_Log,
            Warehouse: wareshouseLocal?.whID,
          }));

          if (generalSettings?.itemsRequiresSerialNumber) {
            // Group by and clean up
            const uniqueByBar_Code = uniqBy(
              rows.map((el) => {
                el.LandingUnitCost = el?.LandingUnitCost
                  ? el?.LandingUnitCost
                  : el.UnitCost;
                el.totalLandingCost = el?.totalLandingCost
                  ? el?.totalLandingCost
                  : el.UnitCost;
                el.InvoiceAmount = el?.InvoiceAmount
                  ? el?.InvoiceAmount
                  : el.UnitCost;
                return {
                  ...el,
                  Bar_Code: el.Item_Code,
                  //  uniqueRow: `${el.Bar_Code}-${el.Batch_Number}`,
                };
              }),
              "Bar_Code"
            );

            rows = uniqueByBar_Code.map((el) => {
              const foundItems = rows.filter(
                (rowItem) => String(rowItem.Bar_Code) == String(el.Bar_Code)
              );
              return {
                ...el,
                ...(type === "RESTOCK"
                  ? {
                    InvoiceAmount: foundItems
                      .map((el) => el?.InvoiceAmount || 0)
                      .reduce((a, b) => currency(a).add(b).value),
                    Total_Freight: foundItems
                      .map((el) => el?.Total_Freight || 0)
                      .reduce((a, b) => currency(a).add(b).value),
                    totalLandingCost: foundItems
                      .map((el) => el?.totalLandingCost || 0)
                      .reduce((a, b) => currency(a).add(b).value),
                  }
                  : {}),
                Quantity: foundItems?.length,
                serialNumbers: foundItems.map((el) => ({
                  serialNum: el.Serial_Number,
                })),
              };
            });

            //
            // console.log(rows);

            // TODO - Do not mix vendors

            // TODO - Ensure all dates are valid
          }

          if (type === "RESTOCK") {
            addMultipleItemsMutation.mutate({
              items: rows.map((el) => ({
                ...el,
                New_Bar_Code: el.New_Item_Code,
                Bar_Code: el.Item_Code,
                Margin: el.Profit,
                Cat_Name: el.Category,
                PurchasePrice: el.UnitCost,
                UnitCost: el?.LandingUnitCost,
                Batch_Name: el.Batch_Number,
                InvoiceNo: el?.InvoiceNo ? el?.InvoiceNo : `IVN${Date.now()}`,
              })),
              type, // "RESTOCK"
            });
            return;
          }

          addMultipleItemsMutation.mutate({
            items: rows.map((el) => ({
              ...el,
              New_Bar_Code: el.New_Item_Code,
              Bar_Code: el.Item_Code,
              Margin: el.Profit,
              Cat_Name: el.Category,
              PurchasePrice: el.UnitCost,
              UnitCost: el?.UnitCost,
              Batch_Name: el.Batch_Number,
            })),
            type, // "INSERT_ITEMS"  "CHANGE_PRICES" "RESTOCK"
          });

          // resolve(validData);
        } catch (err) {
          console.log(err);
          toast.error(err.name);
          toast.error(JSON.stringify(err.errors));
        }
      };
      reader.readAsArrayBuffer(file);
    }
    e.target.value = "";
  }

  window.addEventListener("storage", () => {
    refetch();
  });

  const turnOFFandON = async (Bar_Code) => {
    if (!Bar_Code) {
      toast.error(`Add Bar Code`);

      return;
    }

    try {
      await fetchActionsUtil(
        `${backendUrl}/api/items/update-showInStore-warehouse-items`,
        "POST",
        "",
        { Bar_Code }
      );
      refetch();
    } catch (error) { }
  };

  const updateShowInStore = async (Bar_Code, status) => {
    if (status) {
      await turnOFFandON(Bar_Code);
    } else {
      if (generalSettings.store) {
        await turnOFFandON(Bar_Code);
      } else {
        toast.error("Not subscribed");
      }
    }
  };

  const handleSyncWarehouse = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/warehouse/sync-warehouse`,
        "POST",
        "",
        payload
      ),
    {
      onSuccess: (data) => {
        toast.success(data?.message);
      },
      onError: (data) => {
        toast.error("Unable to perform action", data?.massage);
      },
    }
  );

  return (
    <IsPrivileged roleName="Inventory Entry">
      <section className="cashbook-wrapped">
        <main className="cash-book">
          <div className="content">
            <div className="content-main">
              <div className={`filter-area ${showFilter ? "open" : "close"}`}>
                <div className="filter-header">
                  <h2>
                    <FilterTwoIcon /> Filters
                  </h2>

                  <Button
                    variant="white"
                    onClick={() => setShowFilter(!showFilter)}
                  >
                    <FilterCollapseIcon />
                  </Button>
                </div>
                <div className="filter-body">
                  <Form>
                    <div className="body">
                      <Form.Group className="mb-2-5">
                        <Form.Label>Item Code</Form.Label>

                        <InputGroup>
                          <Form.Control
                            name="barcode"
                            placeholder="Enter Item Code"
                            value={filterParams?.barcode}
                            onChange={(e) => handleFilterParamsChange(e)}
                          />

                          <Popover
                            isOpen={showItemsPopover}
                            onClickOutside={() => setShowItemsPopover(false)}
                            content={() => (
                              <WarehouseItemTable
                                handleSelectedItem={handleSelectedItem}
                              />
                            )}
                            position="bottom"
                          >
                            <InputGroup.Text
                              onClick={() =>
                                setShowItemsPopover(!showItemsPopover)
                              }
                            >
                              <MagnifyIcon />
                            </InputGroup.Text>
                          </Popover>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group className="mb-2-5">
                        <Form.Label>Item Name</Form.Label>
                        <Form.Control
                          name="itemName"
                          value={filterParams?.itemName}
                          placeholder="Enter Item Name"
                          onChange={(e) => handleFilterParamsChange(e)}
                        />
                      </Form.Group>
                      <Form.Group className="mb-2-5">
                        <Form.Label>Product</Form.Label>
                        <Select
                          classNamePrefix="form-select"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          placeholder="All"
                          name="product"
                          isSearchable={true}
                          key={data?.product}
                          onChange={(selected) => {
                            setFilterParams({
                              ...filterParams,
                              product: selected.value,
                            });
                          }}
                          // defaultValue={defaultSelectValue(
                          //   queryParams?.product,
                          //   data?.product,
                          //   { value: "", label: "Select" }
                          // )}
                          value={data?.product?.find(
                            (el) => el.value === filterParams?.product
                          )}
                          options={data?.product || []}
                        />
                      </Form.Group>
                      <Form.Group className="mb-2-5">
                        <Form.Label>Category</Form.Label>
                        <Select
                          classNamePrefix="form-select"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          placeholder="All"
                          name="category"
                          isSearchable={true}
                          key={data?.category}
                          onChange={(selected) => {
                            setFilterParams({
                              ...filterParams,
                              category: selected.value,
                            });
                          }}
                          // defaultValue={defaultSelectValue(
                          //   queryParams?.category,
                          //   data?.category,
                          //   { value: "", label: "Select" }
                          // )}
                          value={data?.category?.find(
                            (el) => el.value === filterParams?.category
                          )}
                          options={data?.category || []}
                        />
                      </Form.Group>
                      {/* <Form.Group className="mb-2-5">
                      <Form.Label>Branch</Form.Label>
                      <Select
                        classNamePrefix="form-select"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        placeholder="All"
                        name="branch"
                        isSearchable={true}
                        key={data?.branch}
                        onChange={(selected) => {
                          setFilterParams({
                            ...filterParams,
                            branch: selected.value,
                          });
                        }}
                        defaultValue={defaultSelectValue(
                          queryParams?.branch,
                          data?.branch,
                          { value: "", label: "All" }
                        )}
                        options={data?.branch || []}
                      />
                    </Form.Group> */}
                      <hr className="mt-3 mb-4" />
                      <Form.Group className="">
                        <Form.Label>Date Range</Form.Label>
                        <div className="position-relative">
                          <RsDateRangePicker
                            placement="topStart"
                            value={
                              filterParams.startDate && filterParams.endDate
                                ? [
                                  parse(
                                    filterParams.startDate,
                                    "yyyy-MM-dd",
                                    new Date()
                                  ),
                                  parse(
                                    filterParams.endDate,
                                    "yyyy-MM-dd",
                                    new Date()
                                  ),
                                ]
                                : []
                            }
                            onClean={() => clearDateRange()}
                            onOk={(date) => filterByDateRange(date)}
                          />
                        </div>
                      </Form.Group>
                    </div>

                    <div className="buttons rounded">
                      <Button onClick={() => reset()} variant="white">
                        Reset
                      </Button>
                      <Button onClick={() => search()} variant="primary">
                        Search
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
              <div className="content-body">
                <header>
                  <h1>
                    {!showFilter && (
                      <button
                        onClick={() => setShowFilter(!showFilter)}
                        className="btn filter"
                      >
                        <FilterTwoIcon />
                      </button>
                    )}
                    Inventory
                    <button
                      onClick={() => refetch()}
                      className="btn text-primary"
                      title="Refresh"
                    >
                      <CachedIcon />
                    </button>
                  </h1>
                  <div className="actions mr-5">
                    {/*  <DateRangePicker
              apply={(date) => filterByDateRange(date)}
              defaultValue={
                queryParams.startDate && queryParams.endDate
                  ? [
                      parse(queryParams.startDate, "yyyy-MM-dd", new Date()),
                      parse(queryParams.endDate, "yyyy-MM-dd", new Date()),
                    ]
                  : []
              }
            /> */}
                    {/*  <RsDateRangePicker
              placement="bottomEnd"
              defaultValue={
                queryParams.startDate && queryParams.endDate
                  ? [
                      parse(queryParams.startDate, "yyyy-MM-dd", new Date()),
                      parse(queryParams.endDate, "yyyy-MM-dd", new Date()),
                    ]
                  : []
              }
              onClean={() => clearDateRange()}
              onOk={(date) => filterByDateRange(date)}
            /> */}
                    {isAdmin || isStore ? (
                      <>
                        {isIronRod && (
                          <>
                            {wareshouseLocal?.cater === "Assets" ||
                              wareshouseLocal?.cater === "non-stock" ? (
                              <button
                                className="btn bg-primary text-white"
                                onClick={() =>
                                  setShowUpdateInventoryAssetModal(true)
                                }
                              >
                                + Create New
                              </button>
                            ) : (
                              <button
                                className="btn bg-primary text-white"
                                onClick={() =>
                                  setShowCreateNewInventoryModal(true)
                                }
                              >
                                + Create New
                              </button>
                            )}

                            <button
                              onClick={() => setShowUpdateItemsModal(true)}
                              className="btn sendSms"
                            >
                              Restock <FIleUploadLineIcon />
                            </button>

                            {/* {isPrivileged("Restock Warehouse") && (
                              <button
                                onClick={() => setShowUpdateItemsModal(true)}
                                className="btn sendSms"
                              >
                                Restock <FIleUploadLineIcon />
                              </button>
                            )} */}
                          </>
                        )}

                        {isCement && (
                          <>
                            <Link
                              className="btn btn-primary px-3"
                              to="/load-atc"
                            >
                              Load ATC
                            </Link>
                          </>
                        )}
                      </>
                    ) : null}
                    <CSVLink
                      className="btn print d-none"
                      filename={`${wareshouseLocal?.namewh
                        } Warehouse inventory Entry Report(${format(
                          new Date(),
                          "dd-MMM-yyyy hh:mm:ss a"
                        )}).csv`}
                      data={excelData}
                      ref={CSVLinkRef}
                    />
                    <Dropdown>
                      <Dropdown.Toggle
                        variant=""
                        className="btn print"
                        disabled={isfetchingExcel}
                        bsPrefix=""
                      >
                        Export
                        <ExportIcon color="#008000" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        popperConfig={{
                          strategy: "fixed",
                        }}
                        renderOnMount
                        className="text-center"
                      >
                        <Dropdown.Item
                          as="div"
                          onClick={onDownloadExcelData}
                          className="p-cursor"
                        >
                          Excel <ExcelIcon color="#008000" />
                        </Dropdown.Item>
                        <Dropdown.Item as="div">
                          <a
                            href={`${backendUrl}/api/warehouse/pdf/inventory-entry/${wareshouseLocal?.cater
                              }/${wareshouseLocal?.whID}?${queryString.stringify(
                                rest
                              )}`}
                            target="blank"
                          >
                            PDF <PDFIcon color="#ff0000" />
                          </a>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant=""
                        className="btn print"
                        disabled={isfetchingExcel}
                        bsPrefix=""
                      >
                        Templates <DownloadIcon />
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        popperConfig={{
                          strategy: "fixed",
                        }}
                        renderOnMount
                        className="text-center"
                      >
                        <Dropdown.Item as="div">
                          <a
                            href={`/excel_templates/INVEX_INSERT_ITEMS_TEMPLATE.xlsx`}
                            target="blank"
                            title="Download"
                            download=""
                          >
                            Insert Items Template
                          </a>
                        </Dropdown.Item>
                        <Dropdown.Item as="div">
                          <a
                            href={`/excel_templates/INVEX_CHANGE_PRICE_TEMPLATE.xlsx`}
                            target="blank"
                            title="Download"
                            download=""
                          >
                            Change Price Template
                          </a>
                        </Dropdown.Item>
                        <Dropdown.Item as="div">
                          <a
                            href={`/excel_templates/INVEX_RESTOCK_ITEMS_TEMPLATE.xlsx`}
                            target="blank"
                            title="Download"
                            download=""
                          >
                            Restock Template
                          </a>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant=""
                        className="btn print"
                        disabled={isfetchingExcel}
                        bsPrefix=""
                      >
                        Import <ExcelIcon color="#008000" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        popperConfig={{
                          strategy: "fixed",
                        }}
                        renderOnMount
                        className="text-center"
                      >
                        <Dropdown.Item
                          as="label"
                          className="p-cursor"
                          title="Import"
                        >
                          <input
                            type="file"
                            className="d-none"
                            onChange={(e) =>
                              handleFile({ eventData: e, type: "INSERT_ITEMS" })
                            }
                            accept=".xlsx"
                          />
                          Insert Items
                        </Dropdown.Item>
                        <Dropdown.Item
                          as="label"
                          className="p-cursor"
                          title="Import"
                        >
                          <input
                            type="file"
                            className="d-none"
                            onChange={(e) =>
                              handleFile({
                                eventData: e,
                                type: "CHANGE_PRICES",
                              })
                            }
                            accept=".xlsx"
                          />
                          Change Prices
                        </Dropdown.Item>
                        <Dropdown.Item
                          as="label"
                          className="p-cursor"
                          title="Import"
                        >
                          <input
                            type="file"
                            className="d-none"
                            onChange={(e) =>
                              handleFile({ eventData: e, type: "RESTOCK" })
                            }
                            accept=".xlsx"
                          />
                          Restock Items
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant=""
                        className="btn print"
                        bsPrefix=""
                      >
                        <DotsVerticalIcon />
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        popperConfig={{
                          strategy: "fixed",
                        }}
                        renderOnMount
                        className="dropdown-with-icons"
                      >
                        <>
                          <Dropdown.Item
                            as="button"
                            onClick={() =>
                              handleSyncWarehouse.mutate({
                                W_ID: wareshouseLocal?.whID,
                              })
                            }
                          >
                            <CogSyncOutlineIcon />
                            Synchronize Warehouse
                          </Dropdown.Item>
                        </>
                      </Dropdown.Menu>
                    </Dropdown>
                    Select
                    {/*  <button className="btn print">
                Print <PrintIcon />
              </button>
              <button className="btn print">
                Print <ExportIcon />
              </button> */}
                  </div>
                </header>

                <div className="px-md-4">
                  <Table
                    responsive
                    borderless
                    striped
                    className="product-table"
                  >
                    <thead>
                      <tr>
                        <th />
                        <th>S/N</th>
                        {wareshouseLocal?.cater === "Stock" && (
                          <th>Show in online store </th>
                        )}
                        <th>Item Code</th>
                        <th>Item Name</th>
                        <th>Item Desc</th>
                        <th>UOM</th>
                        <th>Category</th>
                        <th>QTY</th>
                        {/* <th>Storage Location</th> */}
                        <th>Manufacturer</th>
                        <th>Unit Cost</th>
                        {appSettings.hasIncentive && (
                          <>
                            <th>Mark Up</th>
                            <th>Profit</th>
                            <th>Incentive</th>
                          </>
                        )}
                        <th>Unit Price (Retail/Walk In)</th>
                        <th>
                          Unit Price{" "}
                          {appSettings.isGigc ? "(Pieces)" : "(Wholesale)"}
                        </th>
                        <th>Unit Price (Distributor)</th>{" "}
                        <th>Current Market Price</th>
                        <th>Reorder Level</th>
                        {appSettings.isMedbury && (
                          <>
                            <th>Pack Size</th>
                            <th>Generic Configuration</th>
                            <th>Tier</th>
                            <th>Branded/Generic</th>
                          </>
                        )}{" "}
                        <th>Post time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.warehouses?.map((el, index) => (
                        <tr key={index}>
                          <td>
                            {" "}
                            <Dropdown alignRight drop="end">
                              <Dropdown.Toggle
                                variant=""
                                className="bg-white border-0"
                                bsPrefix="print more"
                              >
                                <DotsVerticalIcon />
                              </Dropdown.Toggle>
                              <Dropdown.Menu
                                popperConfig={{
                                  strategy: "fixed",
                                }}
                                renderOnMount
                                className="dropdown-with-icons"
                              >
                                <Dropdown.Item
                                  as="button"
                                  onClick={() => editPrice(el)}
                                >
                                  <EditIcon className="text-light" />
                                  Edit
                                </Dropdown.Item>
                                {isIronRod &&
                                  wareshouseLocal?.cater !== "Assets" && (
                                    <Dropdown.Item
                                      as="button"
                                      onClick={() => handleShowBatches(el)}
                                    >
                                      <CubeOutline className="text-light" />
                                      Batches
                                    </Dropdown.Item>
                                  )}
                                {generalSettings?.itemsRequiresSerialNumber &&
                                  wareshouseLocal?.cater !== "Assets" ? (
                                  <Dropdown.Item as="div">
                                    <Link
                                      to={`/warehouse-inventory/items-serial-details?barcode=${el?.Bar_Code
                                        }&Warehouse=${wareshouseLocal?.whID}`}
                                    >
                                      <RecieptIcon className="text-light" />
                                      Serial Numbers
                                    </Link>
                                  </Dropdown.Item>
                                ) : null}

                                {wareshouseLocal?.cater === "Assets" && (
                                  <>
                                    <HoverDropDown
                                      alignRight
                                      drop="end"
                                      className="w-100"
                                    >
                                      <Dropdown.Toggle
                                        variant=""
                                        className="w-100 dropdown-item justify-content-between"
                                      >
                                        <span className="d-flex gap-2">
                                          <ChartPieSliceIcon /> Depreciation
                                        </span>
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu className="dropdown-with-icons">
                                        <Dropdown.Item
                                          as="button"
                                          onClick={() =>
                                            setShowDepreciationModal(el)
                                          }
                                        >
                                          <ChartPieSliceIcon />
                                          Set Depreciation
                                        </Dropdown.Item>

                                        <Dropdown.Item
                                          as="button"
                                          className="w-100"
                                          onClick={() =>
                                            setShowProjectedDepreciationModal(
                                              el
                                            )
                                          }
                                        >
                                          <RecieptIcon />
                                          Projected Depreciation
                                        </Dropdown.Item>

                                        <Dropdown.Item
                                          as="div"
                                          className="w-100"
                                        >
                                          <Link
                                            to={`/account-setup/account-list?Description=Accumulated Depreciation&Ref=${el.Bar_Code
                                              }_${el.Item_Name} - Depreciation`}
                                          >
                                            <RecieptIcon />
                                            Accumulated Depreciation
                                          </Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </HoverDropDown>
                                  </>
                                )}

                                <Dropdown.Item
                                  as="button"
                                  className="text-danger"
                                  onClick={() => handleDeleteItem(el)}
                                >
                                  <DeleteIcon />
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                          <td>{index + 1}</td>
                          {wareshouseLocal?.cater === "Stock" && (
                            <td className="text-center">
                              <Form.Check
                                type="switch"
                                name="showInStore"
                                className="custom-form-check"
                                checked={Boolean(el?.showInStore)}
                                onChange={() =>
                                  updateShowInStore(
                                    el?.Bar_Code,
                                    el?.showInStore
                                  )
                                }
                              />
                            </td>
                          )}

                          <td>{el?.Bar_Code}</td>
                          <td>{el?.Item_Name}</td>
                          <td
                            style={{ whiteSpace: "pre" }}
                            title={el?.Item_Desc}
                          >
                            {appSettings.isMedbury
                              ? truncate(el?.Item_Desc)
                              : el?.Item_Desc}
                          </td>
                          <td>{el?.Product_Model}</td>
                          <td>{el?.Cat_Name}</td>
                          <td
                            className="p-cursor text-nowrap"
                            onClick={() => handleShowBatches(el)}
                            title={el.Quantity}
                          >
                            <ConvertQuantity
                              quantity={el.Quantity}
                              desc={el.Item_Desc}
                            />
                          </td>
                          {/* <td>{el?.StorageLocationItem?.name}</td> */}
                          <td>{el?.Product_Name}</td>
                          <td>
                            {currency(el?.UnitCost, {
                              symbol: "",
                            }).format()}
                          </td>
                          {appSettings.hasIncentive && (
                            <>
                              <td>
                                {currency(el.markUp, {
                                  symbol: "",
                                }).format()}
                              </td>

                              <td>
                                {currency(el.Margin, {
                                  symbol: "",
                                }).format()}
                              </td>

                              <td>
                                {currency(el.Incentive, {
                                  symbol: "",
                                }).format()}
                              </td>
                            </>
                          )}

                          <td>
                            {currency(el?.UnitPrice, {
                              symbol: "",
                            }).format()}
                          </td>
                          <td>
                            {currency(el?.Tax, {
                              symbol: "",
                            }).format()}
                          </td>
                          <td>
                            {currency(el?.Vat_5, {
                              symbol: "",
                            }).format()}
                          </td>
                          <td>
                            {currency(el?.currentMarketPrice, {
                              symbol: "",
                            }).format()}
                          </td>
                          <td>{el?.Reorder_Level}</td>
                          {appSettings.isMedbury && (
                            <>
                              <td>{el?.Pack_Size}</td>
                              <td>{el?.Generic_Configuration}</td>
                              <td>{el?.Tier}</td>
                              <td>{el?.Branded_Generic}</td>
                            </>
                          )}
                          <td className="text-nowrap">
                            {format(new Date(el?.Date_Log), "dd MMM, yyyy")}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>

                {!isFetching && isSuccess && isEmpty(data?.warehouses) ? (
                  <div>
                    <NoTableItem queryParams={queryParams} />
                    <p className="text-center">{queryParams?.customerName}</p>
                  </div>
                ) : (
                  <div className="d-flex justify-content-between px-3 align-items-center pagination">
                    {/*<p className="m-0">
                Showing {data?.startIndex + 1} to{" "}
                {data?.endIndex <= data?.count ? data?.endIndex : data?.count}{" "}
                of {data.count} entries
                  </p> */}
                    <div className="pagination_left">
                      <p className="m-0 p-0">Show</p>
                      <select
                        value={queryParams.limit}
                        name="limit"
                        className="form-select "
                        onChange={(e) => handleSearchQueryChange(e)}
                      >
                        <option value="1">1 rows</option>
                        <option value="10">10 rows</option>
                        <option value="20">20 rows</option>
                        <option value="30">30 rows</option>
                        <option value="40">40 rows</option>
                        <option value="50">50 rows</option>
                        <option value="100">100 rows</option>
                      </select>
                    </div>

                    <ReactPaginate
                      {...paginationOptions}
                      pageCount={Math.ceil(data.count / queryParams.limit)}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={0}
                      onPageChange={({ selected }) => {
                        scrollToTop();
                        setQueryParams({
                          ...queryParams,
                          page: selected + 1,
                        });
                      }}
                      forcePage={queryParams.page - 1}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* {showUpdateInventoryModal && (
          <UpdateStockModal
            showUpdateInventoryModal={showUpdateInventoryModal}
            setShowUpdateInventoryModal={setShowUpdateInventoryModal}
            refetch={refetch}
          />
        )} */}

          {showCreateNewInventoryModal && (
            <AddNewStock
              refetch={refetch}
              showCreateNewInventoryModal={showCreateNewInventoryModal}
              setShowCreateNewInventoryModal={setShowCreateNewInventoryModal}
              setItemType={wareshouseLocal}
              printGRN={printGRN}
            />
          )}

          {showUpdateInventoryAssetModal && (
            <AddNewAssets
              refetch={refetch}
              showCreateNewInventoryModal={showUpdateInventoryAssetModal}
              setShowCreateNewInventoryModal={setShowUpdateInventoryAssetModal}
              setItemType={wareshouseLocal}
              savingType="newItem"
              printGRN={printGRN}
            />
          )}

          {showBatchesModal && selectedItem && (
            <BatchesModal
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              showBatchesModal={showBatchesModal}
              setShowBatchesModal={setShowBatchesModal}
              wareshouseLocal={wareshouseLocal}
            />
          )}

          {showUpdatePriceModal && selectedItem ? (
            <UpdateWarehouseItemPriceModal
              showUpdatePriceModal={showUpdatePriceModal}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              setShowUpdatePriceModal={setShowUpdatePriceModal}
              refetch={refetch}
            />
          ) : null}

          {showUpdateItemsModal && (
            <UpdateWarehouseItemModal
              showUpdateInventoryModal={showUpdateItemsModal}
              setShowUpdateInventoryModal={setShowUpdateItemsModal}
              refetch={refetch}
              printGRN={printGRN}
              requiresApproval={generalSettings.restockRequiresApproval}
              requiresPONumber={generalSettings.restockRequiresPONumber}
            />
          )}

          {showDepreciationModalModal && (
            <DepreciationModal
              showDepreciationModal={showDepreciationModalModal}
              setShowDepreciationModal={setShowDepreciationModal}
            />
          )}

          {showProjectedDepreciationModal && (
            <ProjectedDepreciationModal
              showProjectedDepreciationModal={showProjectedDepreciationModal}
              setShowProjectedDepreciationModal={
                setShowProjectedDepreciationModal
              }
            />
          )}

          <ModalLoader show={addMultipleItemsMutation.isLoading} />
        </main>
      </section>
    </IsPrivileged>
  );
}
