import { Table, Form, Button } from "react-bootstrap";
import { PageHeaderListIcon, ExportIcon } from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import { useEffect, useState, useRef } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import useDebounce, { useQueryParams } from "../../utils/hooks";
import { reportActions } from "../../utils/reactQueryActions";
import { useMutation, useQuery, useQueryClient } from "react-query";
import ReactPaginate from "react-paginate";
import queryString from "query-string";

import PageHeader from "../PageHeader";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { Link, Outlet } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "../../hooks/useAuth";
import ReceiveInventoryTable from "./ReceiveInventoryTable";
import { fetchActionsUtil, waitFor } from "../../utils/helpers";
import RejectModal from "../modals/RejectModal";
import { paginationOptions } from "../../utils/helpers";
import ConfirmDialog from "../ConfirmDialogue";
import { backendApis } from "../../config";
import ModalLoader from "../utils/ModalLoader";
import ViewIssueNumberModal from "../Inventory/ViewIssueSerialNumber";
import { IsPrivileged } from "../DisplayChildElement";

export default function ReceiveInventory() {
  const [selectedItems, setSelectedItems] = useState([]);
  const [allItems, setAllItems] = useState([]);
  const [openReject, setOpenReject] = useState(false);
  const [selectedAll, setSelectedAll] = useState(true);
  const [unRejectedItems, setUnRejectedItems] = useState([]);
  const [allStorage, setAllStorage] = useState([]);

  const { backendUrl } = useAuth();
  const initialFilterParams = { q: "" };

  const refreshButton = useRef(null);

  const queryClient = useQueryClient();

  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });
  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });
  const debouncedFilterParams = useDebounce(filterParams, 500);

  useEffect(() => {
    setQueryParams({ ...queryParams, ...debouncedFilterParams });
  }, [debouncedFilterParams]);

  const { data, refetch } = useQuery(
    ["RECEIVING_ITEMS_WAREHOUSE", queryParams],
    () =>
      fetchActionsUtil(
        `${backendUrl}/api/pending?&${queryString.stringify(queryParams)}`,
        "GET"
      ),
    {
      keepPreviousData: false,
      staleTime: 0,
    }
  );

  useEffect(() => {
    let _data = data?.data.filter((p) => p.Status !== "Rejected");

    _data = _data?.map((d) => {
      const sendTo = backendApis.find((el) => el.name === d.Vendor);
      const newItem = {
        ...d,
        sendTo: sendTo ? `${sendTo.url}/api/pending` : "",
        oldQty: d.Quantity,
        allserialNumbers: d.serialNumbers,
      };

      return newItem;
    });

    // console.log("_data", _data);
    setSelectedItems(_data);
    setUnRejectedItems(_data);
    // setAllItems((prev) => data?.data);
    setAllItems((prev) => _data);
  }, [data]);

  const handleSearchQueryChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]: e.target.value,
    });
  };

  const sendIssueItemMutation = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/pending/recieve`,
        "POST",
        "",
        payload
      ),
    {
      onSuccess: ({ message, data }) => {
        refetch();

        toast.success(message);
      },
      onError: () => {
        console.log("error");
        //  toast.error(`Unable to perform action`);
      },
    }
  );

  const rejectItemMutation = useMutation(
    (payload) =>
      fetchActionsUtil(`${backendUrl}/api/pending/reject`, "POST", "", payload),
    {
      onSuccess: ({ message, data }) => {
        refetch();

        toast.success(message);
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  // const selectFunction = (v) => {
  //   const _allItems = allItems
  //   if (v === "reject") {
  //     const aaa = _allItems.filter((d) => d.Status === "Rejected");

  //     setAllItems(aaa);

  //   } else if (v === "non") {
  //           const aaa = _allItems.filter(
  //             (d) => d.Status !== "Rejected"
  //           );
  //      setAllItems(aaa);
  //   } else {
  //     setAllItems();
  //   }
  // }

  const onSelectAll = (e) => {
    if (!e.target.checked) {
      setSelectedItems([]);
      setSelectedAll(!selectedAll);
    } else {
      // console.log(data);
      let _data = data?.data.filter((p) => p.Status !== "Rejected");

      _data = _data?.map((d) => {
        const sendTo = backendApis.find((el) => el.name === d.Vendor);
        const newItem = {
          ...d,
          sendTo: sendTo ? `${sendTo.url}/api/pending` : "",
          oldQty: d.Quantity,
          allserialNumbers: d?.serialNumbers,
        };

        return newItem;
      });

      setSelectedItems((prev) => _data);
      setUnRejectedItems(_data);
      setSelectedAll(!selectedAll);
    }
  };

  const onChangeSelected = (item) => {
    const _selectedItems = [...selectedItems];

    if (_selectedItems.length > 0) {
      const index = _selectedItems.findIndex(
        (d) => d.Bar_Code === item.Bar_Code && d.Trans_ID === item.Trans_ID
      );
      if (index >= 0) {
        _selectedItems.splice(index, 1);
        setSelectedItems(_selectedItems);
      } else {
        setSelectedItems((prev) => [...prev, item]);
      }
    } else {
      setSelectedItems((prev) => [...prev, item]);
    }
  };

  const updateQuantity = (num, item) => {
    let _datas = selectedItems.find(
      (p, i) => p.Bar_Code === item.Bar_Code && p.Trans_ID === item.Trans_ID
    );
    const index = selectedItems.findIndex(
      (a) => a.Bar_Code === item.Bar_Code && a.Trans_ID === item.Trans_ID
    );
    _datas = { ..._datas, Quantity: parseFloat(num) };
    selectedItems.splice(index, 1, _datas);
  };

  const updateSerial = (serial, item) => {
    let _datas = selectedItems.find(
      (p, i) => p.Bar_Code === item.Bar_Code && p.Trans_ID === item.Trans_ID
    );
    const index = selectedItems.findIndex(
      (a) => a.Bar_Code === item.Bar_Code && a.Trans_ID === item.Trans_ID
    );

    _datas = { ..._datas, serialNumbers: JSON.stringify(serial) };
    selectedItems.splice(index, 1, _datas);
  };

  const updateBatch = (text, item) => {
    let _datas = selectedItems.find(
      (p, i) => p.Bar_Code === item.Bar_Code && p.Trans_ID === item.Trans_ID
    );
    const index = selectedItems.findIndex(
      (a) => a.Bar_Code === item.Bar_Code && a.Trans_ID === item.Trans_ID
    );
    _datas = { ..._datas, Batch_Name: text };

    selectedItems.splice(index, 1, _datas);
  };

  const updateDate = (date, item) => {
    let _datas = selectedItems.find(
      (p, i) => p.Bar_Code === item.Bar_Code && p.Trans_ID === item.Trans_ID
    );
    const index = selectedItems.findIndex(
      (a) => a.Bar_Code === item.Bar_Code && a.Trans_ID === item.Trans_ID
    );
    _datas = { ..._datas, ExpireDate: date };

    selectedItems.splice(index, 1, _datas);
  };

  const updateStorageLocation = (text, item) => {
    let _datas = selectedItems.find(
      (p, i) => p.Bar_Code === item?.Bar_Code && p.Trans_ID === item.Trans_ID
    );
    const index = selectedItems.findIndex(
      (a) => a.Bar_Code === item.Bar_Code && a.Trans_ID === item.Trans_ID
    );
    _datas = { ..._datas, StorageLocation: text };

    selectedItems.splice(index, 1, _datas);
  };

  // const handleInputChange = ({ index, name, value }) => {
  //   const oldTableData = allItems;
  //   console.log(oldTableData, index, name);
  //   oldTableData[index][name] = value;
  //   setSelectedItems([...oldTableData]);
  // };

  const approveAll = async () => {
    const errors = selectedItems.filter((el) => el.Quantity > el.oldQty);
    if (errors.length > 0) {
      return toast.error(
        `Input a lower quantity for ${errors
          .map((el) => el.Item_Name)
          .join(",")}`
      );
    }

    let all = selectedItems
      ?.filter((ft) => ft.Quantity > 0)
      ?.map((el) => ({
        ...el,
        serialNumbers: el?.serialNumbers ? JSON.parse(el?.serialNumbers) : null,
        nonSelected: el?.serialNumbers
          ? JSON.parse(el?.allserialNumbers)?.filter((ft) =>
              JSON.parse(el?.serialNumbers)?.find(
                (fd) => ft.serialNum !== fd.serialNum
              )
            )
          : null,
      }));

    console.log(all);
    if (
      await ConfirmDialog({
        title: "Receive Item(s)",
        description:
          "Are you sure you want to receive this Item(s) to your inventory",
      })
    ) {
      //console.log("selectedItems => ", all);
      sendIssueItemMutation.mutate(all);
    }
  };

  const disapproveAll = async (reasonForReject) => {
    const data = { selectedItems, reasonForReject };
    console.log("selectedItems => ", selectedItems);
    if (
      await ConfirmDialog({
        title: "Reject Item(s)",
        description: "Are you sure you want to reject this Item(s)",
      })
    ) {
      rejectItemMutation.mutate(data);
      setOpenReject(false);
    }
  };

  const fechWareHouse = async (query) => {
    const data = await fetchActionsUtil(
      `${backendUrl}/api/items/storage-location?${queryString.stringify(
        query
      )}`,
      "GET"
    );

    data.storage = data.storage?.map((el) => ({
      ...el,
      value: el.storageID,
      label: el.name,
    }));
    setAllStorage(() => data?.storage);
    return data;
  };

  useQuery(
    ["FETCH_STORAGE-LOCATION-ITEMS", queryParams],
    () => fechWareHouse(queryParams),
    {
      keepPreviousData: true,
    }
  );

  return (
    <IsPrivileged
      roleName={[
        "Inventory on Transit",
        "Item Received \nin Sales",
        "Receive Inventory",
      ]}
    >
      <main className="inner-page-outlet">
        {/*    <PageHeader
          name="Inventory On Transit"
          description="Receive Your Inventory"
          icon={<PageHeaderListIcon />}
          
        /> */}

        <main className="cash-book">
          <div className="content">
            <div className="content-main">
              <div className="content-body">
                <header className="flex-wrap gap-3">
                  <span>
                    <h6>Item Code:</h6>{" "}
                  </span>
                  <div className="d-flex flex-grow-1">
                    <div className="global-search-area col col-md-6">
                      <MagnifyIcon />
                      <Form.Control
                        id="queryParams-q"
                        className=""
                        name="q"
                        value={filterParams.q}
                        onChange={(e) => handleSearchQueryChange(e)}
                        placeholder="Search..."
                      />
                    </div>
                    <button
                      onClick={() => refetch()}
                      className="btn text-primary mx-3"
                      ref={refreshButton}
                    >
                      <CachedIcon />
                    </button>
                  </div>

                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="selectedItems"
                      onChange={onSelectAll}
                      checked={
                        selectedItems?.length === unRejectedItems?.length
                      }
                    />
                    <label className="form-check-label" htmlFor="selectedItems">
                      Select All
                    </label>
                  </div>

                  <div className="d-flex gap-3">
                    <Button
                      disabled={
                        selectedItems?.length < 1 ||
                        sendIssueItemMutation.isLoading ||
                        rejectItemMutation.isLoading
                      }
                      onClick={() => approveAll()}
                    >
                      {sendIssueItemMutation.isLoading
                        ? "Please wait..."
                        : "Receive Selected"}{" "}
                    </Button>
                    <Button
                      disabled={
                        selectedItems?.length < 1 ||
                        sendIssueItemMutation.isLoading ||
                        rejectItemMutation.isLoading
                      }
                      onClick={() => setOpenReject(true)}
                      variant="danger"
                      className="text-white px-3"
                    >
                      {rejectItemMutation.isLoading
                        ? "Please wait..."
                        : "Reject Selected"}{" "}
                    </Button>
                    <div className="btn bg print ">
                      Export to Excel <ExportIcon className="px-2" />
                    </div>
                  </div>
                </header>

                <div className="px-md-4">
                  <Table
                    borderless
                    responsive
                    hover
                    striped
                    className="product-table"
                  >
                    <thead>
                      <tr>
                        <th />
                        <th className="ml-3">Item Code</th>
                        <th>Item Name</th>
                        <th>Remaining Quantity</th>
                        <th>Quantity</th>
                        <th className="text-center">Batch</th>
                        <th>Expiry Date</th>
                        <th>Storage Location</th>
                        <th className="text-center">Status</th>
                        <th>Vendor</th>
                        <th>Date Log</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allItems?.map((el, index) => (
                        <ReceiveInventoryTable
                          key={index}
                          el={el}
                          index={index}
                          onChangeSelected={onChangeSelected}
                          updateQuantity={updateQuantity}
                          updateBatch={updateBatch}
                          updateDate={updateDate}
                          selectedItems={selectedItems}
                          updateSerial={updateSerial}
                          allStorage={allStorage}
                          updateStorageLocation={updateStorageLocation}
                          // handleInputChange={handleInputChange}
                        />
                      ))}
                    </tbody>
                  </Table>
                </div>

                <div className="d-flex justify-content-between px-3 align-items-center pagination">
                  <div className="pagination_left">
                    <p className="m-0 p-0">Show</p>
                    <select
                      value={queryParams.limit}
                      name="limit"
                      className="form-select "
                      onChange={(e) => handleSearchQueryChange(e)}
                    >
                      <option value="10">10 rows</option>
                      <option value="20">20 rows</option>
                      <option value="30">30 rows</option>
                      <option value="40">40 rows</option>
                      <option value="50">50 rows</option>
                      <option value="100">100 rows</option>
                    </select>
                  </div>

                  <ReactPaginate
                    {...paginationOptions}
                    pageCount={Math.ceil(data?.count / queryParams?.limit)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={0}
                    onPageChange={({ selected }) => {
                      document.body.scrollTop = document.documentElement.scrollTop = 0;
                      setQueryParams({
                        ...queryParams,
                        page: selected + 1,
                      });
                    }}
                    forcePage={parseInt(queryParams.page) - 1}
                  />
                </div>
              </div>
            </div>
          </div>
        </main>

        <Outlet />
        <RejectModal
          show={openReject}
          onHide={() => setOpenReject(false)}
          disapproveAll={disapproveAll}
        />

        <ModalLoader
          show={sendIssueItemMutation.isLoading || rejectItemMutation.isLoading}
        />
      </main>
    </IsPrivileged>
  );
}
