import React, { useState } from 'react';
import Pin from 'mdi-react/PinIcon';
import PinOff from 'mdi-react/PinOffIcon';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useLocalStorage } from '../../utils/hooks';

const ExpenseSettings = ({ /* initialPinnedMode = false, */ onPinChange = () => { }, mode }) => {
  const [isPinnedMode, setIsPinnedMode] = useLocalStorage("EXPENSE_PIN_MODE", 'Single Mode');

  const handleToggle = () => {
    const newPinnedModeState = isPinnedMode !== 'Single Mode' ? 'Single Mode' : 'Multi Mode';
    setIsPinnedMode(newPinnedModeState);
    onPinChange(newPinnedModeState);
    // toast.success('Done')
  };

  return (
    <Button
      onClick={handleToggle}
      // onClick={() => toast.info('Coming soon')}
      className="border"
      aria-label={isPinnedMode === mode ? "Unpin item" : "Pin item"}
      title={isPinnedMode === mode ? "Unpin" : "Pin"}
      variant=''
    >
      {isPinnedMode === mode ? (
        <PinOff />
      ) : (
        <Pin />
      )}
    </Button>
  );
};

export default ExpenseSettings;
