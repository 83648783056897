import currency from "currency.js";
import { useStoreState } from "easy-peasy";
import { useEffect } from "react";
import { useAuth } from "../../hooks/useAuth";
import { qtyFormat, qtyFormatToString } from "../../utils/helpers";
import { appSettings } from "../../config";

export default function ConvertQuantity({
  quantity = 0,
  desc = "",
  convertNow,
}) {
  const { isIronRod } = useAuth();
  const generalSettings = useStoreState((state) => state?.generalSettings);
  const itemMeasurements = useStoreState((state) => state.itemMeasurements);

  return (
    <>
      {(isIronRod && generalSettings?.convertToTonsAndPcs) ||
      (convertNow && appSettings.isGigc)
        ? qtyFormatToString(
            qtyFormat(quantity, desc, itemMeasurements),
            false,
            generalSettings.convertableUOM
          )
        : currency(quantity, {
            symbol: "",
            precision: 2,
          })
            .format()
            .toString()
            .replace(".00", "")}
    </>
  );
}

export function QuantityLabel() {
  const generalSettings = useStoreState((state) => state?.generalSettings);

  return generalSettings?.quantityLabel
    ? generalSettings?.quantityLabel
    : "Quantity";
}
