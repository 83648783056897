import { useState } from "react";
import { NavLink } from "react-router-dom";

export default function OperationsNav(props) {
  const items = [
    {
      name: "Jobs",
      to: "jobs/",
    },
    {
      name: "Create Job",
      to: `jobs/create`,
    },
    {
      name: "Acquisition Source",
      to: `jobs/acquisition-source`,
    },
    {
      name: "Progress Status",
      to: `jobs/progress-status`,
    },
  ];

  const others = [];

  return (
    <div className="dashboard-tabs innerpage-tabs tier-1 px-4">
      <ul className="nav nav-tabs">
        {items.map((item, index) => (
          <li key={index} className="nav-item">
            <NavLink className={`nav-link ga-nav-tab`} to={item.to}>
              {item.name}
            </NavLink>
          </li>
        ))}
        {others.map((item, index) => (
          <li key={index} className="nav-item">
            <NavLink className={`nav-link ga-nav-tab`} to={item.to}>
              {item.name}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
}
