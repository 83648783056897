import { Table } from "react-bootstrap";
import { useState, useRef, useEffect, useCallback } from "react";

import { useIsInViewport } from "../utils/hooks";

const TableComponent = ({
  tableHeadsFunction,
  mainDataArray,
  tableDataRowFunction,
  striped,
  tableRowClick,
  getIndexFunction,
  firstRowTableData,
  ...props
}) => {
  const [active, setActive] = useState("");

  const tableRef = useRef(null);
  const rowRef = useRef(null);

  const keyMoves = useCallback(
    (event) => {
      if (event.key === "ArrowDown") {
        setActive((state) =>
          state === mainDataArray.length - 1
            ? mainDataArray.length - 1
            : state + 1
        );
      }

      if (event.key === "ArrowUp") {
        setActive((state) => (state === 0 ? state : state - 1));
      }
    },
    [mainDataArray?.length]
  );

  useEffect(() => {
    window.addEventListener("keydown", keyMoves);
    return () => {
      window.removeEventListener("keydown", keyMoves);
    };
  }, [keyMoves]);

  const isInViewport1 = useIsInViewport(rowRef[active]);

  useEffect(() => {
    // 👇️ listen for changes
    if (!isInViewport1) {
      rowRef[active]?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  }, [isInViewport1, active]);

  return (
    <Table {...props} ref={tableRef}>
      {tableHeadsFunction ? tableHeadsFunction() : null}
      <tbody>
        {firstRowTableData ? firstRowTableData() : null}
        {mainDataArray?.map((el, index) => (
          <tr
            key={index}
            className={`p-cursor tran-table ${active === index &&
              "active-table"} ${index % 2 === 0 && "dark-row"}`}
            onClick={(e) => {
              // GA FIX
              if (active === index) { setActive(null); } else {
                setActive(active)
              }
              if (tableRowClick) tableRowClick(e, el);
              getIndexFunction && getIndexFunction(index);
            }}
            ref={(el) => (rowRef[index] = el)}
          >
            {tableDataRowFunction(el, index)}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default TableComponent;
