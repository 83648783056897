import { useParams } from "react-router";
import CreateIronRodInvoice from "./CreateIronRodInvoice";
import { useQuery, useQueryClient } from "react-query";
import { useAuth } from "../../hooks/useAuth";
import ModalLoader from "../utils/ModalLoader";
import currency from "currency.js";
import { isEmpty } from "lodash";
import { useIsAdmin } from "../../utils/hooks";
import { UnAuthorized } from "../utils/UnAuthorized";
import { useStoreState } from "easy-peasy";
import CreateWarehouseInvoice from "../warehouse/Invoicing/CreateWarehouseInvoice";
import InnerPageLayout from "../InnerPageLayout";
import { WareHouseIcon } from "../Icons";

export default function EditInvoiceWrapper() {
  const { backendUrl } = useAuth();
  const { Trans_ID } = useParams();
  const isAdmin = useIsAdmin();
  const generalSettings = useStoreState((state) => state.generalSettings);

  const getTransaction = async (Trans_ID) => {
    let response = await fetch(
      `${backendUrl}/api/transaction/invoice/${Trans_ID}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    let { data } = await response.json();
    data = cleanUpDataForEditing(data);
    return data;
  };

  const { data, refetch, isSuccess, isFetching } = useQuery(
    ["GET_ALL_TRANSACTION_DATA", Trans_ID],
    () => getTransaction(Trans_ID),
    {
      keepPreviousData: false,
    }
  );

  const cleanUpDataForEditing = ({
    transaction,
    deliveryInfo,
    sales,
    customer,
    payments,
    soldBatches,
    warehouseData,
  }) => {
    let items = [];
    let splitPayments = {};

    // console.log(payments);

    // TODO - Check if invoice has been tampered with

    let bank = "";
    // console.log(deliveryInfo, transaction);
    const cashPayment = payments.filter((el) => el.PaymentType === "Cash");
    // console.log(cashPayment, payments.length);

    if (
      transaction.Payment_Type === "Split Payment" &&
      payments.length == 2 &&
      !isEmpty(cashPayment) && // No Balance
      Number(transaction.Balance) == 0 &&
      transaction.amountFromCustomerBalance == 0
    ) {
      // console.log("jd");
      splitPayments.cashAmount = payments
        .filter((el) => el.PaymentType === "Cash")
        .map((el) => el.Credit)
        .reduce((a, b) => currency(a).add(b).value, 0);

      const otherPayment = payments.find((el) => el.PaymentType !== "Cash");

      splitPayments.splitPaymentType =
        otherPayment.PaymentType === "Credit/Debit Card"
          ? "card"
          : otherPayment.PaymentType === "Direct Bank Transfer"
            ? "directTransfer"
            : otherPayment.PaymentType === "Cheque"
              ? "cheque"
              : "";

      bank = otherPayment?.BankName;
    } else if (
      transaction.Payment_Type === "Split Payment Multiple" ||
      payments.length >= 2 ||
      transaction.amountFromCustomerBalance != 0
    ) {
      console.log("dd");
      transaction.Payment_Type = "Split Payment Multiple";
      deliveryInfo.PosCharge = 0;
      splitPayments.splitPaymentCardArray = payments
        .filter((el) => el.PaymentType === "Credit/Debit Card")
        .map((el) => ({
          amount: currency(el.Credit).value,
          amountPaid: currency(el.Credit).subtract(el.posCharge).value,
          bank: el?.BankName,
          posCharge: el.posCharge,
        }));

      splitPayments.splitDirectBankTransferArray = payments
        .filter((el) => el.PaymentType === "Direct Bank Transfer")
        .map((el) => ({ amount: el.Credit, bank: el?.BankName }));

      splitPayments.splitPaymentChequeArray = payments
        .filter((el) => el.PaymentType === "Cheque")
        .map((el) => ({
          amount: el.Credit,
          bank: el?.BankName,
          chequeNumber: el.chequeNumber,
        }));

      splitPayments.cashAmount = payments
        .filter((el) => el.PaymentType === "Cash")
        .map((el) => el.Credit)
        .reduce((a, b) => currency(a).add(b).value, 0);

      if (isEmpty(splitPayments.splitPaymentCardArray)) {
        delete splitPayments.splitPaymentCardArray;
      }
      if (isEmpty(splitPayments.splitDirectBankTransferArray)) {
        delete splitPayments.splitDirectBankTransferArray;
      }
      if (isEmpty(splitPayments.splitPaymentChequeArray)) {
        delete splitPayments.splitPaymentChequeArray;
      }
      // console.log(splitPayments);
    } else if (payments) {
      bank = payments[0]?.BankName;

      // half baked split payment
      if (
        transaction.Payment_Type.includes("Split Payment") &&
        payments.length == 1
      ) {
        transaction.Payment_Type = payments[0]?.PaymentType;
      }
    }

    // console.log(soldBatches, sales);

    items = sales.map((el) => ({
      ...el,
      PayType: transaction.Payment_Type,
      shippingCost: deliveryInfo.shippingCost,
      otherCharges: deliveryInfo.otherCharges,
      ShipTo: transaction.ShipTo,
      bank,
      customer,

      // --------------------------------------------
      loadingCharge: currency(deliveryInfo.LoadingCharge).value,
      offloadingCharge: currency(deliveryInfo.OffloadingCharge).value,
      transportCharge: currency(deliveryInfo.TransportCharge).value,
      posCharge: currency(deliveryInfo.PosCharge).value,
      taxType: transaction.taxType,
      soldBatches: soldBatches
        .filter((batch) => batch.Bar_Code.startsWith(`${el.Bar_Code}-B`))
        .filter((batch) =>
          batch?.serialNum
            ? Boolean(el.SerialNumberItems == batch?.serialNum)
            : true
        ),
      // ---------------------------------------------
      // Get serial numbers
      ...(generalSettings?.itemsRequiresSerialNumber
        ? {
          serialNumberItems: soldBatches
            .filter((batch) => batch.Bar_Code.startsWith(`${el.Bar_Code}-B`))
            .filter((batch) =>
              batch?.serialNum
                ? Boolean(el.SerialNumberItems == batch?.serialNum)
                : true
            ),
        }
        : {}), // {serialNum: "" }

      // ------------------------------------------------------
      Discount: el.Discount ? el.Discount : 0,
      baseCurrency: transaction.currency,
      baseConversionAmount: transaction.conversionAmount,
      currency: transaction.currency,
      conversionAmount: transaction.conversionAmount,
      amountFromCustomerBalance: transaction.amountFromCustomerBalance,
      supplyNow: sales.every((el) => el?.Shipping_Status === "Supplied"),
      generalDiscount: transaction?.generalDiscount,
      generalDiscountType: transaction?.generalDiscountType,
      generalDiscountPercentage: transaction?.generalDiscountPercentage,
    }));

    console.log(items, "kk");
    return { items, splitPaymentsDataToEdit: splitPayments, warehouseData };
  };

  if (!isAdmin) return <UnAuthorized />;

  return (
    <>
      {isSuccess && !isFetching ? (
        <>
          {data.items && data.items[0]?.Warehouse ? (
            <div>
              <InnerPageLayout
                name={data.warehouseData?.W_name}
                description=""
                icon={<WareHouseIcon />}
                sideComponent={null}
              />
              <CreateWarehouseInvoice
                mode="edit"
                oldInvoiceData={data.items}
                splitPaymentsDataToEdit={data.splitPaymentsDataToEdit}
                refreshInvoiceDataItems={refetch}
                warehouseData={{
                  ...data.warehouseData, whID: data.warehouseData?.whID ?? data.warehouseData?.W_ID
                }}
              />{" "}
            </div>
          ) : (
            <CreateIronRodInvoice
              mode="edit"
              oldInvoiceData={data.items}
              splitPaymentsDataToEdit={data.splitPaymentsDataToEdit}
              refreshInvoiceDataItems={refetch}
            />
          )}
        </>
      ) : null}
      {isFetching && <ModalLoader show={isFetching} />}
    </>
  );
}
