import { Table, Pagination, Form, Dropdown } from "react-bootstrap";
import CachedIcon from "mdi-react/CachedIcon";
import { useQuery } from "react-query";
import currency from "currency.js";
import {
  addDays,
  endOfMonth,
  endOfYear,
  format,
  parse,
  startOfMonth,
  startOfYear,
} from "date-fns";
import { useEffect, useRef, useState } from "react";
import queryString from "query-string";
import { CSVLink } from "react-csv";
import { cloneDeep, isEmpty, lowerCase } from "lodash";

import {
  CurrencyCircleDollarIcon,
  ExcelIcon,
  FilterIcon,
  PDFIcon,
} from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import useDebounce, {
  useCurrencies,
  useQueryParams,
  useScrollTop,
} from "../../utils/hooks";
import { useAuth } from "../../hooks/useAuth";
import { useDownloadExcel } from "../../hooks/useDownloadExcel";
import ModalLoader from "../utils/ModalLoader";
import NoTableItem from "../utils/NoTableItem";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import {
  calculateAccounts,
  displayAccountType,
  months,
  formatDate,
} from "../../utils/helpers";
import { useNavigate } from "react-router";
import { IsPrivileged } from "../DisplayChildElement";
import Select from "react-select";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";

const calculateAccountsTotal = (arrayData) => {
  arrayData = cloneDeep(arrayData);
  const accountOne = [
    {
      data: [
        "CURRENT ASSET",
        "CASH AND CASH EQUIVALENT",
        "NON-CURRENT ASSETS",
        "FIXED ASSET",
        "ACCOUNTS RECEIVABLE",
        "COST OF SALES",
        "EXPENSES",
        "ACCUMULATED DEPRECIATION",
      ],
      action: "Debit",
    },
    {
      data: [
        "ACCOUNTS PAYABLE",
        "CURRENT LIABILITIES",
        "NON-CURRENT LIABILITY",
        "LONG-TERM LIABILITIES",
        "OWNER'S EQUITY",
        "INCOME",
      ],
      action: "Credit",
    },
  ];

  const allTotalAmount = {
    Credit: 0,
    Debit: 0,
  };

  arrayData.forEach((el) => {
    accountOne.forEach((d) => {
      if (d.data.some((e) => e === el.Type)) {
        // GA SWAP -
        let action = d.action;
        if (parseFloat(el.RunningBalanceCalculated) < 0) {
          action = d.action === "Debit" ? "Credit" : "Debit";
          // remove negative
          el.RunningBalanceCalculated = Math.abs(
            parseFloat(el.RunningBalanceCalculated)
          );
        }
        allTotalAmount[action] += parseFloat(el.RunningBalanceCalculated);
      }
    });
  });

  // console.log(allTotalAmount, "kdk");
  return allTotalAmount;
};

function TrialBalance() {
  useScrollTop();
  const initialFilterParams = {
    startDate: "" /* format(new Date(), "yyyy-MM-dd") */,
    endDate: "" /* format(new Date(), "yyyy-MM-dd") */,
  };
  const navigate = useNavigate();
  const { backendUrl } = useAuth();
  const [excelData, setExcelData] = useState([]);
  const {
    deploymentCurrencies: currenciesOptions,
    prevailingCurrency,
  } = useCurrencies();
  const CSVLinkRef = useRef(null);
  const [filterText, setFilterText] = useState("");
  const debouncedFilterText = useDebounce(filterText, 800);

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(
    excelData,
    CSVLinkRef
  );

  const [queryParams, setQueryParams] = useQueryParams({
    ...initialFilterParams,
    currency: "",
    convertCurrency: prevailingCurrency,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({
      ...queryParams,
      ...debouncedFilterParams,
    });
  }, [debouncedFilterParams]);

  const fetchCustomerLedgers = async (queryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/journal/trial-balance?&${queryString.stringify(
        queryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    data.allCreditDebitTotal = calculateAccountsTotal(data.journal);

    return data;
  };

  const {
    data = {
      count: 0,
      journal: [],
    },
    refetch,
    isFetching,
    isSuccess,
  } = useQuery(
    ["TrailBalance", queryParams],
    () => fetchCustomerLedgers(queryParams),
    {
      keepPreviousData: true,
    }
  );

  // download excel function
  // const { limit, page, ...rest } = queryParams;
  const onDownloadExcelData = async () => {
    let exData = await fetchExcelData(
      `${backendUrl}/api/journal/trial-balance?${queryString.stringify(
        queryParams
      )}`,
      "GET"
    );
    const { company, journal } = exData.data;

    exData = journal.map((el) => [
      `${el?.DetailType ? el.DetailType : el.Description} ${el?.subText || ""}`,
      currency(
        displayAccountType(el.Type, el.RunningBalanceCalculated, "debit"),
        {
          symbol: "",
          // for excel
          negativePattern: "#",
        }
      ).format(),
      currency(
        displayAccountType(el.Type, el.RunningBalanceCalculated, "credit"),
        {
          symbol: "", // for excel
          negativePattern: "#",
        }
      ).format(),
    ]);

    // console.log(exData);

    //  return;

    data.allCreditDebitTotal = calculateAccountsTotal(data.journal);

    const date =
      queryParams.startDate && queryParams.endDate
        ? `Date Prepared: Between ${format(
            new Date(queryParams.startDate),
            "E MMM d yyyy k:mm:ss z"
          )} to ${format(
            new Date(queryParams.endDate),
            "E MMM d yyyy k:mm:ss z"
          )}`
        : "";

    exData = [
      [company[0]?.CompName],
      ["Trial Balance Report"],
      [date],
      [""],
      ["Description", "Debit", "Credit"],
      ...exData,
      [
        "TOTAL",
        currency(data?.allCreditDebitTotal?.Debit, {
          symbol: "",
        }).format(),
        currency(data?.allCreditDebitTotal?.Credit, {
          symbol: "",
        }).format(),
      ],
    ];
    //  console.log(exData);
    setExcelData(exData);
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
      enableDateRange: true,
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };
  // isprivileged

  const handleAccountingPeriod = ({ month, year }) => {
    if (!year) {
      year = new Date().getFullYear();
    }

    // Invalid year
    if (year < 1000) {
      setFilterParams({
        ...filterParams,
        startDate: "",
        endDate: "",
      });
      return;
    }

    if (month === "Date Range") {
      //
      setFilterParams({
        ...filterParams,
        month,
      });
    } else if (month === "All") {
      // All in year
      const start = startOfYear(new Date(year, 0));
      const end = endOfYear(new Date(year, 0));

      setFilterParams({
        ...filterParams,
        month,
        startDate: format(start, "yyyy-MM-dd"),
        endDate: format(end, "yyyy-MM-dd"),
        year,
      });
    } else {
      // All in month and year
      const start = startOfMonth(new Date(year, month));
      const end = endOfMonth(new Date(year, month));

      setFilterParams({
        ...filterParams,
        month,
        startDate: format(start, "yyyy-MM-dd"),
        endDate: format(end, "yyyy-MM-dd"),
        year,
      });
    }
  };

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  return (
    <IsPrivileged roleName="Financials">
      <main className="cash-book  table-sticky-header">
        <div className="content">
          <header className="container pt-5 px-2 text-nowrap">
            <h1>
              Trial Balance
              <button
                title="Refresh"
                onClick={() => refetch()}
                className="btn text-primary"
              >
                <CachedIcon />
              </button>
            </h1>
            <div className="actions gap-3">
              <div className="global-search-area">
                <MagnifyIcon />
                <input
                  className="form-control search-input"
                  name="q"
                  value={filterText}
                  onChange={(e) => setFilterText(e.target.value)}
                  placeholder="Search..."
                  autoFocus
                  autoComplete="off"
                  type={"text"}
                />
              </div>
              <CSVLink
                className="btn print d-none"
                filename={`Trial Balance(${format(
                  new Date(),
                  "dd-MMM-yyyy hh:mm:ss a"
                )}).csv`}
                data={excelData}
                ref={CSVLinkRef}
              />

              <Form.Group className="position-relative">
                <Form.Label className="fw-5 position-absolute bottom-100 start-0 m-1">
                  Filter By
                </Form.Label>
                <Select
                  classNamePrefix="form-select"
                  placeholder="All Currencies"
                  isSearchable={true}
                  options={currenciesOptions}
                  value={currenciesOptions.find(
                    (el) => el.value == filterParams.currency
                  )}
                  onChange={(selected) =>
                    setFilterParams({
                      ...filterParams,
                      page: 1,
                      currency: selected?.value,
                    })
                  }
                  isClearable={true}
                />
              </Form.Group>

              <Form.Group className="position-relative">
                <Form.Label className="fw-5 position-absolute bottom-100 start-0 m-1">
                  Report In{" "}
                </Form.Label>
                <Select
                  classNamePrefix="form-select"
                  placeholder="Select Currency"
                  isSearchable={true}
                  options={currenciesOptions}
                  value={currenciesOptions.find(
                    (el) => el.value === filterParams.convertCurrency
                  )}
                  onChange={({ value }) =>
                    setFilterParams({
                      ...filterParams,
                      page: 1,
                      convertCurrency: value,
                    })
                  }
                />
              </Form.Group>

              <div className="position-relative">
                <RsDateRangePicker
                  placement="bottomStart"
                  value={
                    filterParams.startDate && filterParams.endDate
                      ? [
                          parse(
                            filterParams.startDate,
                            "yyyy-MM-dd",
                            new Date()
                          ),
                          parse(filterParams.endDate, "yyyy-MM-dd", new Date()),
                        ]
                      : []
                  }
                  onClean={() => clearDateRange()}
                  onOk={(date) => filterByDateRange(date)}
                />
              </div>
              <Dropdown>
                <Dropdown.Toggle variant="" className="btn print" bsPrefix="">
                  <DotsVerticalIcon />
                </Dropdown.Toggle>
                <Dropdown.Menu
                  popperConfig={{
                    strategy: "fixed",
                  }}
                  renderOnMount
                  className="dropdown-with-icons"
                >
                  <div>
                    <Form.Group className="mb-2-5">
                      <Form.Label>Accounting Period</Form.Label>
                      <Select
                        classNamePrefix="form-select"
                        placeholder="Accounting Period"
                        isSearchable={true}
                        options={months}
                        value={months.find(
                          (el) => el.value == filterParams.month
                        )}
                        onChange={(selected) =>
                          handleAccountingPeriod({
                            month: selected?.value,
                            year: filterParams.year,
                          })
                        }
                        isClearable={false}
                      />
                    </Form.Group>

                    <Form.Group className="mb-2-5">
                      <Form.Label>Year</Form.Label>
                      <Form.Control
                        type="number"
                        name="year"
                        value={filterParams?.year}
                        placeholder="Enter Year"
                        onChange={(e) => {
                          handleAccountingPeriod({
                            month: filterParams.month,
                            year: e.target.value,
                          });
                        }}
                      />
                    </Form.Group>

                    <div className="mb-2-5">
                      <Form.Check
                        inline
                        label={`Include Opening Balance at
                        ${
                          queryParams.startDate
                            ? formatDate(
                                addDays(new Date(queryParams.startDate), 0)
                              )
                            : ""
                        }`}
                        name="endingBalanceAtStartDate"
                        type={"checkbox"}
                        id={`endingBalanceAtStartDate`}
                        checked={filterParams?.endingBalanceAtStartDate}
                        onChange={(e) => handleFilterParamsChange(e)}
                      />
                    </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>

              <a
                target="_blank"
                rel="noreferrer"
                href={`${backendUrl}/api/reports/pdf/trial-balance?${queryString.stringify(
                  queryParams
                )}`}
                className="btn print"
              >
                PDF <PDFIcon color="#ff0000" />
              </a>
              <button className="btn print" onClick={onDownloadExcelData}>
                Excel <ExcelIcon color="#008000" />
              </button>
            </div>
          </header>

          <div>
            <Table
              // responsive
              borderless
              striped
              className="product-table container"
              hover
            >
              <thead className="position-sticky top-0 text-nowrap">
                <tr>
                  {/*  <th>AccountID</th> */}
                  <th>Description</th>
                  <th>Debit</th>
                  <th>Credit</th>
                </tr>
              </thead>
              <tbody>
                {data?.journal
                  ?.filter((el) =>
                    debouncedFilterText
                      ? lowerCase(
                          el?.DetailType ? el.DetailType : el.Description
                        ).includes(lowerCase(debouncedFilterText))
                      : true
                  )
                  .map((el, index) => (
                    <tr
                      key={index}
                      className="p-cursor"
                      onClick={(e) => {
                        if (e.detail === 2) {
                          el?.Description
                            ? navigate(
                                `/account-setup/account-list?Description=${
                                  el.Description
                                }&currency=${
                                  filterParams.currency
                                }&convertCurrency=${
                                  filterParams.convertCurrency
                                }&startDate=${filterParams.startDate}&endDate=${
                                  filterParams.endDate
                                }&subText=${el?.subText || ""}`
                              )
                            : navigate(
                                `/account-setup/chart-of-account-report?Type=${
                                  el.Type
                                }&DetailType=${el.DetailType}&currency=${
                                  filterParams.currency
                                }&convertCurrency=${
                                  filterParams.convertCurrency
                                }&startDate=${filterParams.startDate}&endDate=${
                                  filterParams.endDate
                                }&subText=${el?.subText}`
                              );
                        }
                      }}
                      title={`Type: ${el?.Type || ""} _ ${currency(
                        el.RunningBalanceCalculated,
                        {
                          symbol: "",
                        }
                      ).format()}`}
                    >
                      {/* <td>{el?.AccountID}</td> */}
                      <td>
                        {el?.DetailType ? el.DetailType : el.Description}{" "}
                        {el?.subText}
                      </td>
                      <td
                        style={
                          currency(el.RunningBalanceCalculated, {
                            symbol: "",
                          })
                            .format()
                            .includes("-")
                            ? { color: "red" }
                            : {}
                        }
                      >
                        {currency(
                          displayAccountType(
                            el.Type,
                            el.RunningBalanceCalculated,
                            "debit"
                          ),
                          {
                            symbol: "",
                            negativePattern: "(#)",
                          }
                        ).format()}
                      </td>
                      <td
                        style={
                          currency(el.RunningBalanceCalculated, {
                            symbol: "",
                          })
                            .format()
                            .includes("-")
                            ? { color: "red" }
                            : {}
                        }
                      >
                        {currency(
                          displayAccountType(
                            el.Type,
                            el.RunningBalanceCalculated,
                            "credit"
                          ),
                          {
                            symbol: "",
                            negativePattern: "(#)",
                          }
                        ).format()}
                      </td>
                    </tr>
                  ))}
                {data?.journal?.length > 0 && (
                  <tr>
                    {/* <td /> */}
                    <td className="fw-bold">TOTAL</td>
                    <td
                      className="fw-bold"
                      style={{
                        borderBottom: "4px double #333",
                        borderTop: "2px solId #333",
                      }}
                    >
                      {currency(data?.allCreditDebitTotal?.Debit, {
                        symbol: "",
                      }).format()}
                    </td>
                    <td
                      className="fw-bold"
                      style={{
                        borderBottom: "4px double #333",
                        borderTop: "2px solId #333",
                      }}
                    >
                      {currency(data?.allCreditDebitTotal?.Credit, {
                        symbol: "",
                      }).format()}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          {!isFetching && isSuccess && isEmpty(data?.journal) ? (
            <NoTableItem queryParams={queryParams} />
          ) : null}
        </div>
        <ModalLoader show={isfetchingExcel || isFetching} />
      </main>
    </IsPrivileged>
  );
}

export default TrialBalance;
