import { useNavigate } from "react-router-dom"
import { useEffectOnce, useLocalStorage } from "../../utils/hooks"

function ExpensesV2() {
  const navigate = useNavigate()
  const [expenseDefaultUI] = useLocalStorage("EXPENSE_PIN_MODE", 'Single Mode');

  useEffectOnce(() => {
    return expenseDefaultUI === 'Single Mode' ? navigate(`/expenses-entry`) : navigate(`/expenses-entry-bulk`)
  })
}

export default ExpensesV2
