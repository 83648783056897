import React, { useEffect, useState } from "react";
import "../../assets/scss/fileModal.scss";
import { useAuth } from "../../hooks/useAuth";
import { toast } from "react-toastify";

const AutoLogoutWarning = ({ open, setOpen }) => {
  const TIMEDOWN = 60; // 60 secs to logout
  const [timeout, setTimeout] = useState(TIMEDOWN);
  const { backendUrl, user, logout } = useAuth();
  //   count down timeout to 0
  useEffect(() => {
    // Set up the interval to decrease the countdown every second
    const interval = setInterval(() => {
      setTimeout((prevSeconds) => {
        if (prevSeconds <= 1) {
          clearInterval(interval); // Stop the countdown when it reaches 0
          console.log("Count down complete"); // Trigger the function passed to handle countdown completion
          handleLogout();
          return 0;
        }
        return prevSeconds - 1; // Decrease the seconds by 1
      });
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);

  const handleLogout = async (options) => {
    try {
      const res = await fetch(`${backendUrl}/api/auth/logout`, {
        method: "POST",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        body: JSON.stringify({ Staff_ID: user.Staff_ID, ...options }),
        credentials: "include",
      });

      if (res.ok) {
        logout();
      } else {
        toast.error("Unable to perform action: Please try again");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setOpen(false);
    }
  };
  const handleKeepLogin = () => {
    setOpen(false);
  };
  return (
    <div className="autoLogoutModal">
      <div className="container">
        <h1>Are you still there?</h1>
        <p>
          Select I'm Here! to continue or you will be logged out for your
          security
        </p>
        <div className="buttons">
          <button className="logout"  onClick={() => handleLogout()}>
            Logout {`  ${timeout}s`}
          </button>
          <button  onClick={handleKeepLogin}>
            I'm here!
          </button>
        </div>
      </div>
    </div>
  );
};
export default AutoLogoutWarning;
