import { Modal, Form, Button, Row } from "react-bootstrap";
import currency from "currency.js";
import moment from "moment";
import DateTime from "react-datetime";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useStoreActions, useStoreState } from "easy-peasy";

import "../../assets/scss/new-entity-modal.scss";
import Select from "react-select";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
import NumberCustomInput from "../utils/NumberCustomInput";
import { useAuth } from "../../hooks/useAuth";
import { queryActions } from "../../utils/reactQueryActions";
import CreatableSelect from "react-select/creatable";
import ItemSizeDialog from "../ItemSizeDialog";
import PurchaseDialog from "../PurchaseDialog";
import NewVendorModal from "../NewVendorModal";
import { Units, fetchActionsUtil } from "../../utils/helpers";
import { appSettings } from "../../config";

const usageOptions = [
  {
    label: "Sales",
    value: "Sales",
  },
  {
    label: "Office Use",
    value: "Office Use",
  },
  {
    label: "Promo",
    value: "Promo",
  },
];

export default function AddItemToWarehouse({
  show,
  onHide,
  setItemType,
  refetch,
}) {
  const { backendUrl, token } = useAuth();
  const queryClient = useQueryClient();
  const [showCreateNewVendorModal, setShowCreateNewVendorModal] = useState(
    false
  );

  const [accountName, setAccountName] = useState("");

  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const setItemMeasurements = useStoreActions(
    (actions) => actions.setItemMeasurements
  );

  const setUp = async (department) => {
    let response = await fetch(`${backendUrl}/api/items/set-up`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const warehouses = await fetchActionsUtil(
      `${backendUrl}/api/warehouse/categories/${setItemType?.cater}`,
      "GET"
    );

    const storageLocation = await fetchActionsUtil(
      `${backendUrl}/api/StorageLocation/${setItemType?.whID}`,
      "GET"
    );

    const singleWarehouse = await fetchActionsUtil(
      `${backendUrl}/api/warehouse/${setItemType?.namewh}`,
      "GET"
    );

    setAccountName(singleWarehouse?.warehouses[0]?.WH_Accounts?.Description);

    const { data } = await response.json();
    data.vendors = data?.vendors.map((el) => ({
      label: el.CompanyName,
      value: el.Vendor_ID,
    }));
    data.itemMeasurement = data?.itemMeasurement.map((el) => ({
      label: el.Size,
      value: el.Size,
    }));

    data.categories = data.categories.map((el) => ({
      value: el.Cat_Name,
      label: el.Cat_Name,
    }));

    data.subCategories = data.subCategories.map((el) => ({
      ...el,
      value: el.Sub_Cat,
      label: el.Sub_Cat,
    }));

    data.products = data.products.map((el) => ({
      value: el.Product_Name,
      label: el.Product_Name,
    }));

    data.warehouses = warehouses?.warehouses?.map((el) => ({
      value: el.W_ID,
      label: el.W_name,
    }));

    data.storagelocation = storageLocation?.storagelocation?.map((el) => ({
      value: el.Storage_ID,
      label: el.storage_location,
    }));

    // console.log(data);

    return data;
  };
  const {
    data = {
      vendors: [],
      itemMeasurement: [],
    },
  } = useQuery([queryActions.ITEMS_SETUP], () => setUp(), {
    enabled: true,
  });

  const formik = useFormik({
    initialValues: {
      Vendor: "",
      Item_Type: "Inventory",
      Bar_Code: "",
      Item_Name: "",
      InvoiceNo: "",
      Size: appSettings.isBatchStandard ? "" : "8 mm",
      Unit: appSettings.isBatchStandard ? "Each" : "Tons",
      Usage: "Sales",
      PurchasePrice: "",
      Freight: "",
      UnitCost: "",
      Quantity: "",
      ExpireDate: moment(),
      Product_Name: "",
      Cat_Name: "",
      Branch: "HQ",
      Reorder_Level: "5",
      Tax: "",
      Warehouse: "",
      StorageLocation: "",
      Date_Log: moment(),

      //--------
      DriverName: "",
      DriverPhoneNumber: "",
      TruckNumber: "",
      DeliveryDate: moment(),
    },
    validationSchema: yup.object().shape({
      Bar_Code: yup.string().required("Item Code is required"),
      Item_Name: yup.string().required("Item Name is required"),
      InvoiceNo: yup.string().required("Invoice number is required"),
      Product_Name: yup.string().required("required"),
      Cat_Name: yup.string().required("required"),
      Vendor: yup.string().required("reqiured"),
      PurchasePrice: yup.string().required("reqiured"),
      // UnitPrice: yup.string().required("reqiured"),
      Quantity: yup.string().required("reqiured"),
      Warehouse: yup.string().required("reqiured"),
      StorageLocation: yup.string().required("reqiured"),
    }),
    onSubmit: async (values) => {
      values.vendorName = data?.vendors.find(
        (el) => el.value === values.Vendor
      )?.label;
      values.InvoiceAmount = InvoiceAmount;

      const purchase = await PurchaseDialog({
        item: values,
      });

      values.totalLandingCost = totalLandingCost;
      values.accountName = accountName;
      console.log(values);
      createMutation.mutate({
        newItem: values,
        vendorName: values.vendorName,
        purchaseHistory: purchase,
        isBatchStandard: appSettings.isBatchStandard,
      });
    },
    onReset: () => { },
  });

  const createItem = async (payload) => {
    let response = await fetch(`${backendUrl}/api/warehouse/stock`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };

  const createMutation = useMutation((payload) => createItem(payload), {
    onSuccess: ({ message }) => {
      toast.success(message);
      formik.resetForm();
      if (refetch) refetch();
      onHide();
    },
    onError: ({ message = "" }) => {
      toast.error(`Unable to perform action: ${message}`);
    },
  });

  useEffect(() => {
    const UnitCost = currency(formik.values.PurchasePrice, {
      symbol: "",
      separator: "",
    })
      .add(formik.values.Freight)
      .format();
    formik.setFieldValue("UnitCost", UnitCost);
  }, [formik.values.PurchasePrice, formik.values.Freight]);

  const InvoiceAmount = useMemo(
    () =>
      currency(formik.values.PurchasePrice, {
        symbol: "",
        separator: "",
      })
        .multiply(formik.values.Quantity)
        .format(),
    [formik.values.PurchasePrice, formik.values.Quantity]
  );

  const addItemMeasurement = async () => {
    const newSize = await ItemSizeDialog();
    if (newSize) {
      queryClient.setQueryData([queryActions.ITEMS_SETUP], (data) => {
        data.itemMeasurement = [
          ...data.itemMeasurement,
          { value: newSize.Size, label: newSize.Size },
        ];
        return data;
      });
      formik.setFieldValue("Size", newSize.Size);

      // add to store
      // console.log(newSize);
      setItemMeasurements([...itemMeasurements, newSize]);
    }
  };

  const setSelectedVendor = (vendor) => {
    if (vendor) {
      queryClient.setQueryData([queryActions.ITEMS_SETUP], (data) => {
        data.vendors = [
          ...data.vendors,
          { label: vendor.CompanyName, value: vendor.Vendor_ID },
        ];
        return data;
      });
      formik.setFieldValue("Vendor", vendor.Vendor_ID);
    }
  };

  const totalLandingCost = useMemo(() => {
    return currency(formik.values.UnitCost, {
      symbol: "",
      separator: "",
    })
      .multiply(formik.values.Quantity)
      .format();
  }, [formik.values.UnitCost, formik.values.Quantity]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      dialogClassName="new-entity-modal"
      backdropClassName={`global-backdrop`}
      centered={true}
      animation={false}
      enforceFocus={false}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1>Add New Product Information</h1>
          <p>Add a new item by filling in the following forms.</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-0">
        <Form
          noValidate
          onSubmit={formik.handleSubmit}
          className="row new-item-form w-100 m-0"
          autoComplete="off"
        >
          <div className="col-md-6 px-4">
            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Item Code</Form.Label>
              <Form.Control
                className=""
                type="text"
                placeholder="Enter Item Code"
                name="Bar_Code"
                value={formik.values.Bar_Code}
                onChange={formik.handleChange}
                isInvalid={formik.touched.Bar_Code && !!formik.errors.Bar_Code}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.Bar_Code}
              </Form.Control.Feedback>
            </Form.Group>

            {/*  <Form.Group className=" mb-3 pb-2">
                <Form.Label className="mb-1">Amount Rcv</Form.Label>
                <Form.Control
                  className=""
                  type="text"
                  placeholder="Enter Amount Rcv"
                  name="username"
                />
                <Form.Control.Feedback type="invalid" />
              </Form.Group> */}

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Item Name</Form.Label>
              <Form.Control
                className=""
                type="text"
                placeholder="Enter Item Name"
                name="Item_Name"
                value={formik.values.Item_Name}
                onChange={formik.handleChange}
                isInvalid={
                  formik.touched.Item_Name && !!formik.errors.Item_Name
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.Item_Name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Product</Form.Label>
              <CreatableSelect
                classNamePrefix={`form-select`}
                options={data.products}
                value={data?.products?.find(
                  (el) => el.value === formik.values.Product_Name
                )}
                onChange={({ value }) =>
                  formik.setFieldValue("Product_Name", value)
                }
              />
              {formik.touched.Product_Name && formik.errors.Product_Name ? (
                <span className="text-danger mt-2">
                  {formik.errors.Product_Name}
                </span>
              ) : null}
            </Form.Group>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Category</Form.Label>
              <CreatableSelect
                classNamePrefix={`form-select`}
                options={data.categories}
                value={data?.categories?.find(
                  (el) => el.value === formik.values.Cat_Name
                )}
                onChange={({ value }) =>
                  formik.setFieldValue("Cat_Name", value)
                }
              />
              {formik.touched.Cat_Name && formik.errors.Cat_Name ? (
                <span className="text-danger mt-2">
                  {formik.errors.Cat_Name}
                </span>
              ) : null}
            </Form.Group>

            <Form.Group className="col-12 mb-3 pb-2">
              <Form.Label className="mb-1">Sub Category</Form.Label>
              <CreatableSelect
                classNamePrefix={`form-select`}
                options={data?.subCategories?.filter(el => el?.Cat_Name === formik.values?.Cat_Name)}
                value={data?.subCategories?.filter(el => el?.Cat_Name === formik.values?.Cat_Name).find(
                  (el) => el.value === formik.values.Sub_Cat
                )}
                onChange={({ value }) =>
                  formik.setFieldValue("Sub_Cat", value)
                }
              />
            </Form.Group>

            <div className="row text-nowrap align-items-center">
              <Form.Group className="col-5 mb-3 pb-2">
                <Form.Label className="mb-1">Unit</Form.Label>
                <Select
                  classNamePrefix={`form-select`}
                  options={Units}
                  value={Units.find((el) => el.value === formik.values.Unit)}
                  onChange={({ value }) => {
                    formik.setFieldValue("Unit", value);
                    if (!["Tons", "Pieces"].includes(value)) {
                      formik.setFieldValue("Size", "");
                    }
                  }}
                />
                <Form.Control.Feedback type="invalid" />
              </Form.Group>

              {["Tons", "Pieces"].includes(formik.values.Unit) && (
                <>
                  <Form.Group className="col-5 mb-3 pb-2">
                    <Form.Label className="mb-1">Size</Form.Label>
                    <Select
                      classNamePrefix={`form-select`}
                      options={data?.itemMeasurement}
                      value={data?.itemMeasurement.find(
                        (el) => el.value === formik.values.Size
                      )}
                      onChange={({ value }) =>
                        formik.setFieldValue("Size", value)
                      }
                    />
                  </Form.Group>

                  <Form.Group className="col-2 pb-2 px-0">
                    <Button size="sm" onClick={() => addItemMeasurement()}>
                      + Add
                    </Button>
                  </Form.Group>
                </>
              )}
            </div>

            <Form.Group className="mb-3 pb-2">
              <Form.Label>Quantity ({formik.values.Unit})</Form.Label>
              <NumberCustomInput
                placeholder="0"
                name="Quantity"
                value={formik.values.Quantity}
                onValueChange={(value, name) => {
                  formik.setFieldValue(name, value);
                }}
                isInvalid={formik.touched.Quantity && !!formik.errors.Quantity}
                onBlur={() => formik.setFieldTouched("Quantity", true)}
              />
              {formik.touched.Quantity && !!formik.errors.Quantity ? (
                <span className="custom-invalid-feedback">
                  {formik.errors.Quantity}
                </span>
              ) : null}
            </Form.Group>

            <div className="row text-nowrap align-items-center">
              <Form.Group className="col-10 mb-3 pb-2">
                <Form.Label className="mb-1">Vendor</Form.Label>
                <Select
                  classNamePrefix={`form-select`}
                  options={data.vendors}
                  value={data?.vendors?.find(
                    (el) => el.value === formik.values.Vendor
                  )}
                  onChange={({ value }) =>
                    formik.setFieldValue("Vendor", value)
                  }
                />
                {formik.touched.Vendor && formik.errors.Vendor ? (
                  <span className="text-danger mt-2">
                    {formik.errors.Vendor}
                  </span>
                ) : null}
              </Form.Group>

              <Form.Group className="col-2 pb-2 px-0">
                <Button
                  size="sm"
                  onClick={() => setShowCreateNewVendorModal(true)}
                >
                  + Add
                </Button>
              </Form.Group>
            </div>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Invoice No</Form.Label>
              <Form.Control
                className=""
                type="text"
                placeholder="Enter Invoice No"
                name="InvoiceNo"
                value={formik.values.InvoiceNo}
                onChange={formik.handleChange}
                isInvalid={
                  formik.touched.InvoiceNo && !!formik.errors.InvoiceNo
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.InvoiceNo}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Usage</Form.Label>
              <Select
                classNamePrefix={`form-select`}
                options={usageOptions}
                value={usageOptions.find(
                  (el) => el.value === formik.values.Usage
                )}
                onChange={({ value }) => formik.setFieldValue("Usage", value)}
              />
            </Form.Group>

            {/*  <Form.Group className=" mb-3 pb-2">
                <Form.Label className="mb-1">Item Description</Form.Label>
                <Form.Control
                  className=""
                  as="textarea"
                  placeholder=""
                  name="username"
                  rows={5}
                />
                <Form.Control.Feedback type="invalid" />
              </Form.Group> */}
          </div>

          <div className="col-md-6 px-4">
            <div className="row">
              <Form.Group className="col-md-6 mb-3 pb-2">
                <Form.Label className="mb-1">Purchase Price</Form.Label>
                <CurrencyCustomInput
                  name="PurchasePrice"
                  placeholder="0.00"
                  value={formik.values.PurchasePrice}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                  isInvalid={
                    formik.touched.PurchasePrice &&
                    !!formik.errors.PurchasePrice
                  }
                  onBlur={() => formik.setFieldTouched("PurchasePrice", true)}
                />
                {formik.touched.PurchasePrice &&
                  !!formik.errors.PurchasePrice ? (
                  <span className="custom-invalid-feedback">
                    {formik.errors.PurchasePrice}
                  </span>
                ) : null}
              </Form.Group>

              <Form.Group className="col-md-6 mb-3 pb-2">
                <Form.Label className="mb-1">Freight</Form.Label>
                <CurrencyCustomInput
                  name="Freight"
                  placeholder="0.00"
                  value={formik.values.Freight}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                  isInvalid={formik.touched.Freight && !!formik.errors.Freight}
                  onBlur={() => formik.setFieldTouched("Freight", true)}
                />
                {formik.touched.Freight && !!formik.errors.Freight ? (
                  <span className="custom-invalid-feedback">
                    {formik.errors.Freight}
                  </span>
                ) : null}
              </Form.Group>

              <Form.Group className="col-md-6 mb-3 pb-2">
                <Form.Label className="mb-1">
                  Unit Price (
                  {!["Tons", "Pieces"].includes(formik.values.Unit)
                    ? formik.values.Unit
                    : "Tons"}
                  )
                </Form.Label>
                <CurrencyCustomInput
                  name="UnitPrice"
                  placeholder="0.00"
                  value={formik.values.UnitPrice}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                  isInvalid={
                    formik.touched.UnitPrice && !!formik.errors.UnitPrice
                  }
                  onBlur={() => formik.setFieldTouched("UnitPrice", true)}
                />
                {formik.touched.UnitPrice && !!formik.errors.UnitPrice ? (
                  <span className="custom-invalid-feedback">
                    {formik.errors.UnitPrice}
                  </span>
                ) : null}
              </Form.Group>

              {["Tons", "Pieces"].includes(formik.values.Unit) && (
                <Form.Group className="col-md-6 mb-3 pb-2">
                  <Form.Label className="mb-1">Unit Price (Piece)</Form.Label>
                  <CurrencyCustomInput
                    name="Tax"
                    placeholder="0.00"
                    value={formik.values.Tax}
                    onValueChange={(value, name) => {
                      formik.setFieldValue(name, value);
                    }}
                    isInvalid={formik.touched.Tax && !!formik.errors.Tax}
                    onBlur={() => formik.setFieldTouched("Tax", true)}
                  />
                  {formik.touched.Tax && !!formik.errors.Tax ? (
                    <span className="custom-invalid-feedback">
                      {formik.errors.Tax}
                    </span>
                  ) : null}
                </Form.Group>
              )}

              <Form.Group className="col-md-6 mb-3 pb-2">
                <Form.Label className="mb-1">Unit Cost</Form.Label>
                <CurrencyCustomInput
                  name="UnitCost"
                  placeholder="0.00"
                  value={formik.values.UnitCost}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                  isInvalid={
                    formik.touched.UnitCost && !!formik.errors.UnitCost
                  }
                  onBlur={() => formik.setFieldTouched("UnitCost", true)}
                />
                {formik.touched.UnitCost && !!formik.errors.UnitCost ? (
                  <span className="custom-invalid-feedback">
                    {formik.errors.UnitCost}
                  </span>
                ) : null}
              </Form.Group>

              <Form.Group className="col-md-6 mb-3 pb-2">
                <Form.Label title={totalLandingCost} className="mb-1">
                  Invoice Amount
                </Form.Label>
                <CurrencyCustomInput
                  placeholder="0.00"
                  value={InvoiceAmount}
                  readOnly
                />
              </Form.Group>
            </div>

            {/*  <Form.Group className=" mb-3 pb-2">
                <Form.Label className="mb-1">Item Status</Form.Label>
                <Select classNamePrefix={`form-select`} />
                <Form.Control.Feedback type="invalid" />
              </Form.Group> */}

            {/* <Form.Group className="mb-3 pb-2">
                <Form.Label>QTY in Stock</Form.Label>
                <NumberCustomInput placeholder="0" name="qty" />
              </Form.Group> */}

            {/*  <Form.Group className=" mb-3 pb-2">
                <Form.Label className="mb-1">New Vendor</Form.Label>
                <Select classNamePrefix={`form-select`} />
                <Form.Control.Feedback type="invalid" />
              </Form.Group> */}

            {/*  <Form.Group className=" mb-3 pb-2">
                <Form.Label className="mb-1">Update Time</Form.Label>
                <Form.Control
                  defaultValue={format(new Date(), "do MM, yyy")}
                  readOnly
                />
                <Form.Control.Feedback type="invalid" />
              </Form.Group> */}

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Reorder Level</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Reorder Level"
                name="Reorder_Level"
                value={formik.values.Reorder_Level}
                onValueChange={(value, name) => {
                  formik.setFieldValue(name, value);
                }}
              />
            </Form.Group>

            <div className="row">
              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label className="mb-1">Branch</Form.Label>
                <Form.Control defaultValue={formik.values.Branch} readOnly />
              </Form.Group>
              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label className="mb-1">Expire Date</Form.Label>
                <DateTime
                  timeFormat={false}
                  closeOnSelect={true}
                  closeOnClickOutside={true}
                  dateFormat="MMM DD, YYYY"
                  inputProps={{
                    className: `date-input form-control`,
                    placeholder: "Select date",
                    readOnly: true,
                  }}
                  value={formik.values.ExpireDate}
                  onChange={(date) => {
                    formik.setFieldValue("ExpireDate", date, true);
                  }}
                />
              </Form.Group>
            </div>

            <div className="row">
              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label className="mb-1">WareHouse</Form.Label>
                <CreatableSelect
                  classNamePrefix={`form-select`}
                  options={data.warehouses}
                  value={data?.warehouses?.find(
                    (el) => el.value === formik.values.Warehouse
                  )}
                  onChange={({ value }) =>
                    formik.setFieldValue("Warehouse", value)
                  }
                />
                {formik.touched.Warehouse && formik.errors.Warehouse ? (
                  <span className="text-danger mt-2">
                    {formik.errors.Warehouse}
                  </span>
                ) : null}
              </Form.Group>
              {/*  */}

              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label className="mb-1">Storage Location</Form.Label>
                <CreatableSelect
                  classNamePrefix={`form-select`}
                  options={data.storagelocation}
                  value={data?.storagelocation?.find(
                    (el) => el.value === formik.values.StorageLocation
                  )}
                  onChange={({ value }) =>
                    formik.setFieldValue("StorageLocation", value)
                  }
                />
                {formik.touched.StorageLocation &&
                  formik.errors.StorageLocation ? (
                  <span className="text-danger mt-2">
                    {formik.errors.StorageLocation}
                  </span>
                ) : null}
              </Form.Group>

              <h2 className="mb-3 mt-4 pb-1">Delivery Information</h2>

              <Form.Group className="mb-3 pb-1">
                <Form.Label className="mb-1">Driver</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Driver's Name"
                  name="DriverName"
                  value={formik.values.DriverName}
                  onChange={formik.handleChange}
                />
              </Form.Group>

              <Form.Group className="mb-3 pb-1">
                <Form.Label className="mb-1">Driver Phone Number</Form.Label>
                <Form.Control
                  type="tel"
                  placeholder="Enter Phone number"
                  name="DriverPhoneNumber"
                  value={formik.values.DriverPhoneNumber}
                  onChange={formik.handleChange}
                />
              </Form.Group>

              <Row>
                <Form.Group className="col mb-3 pb-1">
                  <Form.Label className="mb-1">Truck Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Truck Number"
                    name="TruckNumber"
                    value={formik.values.TruckNumber}
                    onChange={formik.handleChange}
                  />
                </Form.Group>

                <Form.Group className="col mb-3 pb-2">
                  <Form.Label className="mb-1">Date</Form.Label>
                  <DateTime
                    timeFormat={false}
                    closeOnSelect={true}
                    closeOnClickOutside={true}
                    dateFormat="MMM DD, YYYY"
                    inputProps={{
                      className: `date-input form-control`,
                      placeholder: "Select date",
                      readOnly: true,
                    }}
                    value={formik.values.DeliveryDate}
                    onChange={(date) => {
                      formik.setFieldValue("DeliveryDate", date, true);
                    }}
                  />
                </Form.Group>
              </Row>
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer className="border-0">
        <Button
          onClick={() => formik.submitForm()}
          variant="primary"
          type="submit"
          className="w-50 submit-btn mt-3"
          disabled={createMutation.isLoading}
        >
          {createMutation.isLoading ? "Please wait..." : "Send To Inventory"}
        </Button>
      </Modal.Footer>

      {showCreateNewVendorModal && (
        <NewVendorModal
          showCreateNewVendorModal={showCreateNewVendorModal}
          setShowCreateNewVendorModal={setShowCreateNewVendorModal}
          setSelectedVendor={setSelectedVendor}
        />
      )}
    </Modal>
  );
}
