import { useEffect, useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import { useIsAdmin, useResolveRootRoles } from "../../utils/hooks";
import { useStoreState } from "easy-peasy";
import { useRef } from "react";
import ChevronRightIcon from "mdi-react/ChevronRightIcon";
import ChevronLeftIcon from "mdi-react/ChevronLeftIcon";
import { useAuth } from "../../hooks/useAuth";

export function HMBNav(props) {
  const { user: authUser } = useAuth();
  const { getRootRoles } = useResolveRootRoles();
  const isAdmin = useIsAdmin();
  const generalSettings = useStoreState((state) => state.generalSettings);

  const items = useMemo(() => {
    const navItems = [
      ...(authUser?.Department !== "Ministry of Health"
        ? [
          {
            name: "Pending Proforma",
            to: "/hmb-rsmoh/pending-proforma",
          },
          {
            name: "Aproved Proforma",
            to: "/hmb-rsmoh/approved-proforma",
          },
        ]
        : []),
      {
        name: "Customers",
        to: "/hmb-rsmoh/customer-list",
      },
      ...(authUser?.Department !== "Ministry of Health"
        ? [
          /*  {
            name: "Customer Purchase",
            to: "/hmb-rsmoh/sales-analysis",
          }, */
        ]
        : [
          {
            name: "Incentives",
            to: "/hmb-rsmoh/sales-incentive",
          },
        ]),
      /*  {
        name: "Customer Ledger",
        to: "/hmb-rsmoh/customer-ledger",
      }, */
      {
        name: "Aging Report",
        to: "/hmb-rsmoh/accounts-recievables-aging-report",
      },
      {
        name: "Inventory",
        to: "/hmb-rsmoh/inventory",
      },
    ];

    return navItems;
  }, [isAdmin, generalSettings, authUser?.Department]);

  const fileHolder = useRef(null);
  const [isShowLeftSroll, setShowLeftScroll] = useState(false);
  const [isShowRightSroll, setShowRightScroll] = useState(false);

  const showLeftSroll = () => {
    setShowLeftScroll(fileHolder?.current && fileHolder.current.scrollLeft > 0);
  };

  const showRightScroll = () => {
    setShowRightScroll(
      fileHolder.current &&
      fileHolder.current.offsetWidth + fileHolder.current.scrollLeft <
      fileHolder.current.scrollWidth
    );
  };

  const srcollFileHolder = () => {
    showLeftSroll();
    showRightScroll();
  };

  useEffect(() => {
    setTimeout(() => {
      if (fileHolder.current) srcollFileHolder();
    }, 50);
  }, [fileHolder]);

  return (
    <div className=" tabs-holder">
      {isShowLeftSroll && (
        <button
          className="btn slider-btn left"
          onClick={() => (fileHolder.current.scrollLeft -= 500)}
        >
          <ChevronLeftIcon />
        </button>
      )}
      <div
        ref={fileHolder}
        onScroll={(e) => srcollFileHolder(e)}
        className="dashboard-tabs innerpage-tabs px-4"
      >
        <ul className="nav nav-tabs">
          {items.map((item, index) => (
            <li key={index} className="nav-item">
              <NavLink className={`nav-link ga-nav-tab`} to={item.to}>
                {item.name}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
      {isShowRightSroll && (
        <button
          className="btn slider-btn right"
          onClick={() => (fileHolder.current.scrollLeft += 500)}
        >
          <ChevronRightIcon />
        </button>
      )}
    </div>
  );
}
