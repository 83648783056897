import currency from "currency.js";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { useState } from "react";
import { Modal, Table } from "react-bootstrap";
import { useQuery } from "react-query";
import { useAuth } from "../../hooks/useAuth";
import useDebounce from "../../utils/hooks";
import { queryActions } from "../../utils/reactQueryActions";
import "./../../assets/scss/scoped/items-table.scoped.scss";
import queryString from "query-string";
import ReactPaginate from "react-paginate";
import { paginationOptions } from "../../utils/helpers";
import ConvertQuantity from "./ConvertQuantity";

export default function ShowStorageItems({
  hideItemsNotInStock = false,
  model = "Items",
  StorageLocation,
  onHide,
  show,
  category,
  product,
  ShowName = `${StorageLocation?.name} Storage Location`,
  withExpiry = false,
}) {
  const { backendUrl } = useAuth();
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 100,
    q: "",
    model,
    StorageLocation: StorageLocation?.storageID,
    category: category?.Cat_Name,
    product: product?.Product_Name,
    withExpiry,
  });

  const debouncedqueryParams = useDebounce(queryParams, 500);
  // const [searchString, setSearchString] = useState("");
  // const debouncedSearchString = useDebounce(searchString, 500);

  const getItems = async (debouncedqueryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/items?${queryString.stringify(debouncedqueryParams)}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const { data = { items: [] } } = useQuery(
    ["STORAGE_LOCATION_GET_ITEMS", debouncedqueryParams],
    () => getItems(debouncedqueryParams),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  const getBarcode = (name) => {
    const bar = name.split("-");
    bar.pop();

    return bar.join("-");
  };

  return (
    <Modal
      onHide={onHide}
      show={show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="items-table-area rounded w-100">
          <div className="my-3">
            <h6> {ShowName}</h6>
          </div>
          <div className="search-area my-3">
            <MagnifyIcon />
            <input
              className="form-control search-input"
              name="q"
              value={queryParams.q}
              onChange={(e) =>
                setQueryParams({
                  ...queryParams,
                  page: 1,
                  [e.target.name]: e.target.value,
                })
              }
              placeholder="Search item..."
              autoFocus
              autoComplete="off"
              type={"text"}
            />
          </div>

          <div className="content p-0">
            <Table
              borderless
              responsive
              hover
              striped
              className="product-table"
            >
              <thead className="sticky text-nowrap">
                <tr>
                  {model === "StockBatches" && <th>Batch</th>}
                  <th scope="col">Item Code</th>
                  <th scope="col">Item Name</th>
                  <th scope="col">QTY</th>
                  <th scope="col">Unit Cost</th>
                  <th scope="col">Unit Price</th>
                </tr>
              </thead>
              <tbody>
                {data.items &&
                  data.items
                    .filter((el) =>
                      hideItemsNotInStock ? Number(el.Quantity) > 0 : el
                    )
                    .map((el, index) => (
                      <tr className="p-cursor" key={index}>
                        {model === "StockBatches" && (
                          <th>{el.Bar_Code.split("-").pop()}</th>
                        )}
                        <td>
                          {model === "StockBatches"
                            ? getBarcode(el.Bar_Code)
                            : el.Bar_Code}
                        </td>
                        <td title={el?.Quantity}>
                          {el?.Item_Name ? el.Item_Name : el?.ItemName}
                        </td>
                        <td
                          className="p-cursor text-nowrap"
                          title={el.Quantity}
                        >
                          <ConvertQuantity
                            quantity={el.Quantity}
                            desc={el.Item_Desc}
                          />
                        </td>
                        <td>
                          {currency(el.UnitCost, {
                            precision: 2,
                            symbol: "",
                          }).format()}
                        </td>
                        <td>
                          {currency(el.UnitPrice, {
                            precision: 2,
                            symbol: "",
                          }).format()}
                        </td>
                      </tr>
                    ))}
              </tbody>
            </Table>
          </div>

          <div className="d-flex justify-content-between px-3 my-3 align-items-center pagination">
            <p className="m-0">
              Showing {data?.startIndex + 1} to{" "}
              {data?.endIndex <= data?.count ? data?.endIndex : data?.count} of{" "}
              {data.count} entries
            </p>

            <ReactPaginate
              {...paginationOptions}
              pageCount={Math.ceil(data.count / queryParams.limit)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={0}
              onPageChange={({ selected }) => {
                document.body.scrollTop = document.documentElement.scrollTop = 0;
                setQueryParams({
                  ...queryParams,
                  page: selected + 1,
                });
              }}
              forcePage={queryParams.page - 1}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
