import { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { QueryClient, useIsFetching, useQuery } from "react-query";
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { useEffectOnce } from "../utils/hooks";
import NavBar from "./NavBar";
import { useStoreActions, useStoreState } from "easy-peasy";
import { LoaderIcon } from "./Icons";
import OfflineCheck from "./OfflineCheck";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";
import GlobalModals from "./modals/GlobalModals";
import { initialGeneralSettings } from "../utils/helpers";
import ModalLoader from "./utils/ModalLoader";
import AlreadyLoggedIn from "./AlreadyLoggedIn";
import VerifyEmailModal from "./VerifyEmailModal";
import UpdateEmailModal from "./UpdateEmailModal";
import "../assets/scss/VerifyEmail.scss";
import TermsAndConditsionModal from "./TermsAndConditionModal";
import { useCheckLoginUser } from "../hooks/useChekAuth";
import ChatbotPopup from "./ChatBot/Panel";

export default function MasterLayout() {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    user,
    backendUrl,
    customer: authCustomer,
    setDepartmentAndJobGrade,
  } = useAuth();
  const [acceptTerms, setAcceptterms] = useState(false);

  const setItemMeasurements = useStoreActions(
    (actions) => actions.setItemMeasurements
  );
  const setDefaultCustomer = useStoreActions(
    (actions) => actions.setDefaultCustomer
  );
  const setGeneralSettings = useStoreActions(
    (actions) => actions.setGeneralSettings
  );
  const setCurrentLoggedInCompany = useStoreActions(
    (actions) => actions.setCurrentLoggedInCompany
  );
  const setAccountExpire = useStoreActions(
    (actions) => actions.setAccountExpire
  );

  const setRootRoles = useStoreActions((actions) => actions.setRootRoles);
  const setStaffRoles = useStoreActions((actions) => actions.setStaffRoles);
  const setDeptRoles = useStoreActions((actions) => actions.setDeptRoles);

  const [showVerifyEmail, setShowVerifyEmail] = useState(
    user?.email && user?.isEmailVerified !== 1
  );
  const [showUpdateEmail, setShowUpdateEmail] = useState(!user?.email);
  const { checkLoggedInUser } = useCheckLoginUser();

  const isFetching = useIsFetching();
  // Pull relevant Data
  const setUp = async () => {
    let response = await fetch(`${backendUrl}/api/auth/set-up`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      if (
        String(response.message).includes("not logged in") ||
        String(response.message).includes("Invalid Token")
      ) {
        localStorage.clear();
        window.location.href = "/login";
      }
      throw new Error(response.message);
    }

    const { data } = await response.json();

    // console.log(data);
    if (data?.isExpired) {
      toast.error("Account Expired, Contact Support");
      if (data?.loggedInUser?.Department === "Admin") {
        setAccountExpire(data?.isExpired);
        return navigate("/payment-and-billing", { replace: true });
      } else {
        return navigate("/login", { replace: true });
      }
    } else {
      setAccountExpire(data?.isExpired);
    }

    if (data?.isBlocked) {
      toast.error("User is blocked, Please contact the admin");
      return navigate("/login", { replace: true });
    }

    /* if (data?.isLoggedIn) {
      toast.error("User already logged in with this account");
      return navigate("/login", { replace: true });
    } */

    // console.log(data, "logged In User");
    setItemMeasurements(data.itemMeasurements);
    setDefaultCustomer(data.defaultCustomer);
    setGeneralSettings({ ...initialGeneralSettings, ...data.settings });
    setCurrentLoggedInCompany(data.company);
    if (data?.loggedInUser) {
      setDepartmentAndJobGrade({
        Department: data?.loggedInUser.Department,
        jobGrade: data?.loggedInUser.jobGrade,
        AccessLavel: data?.loggedInUser.AccessLavel,
      });
    }

    if (Boolean(data?.loggedInUser?.email)) {
      setShowUpdateEmail(!Boolean(data?.loggedInUser?.email));
    }

    if (Boolean(data?.loggedInUser?.isEmailVerified)) {
      setShowVerifyEmail(!Boolean(data.loggedInUser.isEmailVerified));
    }

    setAcceptterms(!Boolean(data?.loggedInUser?.acceptServiceTerms));

    function isEmpty(data) {
      return data?.some((roles) => !roles.hasOwnProperty("checked"));
    }

    function noneChecked(data) {
      return data?.every((roles) => roles.checked === false);
    }

    // const isEmpty = data?.staffRoles?.some(
    //   (roles) => !roles.hasOwnProperty("checked")
    // );
    // const noneChecked = data?.staffRoles?.every(
    //   (roles) => roles.checked === false
    // );

    // staff roles
    if (
      Boolean(isEmpty(data?.staffRoles)) ||
      Boolean(noneChecked(data?.staffRoles))
    ) {
      setStaffRoles([]);
    } else {
      setStaffRoles(data?.staffRoles);
    }

    // departmental roles
    // staff roles

    if (
      Boolean(isEmpty(data?.deptRoles)) ||
      Boolean(noneChecked(data?.deptRoles))
    ) {
      setDeptRoles([]);
    } else {
      setDeptRoles(data?.deptRoles);
    }

    // Root -
    setRootRoles(data.rootRoles);

    // ------

    document.title = `${process.env.REACT_APP_SITE_TITLE} - ${user.company}`;
    return data;
  };

  const { data, isFetched, isFetching: isSettingUp } = useQuery(
    ["GLOBAL_SETUP"],
    () => setUp(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: () => {
        //   checkLoggedInUser();
        return false;
      },
    }
  );

  if (process.env.REACT_APP_IS_STORE) {
    return <Navigate to="/store" replace state={{ from: location }} />;
  }

  if (!isEmpty(authCustomer)) {
    return <Navigate to="/store" replace state={{ from: location }} />;
  }

  if (isEmpty(user)) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  const hideVerifyEmailModal = () => {
    setShowVerifyEmail(false);
  };

  const hideUpdateEmailModal = () => {
    setShowUpdateEmail(false);
  };

  const showUpdateEmailModal = () => {
    setShowUpdateEmail(true);
    setShowVerifyEmail(false);
  };

  return isFetched ? (
    <>
      <TermsAndConditsionModal acceptTerms={acceptTerms} />
      <NavBar />

      <Outlet />

      {isFetching ? (
        <div className="global-spinner">
          <LoaderIcon className="spin text-primary" />
        </div>
      ) : (
        <></>
      )}
      <footer className="d-flex py-3 align-items-center justify-content-center text-center">
        <p className="mb-0">
          ©{new Date().getFullYear()}{" "}
          <a
            href="https://invexerp.excellentbridge.com/"
            target="_blank"
            className="text-decoration-underline"
          >
            Excellentbridge Technologies
          </a>
          <br />
          <small>Version {process.env?.REACT_APP_VERSION}</small>
        </p>
      </footer>
      <VerifyEmailModal
        show={showVerifyEmail}
        onHide={hideVerifyEmailModal}
        email={user.email}
        name={user.Name}
        showUpdateEmailModal={showUpdateEmailModal}
      />
      <UpdateEmailModal show={showUpdateEmail} onHide={hideUpdateEmailModal} />
      <OfflineCheck />
      <GlobalModals />
      <AlreadyLoggedIn />
      <ChatbotPopup />
    </>
  ) : (
    <ModalLoader show={true} />
  );
}
// masters it
