import {
  Table,
  InputGroup,
  Button,
  Dropdown,
  Form,
  Modal,
} from "react-bootstrap";
import { CSVLink } from "react-csv";
import printJS from "print-js";
import {
  CalendarIcon,
  CubeIcon,
  DeleteIcon,
  EditIcon,
  ExportIcon,
  FIleUploadLineIcon,
  FilterCollapseIcon,
  PrintIcon,
} from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import { useEffect, useMemo, useRef, useState } from "react";
import DateRangePicker from "../utils/DateRangePicker";
import CachedIcon from "mdi-react/CachedIcon";
import { FilterTwoIcon, PDFIcon, ExcelIcon } from "../Icons";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import ChevronUpIcon from "mdi-react/ChevronUpIcon";
import { queryActions, reportActions } from "../../utils/reactQueryActions";
import useDebounce, {
  useChosenBackendUrl,
  useEffectOnce,
  useIsAdmin,
  useIsCashier,
  useIsStore,
  useLocalStorage,
  useQueryParams,
  useScrollTop,
} from "../../utils/hooks";
import queryString from "query-string";
import { appSettings, backendApis, services } from "../../config";
import { useMutation, useQuery, useQueryClient } from "react-query";
import currency from "currency.js";
import {
  compareAsc,
  endOfMonth,
  endOfYear,
  format,
  parse,
  setMonth,
  startOfMonth,
  startOfYear,
} from "date-fns";
import ReactPaginate from "react-paginate";
import {
  defaultSelectValue,
  paginationOptions,
  qtyFormat,
  qtyFormatToString,
  scrollToTop,
  sortOptions,
  itemLevelOptions,
  fetchActionsUtil,
  months,
} from "../../utils/helpers";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import { useAuth } from "../../hooks/useAuth";
import UpdateItemModal from "../UpdateItemModal";
import NewItemModal from "../NewItemModal";
import PurchaseDialog from "../PurchaseDialog";
import NewItemServiceModal from "../NewItemServiceModal";
import NewItemModalWrapper from "../NewItemModalWrapper";
import NewVendorModal from "../NewVendorModal";
import BatchesModal from "./BatchesModal";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import LoginDialog from "../LoginDialog";
import ConfirmDialog from "../ConfirmDialogue";
import { toast } from "react-toastify";
import CubeOutline from "mdi-react/CubeOutlineIcon";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useStoreActions, useStoreState } from "easy-peasy";
import Select from "react-select";
import { Popover } from "react-tiny-popover";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import ItemsTable from "../utils/ItemsTable";
import TableComponent from "../TableComponent";
import ConvertQuantity from "../utils/ConvertQuantity";
import { useDownloadExcel } from "../../hooks/useDownloadExcel";
import ModalLoader from "../utils/ModalLoader";
import UpdateItemPriceModal from "../UpdateItemPriceModal";
import QuantityConversionModal from "../QuantityConversionModal";
import GRNDialog from "../GRNDialog";
import NoTableItem from "../utils/NoTableItem";
import { isEmpty, uniqBy } from "lodash";
import ChevronRightIcon from "mdi-react/ChevronRightIcon";
import CurrencyInput from "react-currency-input-field";
import InItemsRequisitionModal from "../Requisition/InItemsRequisition";
import PORequisitionModal from "../Requisition/PORequisition";
import { IsPrivileged } from "../DisplayChildElement";
import ConfirmDialogue from "../ConfirmDialogue";
import { useFormik } from "formik";

const CompanySwitcher = () => {
  const { backendUrl } = useAuth();
  const options = backendApis.map((el) => ({
    ...el,
    value: el.name,
    label: el.name,
  }));

  const setSelectedCompanyForAnalytics = useStoreActions(
    (actions) => actions.setSelectedCompanyForAnalytics
  );
  const selectedCompanyForAnalytics = useStoreState(
    (state) => state.selectedCompanyForAnalytics
  );
  const selectedCompany = useMemo(() => {
    return options.find((el) =>
      selectedCompanyForAnalytics?.value
        ? el.value === selectedCompanyForAnalytics?.value
        : el.url === backendUrl
    );
  }, [options, backendUrl, selectedCompanyForAnalytics]);

  return (
    <div
      className="d-flex gap-1 align-items-center"
      style={{ marginTop: "-0.25rem" }}
    >
      <Dropdown style={{ margin: 0 }}>
        <Dropdown.Toggle
          variant=""
          className="bg-light-blue text-primary pe-2"
          bsPrefix="change"
        >
          {selectedCompany?.value}
          {"    "}
          <span className="d-inline  me-n2">
            <ChevronDownIcon />
          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu
          popperConfig={{
            strategy: "fixed",
          }}
          renderOnMount
          className=""
        >
          {options.map((el, index) => (
            <Dropdown.Item
              key={index}
              as="button"
              className={`${el.value === selectedCompany?.value ? "active" : ""
                }`}
              onClick={() => setSelectedCompanyForAnalytics({ ...el })}
            >
              {el.value}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default function EditForcastRecorder() {
  const backendUrl = useChosenBackendUrl();
  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);
  const [isCanSaveQtyToOrderOpen, setIsCanSaveQtyToOrderOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  // HOT FIX - do not try this at home
  const [authUser, _] = useLocalStorage("user", null);
  const [plan, setPlan] = useState();
  useScrollTop();

  const initialFilterParams = {
    page: 1,
    limit: 40,
    barcode: "",
    itemName: "",
    product: "",
    category: "",
    branch: "",
    withProduct: true,
    withCategory: true,
    showAll: true,
    ChangeToBrandedGeneric: false,
    month: "",
    year: "",
  };
  const [queryParams, setQueryParams] = useQueryParams({
    ...initialFilterParams,
  });
  const [filterParams, setFilterParams] = useState({
    ...queryParams,
    ...initialFilterParams,
  });

  useEffectOnce(() => {
    if (location?.state?.plan) {
      setPlan(location?.state?.plan);
      handleAccountingPeriod({
        month: location?.state?.plan.month,
        year: location?.state?.plan.year,
      });
    } else {
      navigate("/inventory-for-sales/items-forcast-recorder/demand-plan");
    }
  });

  const [tableData, setTableData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [branchesWithPlanItems, setbranchesWithPlanItems] = useState([]);

  const [consolidate, setConsolidate] = useState(false);

  const [ShowNewRequisitionModal, setShowNewRequisitionModal] = useState(false);

  const [ShowPORequisitionModal, setShowPORequisitionModal] = useState(false);

  const [showLoadingTitle, setShowLoadingTitle] = useState(
    "Downloading Excel..."
  );
  const [isLoading, setIsLoading] = useState(false);

  const generalSettings = useStoreState((state) => state.generalSettings);

  // const [brandedGeneric, setBrandedGeneric] = useState(false);

  // fetch excel hook
  const [excelData, setExcelData] = useState([]);
  const CSVLinkRef = useRef(null);
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(
    excelData,
    CSVLinkRef
  );

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams((query) => ({ ...query, ...debouncedFilterParams }));
  }, [debouncedFilterParams, setQueryParams]);

  const fetchItems = async (queryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/items/analysis-data-items/?&${queryString.stringify(
        queryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    // we need to pull what was saved in other branches
    const consItems = await Promise.all(
      backendApis.map((endPoints) => {
        return new Promise(async (resolve, reject) => {
          try {
            const responseData = await fetchActionsUtil(
              `${endPoints.url
              }/api/demandplan/plan-items?${queryString.stringify(queryParams)}`
            );

            responseData.data.dataForBranch = {
              ...endPoints,
              items: responseData?.data.items,
              company: responseData?.data.company,
            };

            resolve(responseData?.data);
          } catch (error) {
            console.log(error);
            reject(error);
          }
        });
      })
    );

    setbranchesWithPlanItems(consItems.map((el) => el.dataForBranch));

    // combine for all the buisiness units
    const conbinedPlanedItems = consItems
      .map((el) => el.dataForBranch.items)
      .flat();

    // console.log(consItems, conbinedPlanedItems);
    setSelectedData(() => uniqBy(conbinedPlanedItems, "Bar_Code"));

    // =====================================================================

    data.ItemsAnalysis = data?.ItemsAnalysis?.map((el) => ({
      ...el,
      QtyToOrder: conbinedPlanedItems
        .filter((fItem) => fItem.Bar_Code === el.Bar_Code)
        .map((el) => el.QtyToOrder)
        .reduce((a, b) => currency(a).add(b).value, 0),
      userQtyToOrder: conbinedPlanedItems
        .filter((fItem) => fItem.Bar_Code === el.Bar_Code)
        .map((el) => el.userQtyToOrder)
        .reduce((a, b) => currency(a).add(b).value, 0),
    }));

    setTableData(data.ItemsAnalysis);
    data.product = [
      {
        label: "All",
        value: "",
      },
      ...data.product.map((el) => ({
        label: el.Product_Name,
        value: el.Product_Name,
      })),
    ];

    data.category = [
      {
        label: "All",
        value: "",
      },
      ...data.category.map((el) => ({
        label: el.Cat_Name,
        value: el.Cat_Name,
      })),
    ];

    data.branch = [
      {
        label: "All",
        value: "",
      },
      ...data.branch.map((el) => ({
        label: el.Branch,
        value: el.Branch,
      })),
    ];

    data.Generic_Configurations = [
      {
        label: "All",
        value: "",
      },
      ...data.Generic_Configurations.map((el) => ({
        label: el.Generic_Configuration,
        value: el.Generic_Configuration,
      })),
    ];

    return data;
  };

  const {
    data = { count: 0, ItemsAnalysis: [] },
    refetch,
    isFetching,
    isSuccess,
  } = useQuery(
    ["ITEMS_ANALYSIS_FORCAST_FOR_EDIT", queryParams, backendUrl, plan],
    () => !consolidate && fetchItems(queryParams),
    {
      keepPreviousData: false,
      enabled: Boolean(plan?.month),
    }
  );

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams({
      ...initialFilterParams,
    });
  };

  const handleSelectedItem = (item) => {
    setFilterParams({
      ...filterParams,
      barcode: item.Bar_Code,
      itemName: item.Item_Name,
    });
    setShowItemsPopover(false);
  };

  const onSelected = (item) => {
    const theSelected = [...selectedData];

    if (theSelected.length > 0) {
      const index = theSelected.findIndex(
        (it) => it.Bar_Code === item.Bar_Code
      );
      // const vendorindex = theSelected.findIndex(
      //   (it) => it.vendor === item.vendor
      // );

      // if (vendorindex >= 0) {
      if (index >= 0) {
        theSelected.splice(index, 1);
        setSelectedData(theSelected);
      } else {
        setSelectedData((d) => [...d, item]);
      }
      // } else {
      //   setSelectedData([item]);
      // }
    } else {
      setSelectedData((d) => [...d, item]);
    }
  };

  const selectAll = (e) => {
    if (!e.target.checked) {
      setSelectedData([]);
    } else {
      setSelectedData(tableData);
    }
  };

  const handleInputChange = ({ index, name, value }) => {
    // console.log({ index, name, value });
    const oldTableData = tableData;
    oldTableData[index][name] = value;
    setTableData([...oldTableData]);
  };

  // The main table data
  const tableBodyData = (el, index) => {
    return (
      <>
        <td>
          <input
            type="checkbox"
            onChange={() => onSelected(el)}
            checked={Boolean(
              selectedData.find((ek) => ek.Bar_Code === el.Bar_Code)
            )}
          />
        </td>
        {/* <td>{data?.startIndex + index + 1}</td> */}
        <td>{index + 1}</td>
        <td>{el?.Bar_Code}</td>
        <td>{el?.Item_Name}</td>
        <td title={el.Quantity}>
          <ConvertQuantity quantity={el.Quantity} desc={el.Item_Desc} />
        </td>
        <td>{el?.MinimumLevel}</td>
        <td>{el?.MaximumLevel}</td>
        <td>{el?.Totalsales}</td>
        <td>{el?.avarage}</td>
        <td>{el?.monthlyforcast}</td>
        <td>{el?.forcast}</td>

        <td>
          <CurrencyInput
            className="form-control border-0 px-0"
            name="QtyToOrder"
            value={el?.QtyToOrder}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                onSelected(el);
                e.target.blur();
              }
            }}
            onValueChange={(value, name) =>
              handleInputChange({
                index,
                name,
                value,
              })
            }
            // disableGroupSeparators
            decimalsLimit={2}
            disabled={consolidate}
          />
        </td>
        <td>
          <CurrencyInput
            className="form-control border-0 px-0"
            name="userQtyToOrder"
            value={el?.userQtyToOrder}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                onSelected(el);
                e.target.blur();
              }
            }}
            onValueChange={(value, name) =>
              handleInputChange({
                index,
                name,
                value,
              })
            }
            // disableGroupSeparators
            decimalsLimit={2}
          // disabled={consolidate}
          />
        </td>
      </>
    );
  };

  const genericTableBodyData = (el, index) => {
    return (
      <>
        <td>{index + 1}</td>
        <td>{el?.Generic_Configuration}</td>

        <td title={el.Quantity}>
          <ConvertQuantity quantity={el.Quantity} desc={el.Item_Desc} />
        </td>
        <td>{el?.MinimumLevel}</td>
        <td>{el?.MaximumLevel}</td>
        <td>{el?.Totalsales}</td>
        <td>{el?.avarage}</td>
        <td>{el?.monthlyforcast}</td>
        <td>{el?.forcast}</td>

        <td>{el?.QtyToOrder}</td>
        <td>{el?.userQtyToOrder}</td>
        {/* <td>
            <Link to="/not-subscribed" className="btn btn-primary text-nowrap">
              Reorder Now
            </Link>
          </td> */}
      </>
    );
  };
  const genericTableBodyDataStandard = (el, index) => {
    return (
      <>
        <td>{index + 1}</td>
        <td>{el?.Generic_Configuration}</td>

        <td title={el.Quantity}>
          <ConvertQuantity quantity={el.Quantity} desc={el.Item_Desc} />
        </td>
        <td>{el.averageDailySales}</td>
        <td>{el.minimumReorderLevel}</td>
        <td>{el.maximumReorderLevel?.toFixed(2)}</td>
        <td>{el.reorderQuantity}</td>
        <td>{el.safetyStock?.toFixed(2)}</td>
        {/* <td>
            <Link to="/not-subscribed" className="btn btn-primary text-nowrap">
              Reorder Now
            </Link>
          </td> */}
      </>
    );
  };

  const tableHead = () => {
    return (
      <thead>
        <tr>
          <th>
            <input
              type="checkbox"
              onChange={selectAll}
              checked={Boolean(selectedData.length === tableData.length)}
            />
          </th>
          <th>S/N</th>
          <th>Item Code</th>
          <th>Item Name</th>
          <th>
            Quantity <br /> In Stock
          </th>
          <th>
            Minimum <br /> Level
          </th>
          <th>
            Maximum <br /> Level
          </th>
          <th>
            Total Sales <br /> in last {data?.analyseDataFrom} <br /> Months
          </th>
          <th>
            Average <br /> Sales <br /> Per Month
          </th>
          <th>
            Recommended <br /> Qty to Order <br /> for next 1 Month
          </th>
          <th>
            Recommended <br /> Qty to Order <br /> for next{" "}
            {data?.forcastDataFor} Months
          </th>
          <th>
            Quantity <br /> to order
          </th>
          <th>
            User Quantity <br /> to Order
          </th>
          {/* <hr /> */}
        </tr>
      </thead>
    );
  };

  const standardTableHead = () => {
    return (
      <thead>
        <tr>
          <th>
            <input
              type="checkbox"
              onChange={selectAll}
              checked={Boolean(selectedData.length === tableData.length)}
            />
          </th>
          <th>S/N</th>
          <th>Item Code</th>
          <th>Item Name</th>
          <th>UOM</th>
          <th>
            Quantity <br /> In Stock
          </th>
          <th>Total Sales</th>
          <th>
            Average <br /> Daily sales
          </th>
          <th>
            Average <br /> Monthly sales
          </th>
          <th>
            Minimum <br /> Reorder Level
          </th>
          <th>
            Maximum <br /> Reorder Level
          </th>

          <th>
            Reorder <br /> Quantity{" "}
          </th>

          <th>Safety Stock</th>
          <th>
            User Quantity <br /> to Order
          </th>
          <th>{"    "}</th>

          {/* <hr /> */}
        </tr>
      </thead>
    );
  };

  const standardTableBodyData = (el, index) => {
    return (
      <>
        <td>
          <input
            type="checkbox"
            onChange={() => onSelected(el)}
            checked={Boolean(
              selectedData.find((ek) => ek.Bar_Code === el.Bar_Code)
            )}
          />
        </td>
        {/* <td>{data?.startIndex + index + 1}</td> */}
        <td>{index + 1}</td>
        <td>{el?.Bar_Code}</td>
        <td>{el?.Item_Name}</td>
        <td>{el?.Product_Model}</td>
        <td title={el.Quantity}>
          <ConvertQuantity quantity={el.Quantity} desc={el.Item_Desc} />
        </td>
        <td>{el?.Totalsales}</td>
        <td>{el.averageDailySales}</td>
        <td>{el.averageMonth}</td>
        <td>{el.minimumReorderLevel}</td>
        <td>{el.maximumReorderLevel?.toFixed(2)}</td>
        <td>{el.reorderQuantity}</td>
        <td>{el.safetyStock?.toFixed(2)}</td>
        <td>
          <CurrencyInput
            className="form-control border-1 px-2 shadow"
            name="userQtyToOrder"
            value={el?.userQtyToOrder}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                onSelected(el);
                e.target.blur();
              }
            }}
            onValueChange={(value, name) =>
              handleInputChange({
                index,
                name,
                value,
              })
            }
            decimalsLimit={2}
          //    disabled={consolidate}
          />
        </td>
        <td>
          <input
            type="checkbox"
            onChange={() => onSelected(el)}
            checked={Boolean(
              selectedData.find((ek) => ek.Bar_Code === el.Bar_Code)
            )}
          />
        </td>
      </>
    );
  };

  const genericTableHead = () => {
    return (
      <thead>
        <tr>
          <th>S/N</th>
          <th>Generic Name</th>

          <th>
            Quantity <br /> In Stock
          </th>
          <th>
            Minimum <br /> Level
          </th>
          <th>
            Maximum <br /> Level
          </th>
          <th>
            Total Sales <br /> in last {data?.analyseDataFrom} <br /> Months
          </th>
          <th>
            Average <br /> Sales <br /> Per Month
          </th>
          <th>
            Recommended <br /> Qty to Order <br /> for next 1 Month
          </th>
          <th>
            Recommended <br /> Qty to Order <br /> for next{" "}
            {data?.forcastDataFor} Months
          </th>
          <th>
            Quantity <br /> to order
          </th>
          <th>
            User Quantity <br /> to Order
          </th>
        </tr>
      </thead>
    );
  };

  const genericTableHeadStandard = () => {
    return (
      <thead>
        <tr>
          <th>S/N</th>
          <th>Generic Name</th>

          <th>
            Quantity <br /> In Stock
          </th>

          <th>
            Average <br /> Daily sales
          </th>
          <th>
            Minimum <br /> Reorder Level
          </th>
          <th>
            Maximum <br /> Reorder Level
          </th>

          <th>
            Reorder <br /> Quantity{" "}
          </th>

          <th>Safety Stock</th>
        </tr>
      </thead>
    );
  };

  const onDownloadExcelData = async () => {
    const { limit, page, ...rest } = queryParams;
    let exData = await fetchExcelData(
      `${backendUrl}/api/items/analysis-data-items?${queryString.stringify(
        rest
      )}`,
      "GET"
    );

    exData = exData?.data?.ItemsAnalysis?.map((d, i) => [
      data?.startIndex + i + 1,
      d?.Bar_Code,
      d?.Item_Name,
      d?.Quantity,
      d?.MinimumLevel,
      d?.MaximumLevel,
      d?.Totalsales,
      d?.avarage,
      d?.monthlyforcast,
      d?.forcast,
      d?.QtyToOrder,
    ]);
    const date =
      rest.startDate && rest.endDate
        ? `Date Prepared: Between ${format(
          new Date(rest.startDate),
          "E MMM d yyyy k:mm:ss z"
        )} to ${format(new Date(rest.endDate), "E MMM d yyyy k:mm:ss z")}`
        : "";
    exData = [
      ["Demand Plan"],
      [date],
      [""],
      [
        "S/N",
        "Item Code",
        "Item Name",
        "Quantity In Stock",
        "Mininum Level",
        "Maximum Level",
        `Total Sales in last ${data?.analyseDataFrom} Months`,
        "Avarage Sales Per Month",
        "Recommended Qty to Order for next 1 Month",
        `Recommended Qty to Order for next ${data?.forcastDataFor} Months`,
        "User Quantity to Order",
      ],
      ...exData,
      [""],
    ];
    //console.log(exData);
    setExcelData(exData);
  };

  const consolidateItems = async (filters) => {
    setShowLoadingTitle("Consolidating...");
    setIsLoading(true);

    const consItems = await Promise.all(
      backendApis.map((endPoints) => {
        return new Promise(async (resolve, reject) => {
          try {
            const items = await fetchActionsUtil(
              `${endPoints.url
              }/api/items/analysis-data-items/?&${queryString.stringify(
                filters
              )}&requesterName=${endPoints.name}`
            );

            const responseData = await fetchActionsUtil(
              `${endPoints.url
              }/api/demandplan/plan-items?${queryString.stringify(queryParams)}`
            );

            const conbinedPlanedItems = responseData?.data.items;

            items.data.ItemsAnalysis = items?.data.ItemsAnalysis.map((el) => {
              const foundPreviouslySaved = conbinedPlanedItems.find(
                (fItem) => fItem.Bar_Code === el.Bar_Code
              );

              return {
                ...el,
                QtyToOrder: foundPreviouslySaved?.QtyToOrder,
                userQtyToOrder: foundPreviouslySaved?.userQtyToOrder,
              };
            });

            items.data.dataForBranch = {
              ...endPoints,
              items: responseData?.data.items,
              company: responseData?.data.company,
            };

            resolve(items?.data);
          } catch (error) {
            console.log(error);
            reject(error);
          }
        });
      })
    );

    setbranchesWithPlanItems(consItems.map((el) => el.dataForBranch));
    setSelectedData(
      uniqBy(consItems.map((el) => el.dataForBranch.items).flat(), "Bar_Code")
    );

    const array = [];

    Object.entries(consItems).forEach((el, i) => {
      array.push(el[1].ItemsAnalysis);
    });

    const items = [...new Set(array.flat(1).map((d) => d.Bar_Code))].map(
      (el) => {
        const item = array.flat(1)?.filter((a) => a.Bar_Code === el);
        const userQty = item?.reduce((a, b) => a + parseFloat(b.QtyToOrder), 0);

        return {
          Bar_Code: item[0]?.Bar_Code,
          Item_Desc: item[0]?.Item_Desc,
          Item_Name: item[0]?.Item_Name,
          MaximumLevel: item?.reduce(
            (a, b) => a + parseFloat(b.MaximumLevel),
            0
          ),
          MinimumLevel: item?.reduce(
            (a, b) => a + parseFloat(b.MinimumLevel),
            0
          ),
          Quantity: item?.reduce((a, b) => a + parseFloat(b.Quantity), 0),
          Totalsales: item?.reduce((a, b) => a + parseFloat(b.Totalsales), 0),
          avarage: item?.reduce((a, b) => a + parseFloat(b.avarage), 0),
          forcast: item?.reduce((a, b) => a + parseFloat(b.forcast), 0),
          monthlyforcast: item?.reduce(
            (a, b) => a + parseFloat(b.monthlyforcast),
            0
          ),
          QtyToOrder: item?.reduce((a, b) => a + parseFloat(b.forcast), 0),
          Generic_Configuration: item[0]?.Generic_Configuration,
          userQtyToOrder: isNaN(userQty)
            ? 0
            : item?.reduce((a, b) => a + parseInt(b.userQtyToOrder), 0),
          requesterName: el?.requesterName,
          // ---------------------------------

          safetyStock: item?.reduce((a, b) => a + parseFloat(b.safetyStock), 0),
          reorderQuantity: item?.reduce(
            (a, b) => a + parseFloat(b.reorderQuantity),
            0
          ),
          maximumReorderLevel: item?.reduce(
            (a, b) => a + parseFloat(b.maximumReorderLevel),
            0
          ),
          minimumReorderLevel: item?.reduce(
            (a, b) => a + parseFloat(b.minimumReorderLevel),
            0
          ),
          averageDailySales: item?.reduce(
            (a, b) => a + parseFloat(b.averageDailySales),
            0
          ),
        };
      }
    );

    setTableData(items);
    console.log("items =>", items);
    setShowLoadingTitle("Consolidating Completed ✔️");
    setIsLoading(false);
  };

  useEffect(() => {
    if (consolidate) {
      setFilterParams({
        ...filterParams,
        showAll: false,
      });
    }
  }, [consolidate]);

  useEffect(() => {
    if (!filterParams.showAll && consolidate) {
      consolidateItems(filterParams);
    }

    if (filterParams.ChangeToBrandedGeneric) {
      setConsolidate(false);
    }
  }, [filterParams]);

  const saveQuantityToOrder = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/items/create-analysis-data-items`,
        "POST",
        "",
        payload
      ),
    {
      onSuccess: ({ message }) => {
        setIsCanSaveQtyToOrderOpen(false);
        toast.success(message);
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  const sendOrderItem = async (plan) => {
    if (
      await ConfirmDialogue({
        title: "Save Quantity to Order",
        description: "Are you sure you want to save quantity to order",
      })
    ) {
      // plan.analyseDataFrom = filterParams.analyseDataFrom
      saveQuantityToOrder.mutate({ selectedData, plan });
    }
  };

  const handleAccountingPeriod = ({ month, year }) => {
    if (!year) {
      year = new Date().getFullYear();
    }

    // Invalid year
    if (year < 1000) {
      setFilterParams({
        ...filterParams,
        startDate: "",
        endDate: "",
      });
      return;
    }

    if (month === "Date Range") {
      //
      setFilterParams({
        ...filterParams,
        month,
      });
    } else if (month === "All") {
      // All in year
      const start = startOfYear(new Date(year, 0));
      const end = endOfYear(new Date(year, 0));

      setFilterParams({
        ...filterParams,
        month,
        startDate: format(start, "yyyy-MM-dd"),
        endDate: format(end, "yyyy-MM-dd"),
        year,
      });
    } else {
      // All in month and year
      const start = startOfMonth(new Date(year, month));
      const end = endOfMonth(new Date(year, month));

      setFilterParams({
        ...filterParams,
        month,
        startDate: format(start, "yyyy-MM-dd"),
        endDate: format(end, "yyyy-MM-dd"),
        year,
      });
    }
  };

  const monthRef = useRef(null);

  const scrollToMonth = () => {
    monthRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const splitItems = useMemo(() => {
    if (isEmpty(branchesWithPlanItems)) {
      return [];
    }
    let result = branchesWithPlanItems.map((branch, index) => {
      return branch.items.map((item) => ({
        Generic_Configuration: item.Generic_Configuration,
        Generic_Configuration: item.Generic_Configuration,
        Pack_Size: item?.Pack_Size,
        Sendercompany: authUser?.company,
        Tier: item.Tier,
        barcode: item.Bar_Code,
        issueTo: branch.name,
        issueToAddress: branch.company.AddressLine1,
        issueToCompanyName: branch.company.CompName,
        issueToPhone: branch.company.Phone,
        itemName: item.Item_Name,
        priority: index,
        quantity: item.userQtyToOrder,
        receivingofficer: "",
        remaining: 0,
        sendTo: `${branch.url}/api/itemissuer/receiver`,
        warehouse: " ",
        warehouseName: "",
      }));
    });

    result = result.map((el) => el || []).flat();

    return [...new Set(result)];
  }, [branchesWithPlanItems, authUser?.company]);

  return (
    <>
      <section className="cashbook-wrapped">
        <main className="cash-book">
          <div className="content">
            <div className="content-main">
              <div className={`filter-area ${showFilter ? "open" : "close"}`}>
                <div className="filter-header">
                  <h2>
                    <FilterTwoIcon /> Filters
                  </h2>

                  <Button
                    variant="white"
                    onClick={() => setShowFilter(!showFilter)}
                  >
                    <FilterCollapseIcon />
                  </Button>
                </div>
                <div className="filter-body">
                  <Form>
                    <div className="body">
                      <Form.Group className="mb-2-5">
                        <Form.Label>Item Code</Form.Label>

                        <InputGroup>
                          <Form.Control
                            name="barcode"
                            placeholder="Enter Item Code"
                            value={filterParams?.barcode}
                            onChange={(e) => handleFilterParamsChange(e)}
                          />

                          <Popover
                            isOpen={showItemsPopover}
                            onClickOutside={() => setShowItemsPopover(false)}
                            content={() => (
                              <ItemsTable
                                handleSelectedItem={handleSelectedItem}
                              />
                            )}
                            position="bottom"
                          >
                            <InputGroup.Text
                              onClick={() =>
                                setShowItemsPopover(!showItemsPopover)
                              }
                            >
                              <MagnifyIcon />
                            </InputGroup.Text>
                          </Popover>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group className="mb-2-5">
                        <Form.Label>Item Name</Form.Label>
                        <Form.Control
                          name="itemName"
                          value={filterParams?.itemName}
                          placeholder="Enter Item Name"
                          onChange={(e) => handleFilterParamsChange(e)}
                        />
                      </Form.Group>
                      <hr className="mt-3 mb-4" />
                      <Form.Group className="mb-2-5">
                        <Form.Label>Manufacturer</Form.Label>
                        <Select
                          classNamePrefix="form-select"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          placeholder="All"
                          name="product"
                          isSearchable={true}
                          key={data?.product}
                          onChange={(selected) => {
                            setFilterParams({
                              ...filterParams,
                              product: selected.value,
                            });
                          }}
                          defaultValue={defaultSelectValue(
                            queryParams?.product,
                            data?.product,
                            { value: "", label: "All" }
                          )}
                          options={data?.product || []}
                        />
                      </Form.Group>
                      <Form.Group className="mb-2-5">
                        <Form.Label>Category</Form.Label>
                        <Select
                          classNamePrefix="form-select"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          placeholder="All"
                          name="category"
                          isSearchable={true}
                          key={data?.category}
                          onChange={(selected) => {
                            setFilterParams({
                              ...filterParams,
                              category: selected.value,
                            });
                          }}
                          defaultValue={defaultSelectValue(
                            queryParams?.category,
                            data?.category,
                            { value: "", label: "All" }
                          )}
                          options={data?.category || []}
                        />
                      </Form.Group>

                      <Form.Group className="mb-2-5">
                        <Form.Label>Level</Form.Label>
                        <Select
                          classNamePrefix="form-select"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          placeholder="All"
                          name="branch"
                          isSearchable={true}
                          onChange={(selected) => {
                            setFilterParams({
                              ...filterParams,
                              checkLevel: selected.value,
                            });
                          }}
                          options={itemLevelOptions || []}
                          isDisabled={true}
                        />
                      </Form.Group>
                      <hr className="mt-3 mb-4" />
                    </div>

                    <div className="buttons rounded">
                      <Button onClick={() => reset()} variant="white">
                        Reset
                      </Button>
                      <Button onClick={() => search()} variant="primary">
                        Search
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
              <div className="content-body">
                <header>
                  <h1>
                    {!showFilter && (
                      <button
                        onClick={() => setShowFilter(!showFilter)}
                        className="btn filter"
                      >
                        <FilterTwoIcon />
                      </button>
                    )}
                    <EditIcon />
                    &nbsp;&nbsp;Edit Demand Plan
                    <button
                      onClick={() => refetch()}
                      className="btn text-primary"
                      title="Refresh"
                    >
                      <CachedIcon />
                    </button>
                    {plan ? (
                      <>
                        <p className="fw-5">
                          {plan?.month
                            ? format(setMonth(new Date(), plan?.month), "MMMM")
                            : "..."}
                          {" - "}
                          {plan?.year}
                        </p>
                        <br />

                      </>
                    ) : null}
                    <small className="px-3">{authUser?.company}</small>
                  </h1>



                  <div className="actions mr-5" style={{ zIndex: "3" }}>
                    {/*  IS SAVED  */}
                    {consolidate && (
                      <Dropdown>
                        <Dropdown.Toggle
                          variant=""
                          className="btn btn-primary"
                          bsPrefix=""
                          disabled={selectedData?.length > 0 ? false : true}
                        >
                          <span className="mx-2">Order Items</span>

                          <FIleUploadLineIcon color="#fff" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          popperConfig={{
                            strategy: "fixed",
                          }}
                          renderOnMount
                          className="text-center"
                        >
                          <Dropdown.Item
                            as="div"
                            onClick={() => setShowNewRequisitionModal(true)}
                            className="p-cursor"
                          >
                            <b>Create RFQ</b> <CubeIcon color="#000" />
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="div"
                            onClick={() => setShowPORequisitionModal(true)}
                            className="p-cursor"
                          >
                            <b> Create PO </b>
                            <CubeIcon color="#000" />
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    )}

                    {/*   {!consolidate && <CompanySwitcher />} */}
                  </div>
                </header>

                <div className="d-flex justify-content-end align-items-center px-4 pb-3">
                  {plan ? <p>Analyze Data for planning from (Months): {plan.analyseDataFrom}</p> : null}

                  <Form.Group className="mx-4">
                    <Form.Check
                      type="switch"
                      label="Consolidate"
                      className="ga-form-check fs-6"
                      checked={consolidate}
                      onChange={() => {
                        if (!consolidate && !filterParams.month) {
                          scrollToMonth();
                          return toast.info(
                            "Select Month, so that other branches (BU) can be consolidated"
                          );
                        }
                        setConsolidate(!consolidate);
                      }}
                      disabled={filterParams?.ChangeToBrandedGeneric}
                    />
                  </Form.Group>

                  {/*   <Form.Group className="mx-4">
                    <Form.Check
                      type="switch"
                      label="Group Generic"
                      className="ga-form-check fs-6"
                      checked={filterParams?.ChangeToBrandedGeneric}
                      name="ChangeToBrandedGeneric"
                      onChange={(e) => {
                        handleFilterParamsChange(e);
                      }}
                    />
                  </Form.Group> */}
                  {/*  )} */}

                  {/*    <Form.Group className="mx-4">
                    <Form.Check
                      type="switch"
                      label="Show All"
                      name="showAll"
                      className="ga-form-check fs-6"
                      checked={queryParams.showAll}
                      onChange={handleFilterParamsChange}
                      disabled={consolidate}
                    />
                  </Form.Group> */}
                  {!consolidate && (
                    <button
                      className="btn btn-primary"
                      onClick={() => setIsCanSaveQtyToOrderOpen(true)}
                      disabled={selectedData?.length > 0 ? false : true}
                    >
                      Save Changes
                    </button>
                  )}
                </div>

                <div className="px-md-4">
                  {generalSettings?.standardProcurementPlan ===
                    "Custom Plan" && (
                      <TableComponent
                        responsive
                        borderless
                        striped
                        tableHeadsFunction={
                          filterParams?.ChangeToBrandedGeneric
                            ? genericTableHeadStandard
                            : standardTableHead
                        }
                        mainDataArray={tableData}
                        tableDataRowFunction={
                          filterParams?.ChangeToBrandedGeneric
                            ? genericTableBodyDataStandard
                            : standardTableBodyData
                        }
                        className="product-table"
                      />
                    )}

                  {generalSettings?.standardProcurementPlan ===
                    "Default Plan" && (
                      <TableComponent
                        responsive
                        borderless
                        striped
                        tableHeadsFunction={
                          filterParams?.ChangeToBrandedGeneric
                            ? genericTableHead
                            : tableHead
                        }
                        mainDataArray={tableData}
                        tableDataRowFunction={
                          filterParams?.ChangeToBrandedGeneric
                            ? genericTableBodyData
                            : tableBodyData
                        }
                        className="product-table"
                      />
                    )}

                  {/*
                    {generalSettings?.standardProcurementPlan ? (
                      <TableComponent
                        responsive
                        borderless
                        striped
                        tableHeadsFunction={
                          filterParams?.ChangeToBrandedGeneric
                            ? genericTableHead
                            : standardTableHead
                        }
                        mainDataArray={tableData}
                        tableDataRowFunction={
                          filterParams?.ChangeToBrandedGeneric
                            ? genericTableBodyData
                            : standardTableBodyData
                        }
                        className="product-table"
                      />
                    ) : (
                      <TableComponent
                        responsive
                        borderless
                        striped
                        tableHeadsFunction={
                          filterParams?.ChangeToBrandedGeneric
                            ? genericTableHead
                            : tableHead
                        }
                        mainDataArray={tableData}
                        tableDataRowFunction={
                          filterParams?.ChangeToBrandedGeneric
                            ? genericTableBodyData
                            : tableBodyData
                        }
                        className="product-table"
                      />
                    )} */}

                  {/*  {generalSettings?.standardProcurementPlan}
                   */}
                  {!isFetching && isSuccess && isEmpty(tableData) ? (
                    <NoTableItem queryParams={queryParams} />
                  ) : null}
                </div>

                <div className="d-flex justify-content-between px-3 align-items-center pagination">
                  {/*<p className="m-0">
                  Showing {data?.startIndex + 1} to{" "}
                  {data?.endIndex <= data?.count ? data?.endIndex : data?.count}{" "}
                  of {data.count} entries
                    </p> */}
                  <div className="pagination_left">
                    <p className="m-0 p-0">Show</p>
                    <select
                      value={queryParams.limit}
                      name="limit"
                      className="form-select "
                      onChange={(e) => handleSearchQueryChange(e)}
                    >
                      <option value="10">10 rows</option>
                      <option value="20">20 rows</option>
                      <option value="30">30 rows</option>
                      <option value="40">40 rows</option>
                      <option value="50">50 rows</option>
                      <option value="100">100 rows</option>
                    </select>
                  </div>

                  <ReactPaginate
                    {...paginationOptions}
                    pageCount={Math.ceil(data.count / queryParams.limit)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={0}
                    onPageChange={({ selected }) => {
                      scrollToTop();
                      setQueryParams({
                        ...queryParams,
                        page: selected + 1,
                      });
                    }}
                    forcePage={queryParams.page - 1}
                  />
                </div>
              </div>
            </div>
          </div>

          <ModalLoader
            title={showLoadingTitle}
            show={isfetchingExcel || isLoading || saveQuantityToOrder.isLoading}
          />

          {/*  <QuantityConversionModal /> */}
          {ShowNewRequisitionModal && (
            <InItemsRequisitionModal
              setShowNewRequisitionModal={setShowNewRequisitionModal}
              ShowNewRequisitionModal={ShowNewRequisitionModal}
              selectedRFQ={selectedData.map((el) => ({
                ...el,
                QtyToOrder: el.userQtyToOrder,
              }))}
            />
          )}

          {ShowPORequisitionModal && (
            <PORequisitionModal
              setShowNewRequisitionModal={setShowPORequisitionModal}
              ShowNewRequisitionModal={ShowPORequisitionModal}
              planItems={selectedData.map((el) => ({
                ...el,
                QtyToOrder: el.userQtyToOrder,
              }))}
              splitItems={splitItems}
              demandPlan={plan}
            />
          )}
        </main>
      </section>
      {isCanSaveQtyToOrderOpen && (
        <SaveQuantityToOrderOtherData
          show={isCanSaveQtyToOrderOpen}
          onHide={setIsCanSaveQtyToOrderOpen}
          sendOrderItem={sendOrderItem}
          saveQuantityToOrder={saveQuantityToOrder}
          month={filterParams.month}
          year={filterParams.year}
        />
      )}
    </>
  );
}

function SaveQuantityToOrderOtherData({
  sendOrderItem,
  show,
  onHide,
  saveQuantityToOrder,
  month,
  year,
}) {
  const [filterParams, setFilterParams] = useState({ month, year });

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
      enableDateRange: true,
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const handleAccountingPeriod = ({ month, year }) => {
    if (!year) {
      year = new Date().getFullYear();
    }

    // Invalid year
    if (year < 1000) {
      setFilterParams({
        ...filterParams,
        startDate: "",
        endDate: "",
      });
      return;
    }

    if (month === "Date Range") {
      //
      setFilterParams({
        ...filterParams,
        month,
      });
    } else if (month === "All") {
      // All in year
      const start = startOfYear(new Date(year, 0));
      const end = endOfYear(new Date(year, 0));

      setFilterParams({
        ...filterParams,
        month,
        startDate: format(start, "yyyy-MM-dd"),
        endDate: format(end, "yyyy-MM-dd"),
        year,
      });
    } else {
      // All in month and year
      const start = startOfMonth(new Date(year, month));
      const end = endOfMonth(new Date(year, month));

      setFilterParams({
        ...filterParams,
        month,
        startDate: format(start, "yyyy-MM-dd"),
        endDate: format(end, "yyyy-MM-dd"),
        year,
      });
    }
  };

  return (
    <Modal show={show} onHide={onHide} enforceFocus={false} centered>
      <Modal.Header closeButton>
        <Modal.Title className="h5">Save Demand Plan</Modal.Title>
      </Modal.Header>

      {/*   GET our month */}
      <Modal.Body>
        {/*  <Form.Group className="mb-2-5">
          <Form.Label>Title</Form.Label>
          <Form.Control
            type="text"
            name="title"
            value={filterParams?.title}
            placeholder="Enter Title"
            onChange={(e) => {
              setFilterParams({
                ...filterParams,
                title: e.target.value,
              });
            }}
          />
        </Form.Group> */}

        <Form.Group
          className="mb-2-5"
          style={{ zIndex: 10, position: "relative" }}
        >
          <Form.Label>Month</Form.Label>
          <Select
            classNamePrefix="form-select"
            placeholder="Select Month"
            isSearchable={true}
            options={months.filter((el) => el.value !== "Date Range")}
            value={months.find((el) => el.value == filterParams.month)}
            onChange={(selected) =>
              handleAccountingPeriod({
                month: selected?.value,
                year: filterParams.year,
              })
            }
            isClearable={false}
            menuPosition="fixed"
          />
        </Form.Group>

        <Form.Group className="mb-2-5">
          <Form.Label>Year</Form.Label>
          <Form.Control
            type="number"
            name="year"
            value={filterParams?.year}
            placeholder="Enter Year"
            onChange={(e) => {
              handleAccountingPeriod({
                month: filterParams.month,
                year: e.target.value,
              });
            }}
          />
        </Form.Group>
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="primary"
          onClick={() => sendOrderItem(filterParams)}
          disabled={saveQuantityToOrder.isLoading}
        >
          Post
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
