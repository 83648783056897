import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Logo } from "../Icons";
import NavBar from "../NavBar";
import { useAuth } from "../../hooks/useAuth";
import { isEmpty } from "lodash";

export function NoMatch() {
  const navigate = useNavigate();
  const { user: authUser } = useAuth();
  return (
    <>
      {!isEmpty(authUser) && <NavBar />}
      <div className="vh-100 vw-100 d-flex align-items-center justify-content-center text-center">
        <div>
          <Logo />
          <h1 className="display-1">404</h1> <p className="">Page Not found</p>
          <Button
            onClick={() => navigate(-1)}
            variant=""
            className="text-primary text-underline"
          >
            Go back
          </Button>
          <br />
          <small>Version {process.env?.REACT_APP_VERSION}</small>
        </div>
      </div>
    </>
  );
}
