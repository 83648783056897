import Modal from "react-bootstrap/Modal";
import { useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import CreatableSelect from "react-select/creatable";

import { useAuth } from "../../hooks/useAuth";
import { fetchActionsUtil } from "../../utils/helpers";
import ConfirmDialog from "../ConfirmDialogue";
import ModalLoader from "../utils/ModalLoader";
import CloseIcon from "mdi-react/CloseIcon";
import { queryActions } from "../../utils/reactQueryActions";

const AddEditSubCategoryModal = (props) => {
  const { backendUrl, token } = useAuth();

  const setUp = async (department) => {
    let response = await fetch(`${backendUrl}/api/items/set-up`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    data.categories = data.categories.map((el) => ({
      ...el,
      value: el.Cat_Name,
      label: el.Cat_Name,
    }));

    return data;
  };
  const {
    data = {
      categories: [],
    },
  } = useQuery([queryActions.ITEMS_SETUP], () => setUp(), {
    enabled: true,
  });

  const saveCategory = useMutation(
    (payload) =>
      fetchActionsUtil(`${backendUrl}/api/items/sub-category`, "POST", "", payload),
    {
      onSuccess: () => {
        toast.success("Category created Successfully");
        formik.resetForm({
          Cat_Name: "",
          Sub_Cat: "",
        });
        props?.refetch && props?.refetch();
        props?.onHide();
      },
      onError: ({ message }) => {
        toast.error(message ? message : "Error!!!", {
          position: "top-right",
        });
      },
    }
  );

  const replaceCategory = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/items/update-sub-category-items`,
        "POST",
        "",
        payload
      ),
    {
      onSuccess: () => {
        toast.success("Category deleted Successfully");
        formik.resetForm({
          Cat_Name: "",
          Sub_Cat: "",
        });
        props?.refetch && props?.refetch();
        props?.onHide();
      },
      onError: ({ message }) => {
        toast.error(message ? message : "Error!!!", {
          position: "top-right",
        });
      },
    }
  );

  const editCategory = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/items/edit-sub-category/${props?.selectedCategory?.id}`,
        "POST",
        "",
        payload
      ),
    {
      onSuccess: () => {
        toast.success("Category updated Successfully");
        formik.resetForm({
          Cat_Name: "",
          Sub_Cat: "",
        });
        props?.refetch && props?.refetch();
        props?.setSelectedCategory && props?.setSelectedCategory()
        props?.onHide();
      },
      onError: ({ message }) => {
        toast.error(message ? message : "Error!!!", {
          position: "top-right",
        });
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      Cat_Name: "",
      Sub_Cat: "",
    },
    validationSchema: yup.object().shape({
      Sub_Cat: yup.string().required("Category is required"),
    }),
    onSubmit: async (values) => {
      if (
        await ConfirmDialog({
          title: `${props?.editStorage
            ? "Edit"
            : props?.deleteSub_Cat
              ? "Replace"
              : "Create"
            } Category`,
          description: `Are you sure you want to ${props?.editStorage
            ? "Edit"
            : props?.deleteSub_Cat
              ? "Replace"
              : "Create"
            } this category `,
        })
      ) {
        // console.log(values);
        if (props?.editStorage) {
          editCategory.mutate(values);
        } else if (props?.deleteSub_Cat) {
          replaceCategory.mutate({
            Cat_Name: values.name,
            deleteSub_Cat: props?.deleteSub_Cat,
          });
        } else {
          saveCategory.mutate({ ...values, id: props?.selectedCategory?.id });
        }
      }
    },
  });

  useEffect(() => {
    if (props?.editStorage) {
      formik.setValues({
        Cat_Name: props?.selectedCategory?.Cat_Name,
        Sub_Cat: props?.selectedCategory?.Sub_Cat,
      });
    }
  }, []);

  return (
    <Modal
      onHide={props.onHide}
      show={props.show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div>
          <div>
            <div className="d-flex justify-content-between">
              <h6>
                {props?.editStorage
                  ? "Edit"
                  : props?.deleteSub_Cat
                    ? "Replace"
                    : "Create"}{" "}
                Sub Category
              </h6>
              <div role="button" className="text-lg" onClick={props.onHide}>
                <CloseIcon />
              </div>
            </div>
            <p className="text-muted">
              {props?.editStorage
                ? "Edit"
                : props?.deleteSub_Cat
                  ? "Replace the deleted"
                  : "Create a new"}{" "}
              Category
            </p>
          </div>
          <div className="mt-3">
            {/*  */}
            {!props?.deleteSub_Cat && (
              <>
                <div className="mb-3">
                  <label className="col-form-label fw-bold">Category Name</label>
                  <CreatableSelect
                    classNamePrefix={`form-select`}
                    options={data?.categories}
                    value={data?.categories.find(
                      (el) => el.value === formik.values.Cat_Name
                    )}
                    onChange={({ value }) =>
                      formik.setFieldValue("Cat_Name", value)
                    }
                    isDisabled={props?.selectedCategory?.id}
                  />
                </div>

                <div className="mb-3">
                  <label className="col-form-label fw-bold">Sub Category Name</label>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      value={formik.values.Sub_Cat}
                      name="Sub_Cat"
                      onChange={formik.handleChange}
                    />
                    <span className="form-control-feedback text-danger">
                      {formik.errors.name}
                    </span>
                  </div>
                </div>
              </>
            )}

            {/*  */}
            {props?.deleteSub_Cat && (
              <>
                <small className="my-2 text-danger">
                  This Category is attached to items
                </small>
                <>
                  <div className="mb-3">
                    <label className="col-form-label fw-bold">Category Name</label>
                    <CreatableSelect
                      classNamePrefix={`form-select`}
                      options={data?.categories}
                      value={data?.categories.find(
                        (el) => el.value === formik.values.Cat_Name
                      )}
                      onChange={({ value }) =>
                        formik.setFieldValue("Cat_Name", value)
                      }
                      isDisabled={props?.selectedCategory?.id}
                    />
                  </div>

                  <div className="mb-3">
                    <label className="col-form-label fw-bold">Sub Category Name</label>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        value={formik.values.Sub_Cat}
                        name="Sub_Cat"
                        onChange={formik.handleChange}
                      />
                      <span className="form-control-feedback text-danger">
                        {formik.errors.name}
                      </span>
                    </div>
                  </div>
                </>
              </>
            )}

            {/*  */}
          </div>
        </div>
      </Modal.Body>
      <div className="d-inline-flex justify-content-end m-4">
        {!props?.deleteSub_Cat && (
          <button className="btn bg print mx-4" onClick={props.onHide}>
            Cancel
          </button>
        )}
        <button className="btn btn-primary" onClick={formik.handleSubmit}>
          {props?.editStorage
            ? "Edit"
            : props?.deleteSub_Cat
              ? "Replace"
              : "Save"}{" "}
          Category
        </button>
      </div>
      <ModalLoader
        show={
          saveCategory.isLoading ||
          editCategory.isLoading ||
          replaceCategory.isLoading
        }
      />
    </Modal>
  );
};

export default AddEditSubCategoryModal;
