import { useEffect, useRef } from "react";
import { useState } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Dropdown,
  Overlay,
  Table,
} from "react-bootstrap";
import { Popover } from "react-tiny-popover";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import {
  copyText,
  customerFullName,
  formatDate,
  initialServiceItem,
  maxTopPopperConfig,
  nanoid,
  pcsToTons,
  qtyFormat,
  qtyFormatToString,
  reactSelectTheme,
  tonsToPcs,
  toTonsOrPcs,
  Units,
} from "../../utils/helpers";
import CustomerSelectModal from "../CustomerSelectModal";
import {
  AddCircleIcon,
  CreateInvoiceIcon,
  NoSelectedItemIcon,
  UserSolidIcon,
  CashSelectIcon,
  CreditSelectIcon,
  DirectTransferSelectIcon,
  ChequeSelectIcon,
  CustomerAccountSelectIcon,
  CreditMemoSelectIcon,
  EditIcon,
  DeleteIcon,
  RecieptIcon,
  DropdownCloseIcon,
} from "../Icons";
import NewCustomerModal from "../NewCustomerModal";
import NewItemModal from "../NewItemModal";
import PageHeader from "../PageHeader";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
import DatePickerCustomInput from "../utils/DatePickerCustomInput";
import ItemsTable from "../utils/ItemsTable";
import "./../../assets/scss/create-invoice.scss";
import currency from "currency.js";
import DotsVeritcalIcon from "mdi-react/DotsVerticalIcon";
import { cloneDeep, isEmpty, lowerCase } from "lodash";
import { useMemo } from "react";
import NumberCustomInput from "../utils/NumberCustomInput";
import { appSettings, services } from "../../config";
import queryString from "query-string";
import { useQuery } from "react-query";
import { queryActions, reportActions } from "../../utils/reactQueryActions";
import { useFormik, FieldArray, FormikProvider } from "formik";
import * as yup from "yup";
import Datetime from "react-datetime";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import SelectBankDialog from "../SelectBankDialog";
import useDebounce, {
  useCurrencies,
  useEffectOnce,
  useIsAdmin,
  useTaxOptions,
  useUpdateEffect,
} from "../../utils/hooks";
import AddRodItemModal from "../SalesAndInvoicing/AddRodItemModal";
import ConfirmDialog from "../ConfirmDialogue";
import EditIronRodItemModal from "../SalesAndInvoicing/EditIronRodItemModal";
import PermitModal from "../SalesAndInvoicing/PermitModal";
import { useAuth } from "../../hooks/useAuth";
import { useStoreActions, useStoreState } from "easy-peasy";
import ModalLoader from "../utils/ModalLoader";
import { first } from "lodash";
import ProformaDialog from "../ProformaDialog";
import moment from "moment";
import { truncate } from "lodash";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CachedIcon from "mdi-react/CachedIcon";

function Attachments({ files = [], setFiles, milestone }) {
  //  const [files, setFiles] = useState([]);
  const { backendUrl } = useAuth();
  return (
    <div>
      <div>
        {files?.map((el, index) => (
          <div key={index} className="d-flex align-items-center">
            {el?.fileId ? (
              <a
                href={`${backendUrl}/api/job/view-po-file/${el.systemFileName}`}
                target="_blank"
                rel="noreferrer"
                className="text-primary"
              >
                {el?.name}
              </a>
            ) : (
              <span>{el?.name}</span>
            )}

            <Button
              variant=""
              size="sm"
              onClick={(e) => setFiles(files.filter((el, i) => i !== index))}
            >
              <DropdownCloseIcon width={16} />
            </Button>
            <hr className="m-0" />
          </div>
        ))}
      </div>

      <Form.Label className="btn btn-light-blue">
        <span>Add Files </span>
        <Form.Control
          type="file"
          className="d-none"
          multiple
          onChange={(e) => setFiles([...files, ...e.target.files])}
        />
      </Form.Label>
    </div>
  );
}

export default function EditJob() {
  const paymentMethod = [
    {
      icon: <CreditMemoSelectIcon />,
      label: "Proforma",
      value: "Proforma",
    },
  ];

  const taxOptions = useTaxOptions();
  const { deploymentCurrencies: currenciesOptions } = useCurrencies();

  const saleTypes = Units;
  const generalSettings = useStoreState((state) => state.generalSettings);
  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const defaultCustomer = useStoreState((state) => state.defaultCustomer);
  const { backendUrl, user: authUser } = useAuth();
  const isAdmin = useIsAdmin();
  const [loadingPrint, setLoadingPrint] = useState(false);
  const [showCustomerSelectorModal, setShowCustomerSelectorModal] = useState(
    false
  );
  const [selectedCustomer, setSelectedCustomer] = useState(defaultCustomer);
  const [showItemSelectorModal, setShowItemSelectorModal] = useState(false);
  const [showCreateNewCustomerModal, setShowCreateNewCustomerModal] = useState(
    false
  );
  const [showPermitModal, setShowPermitModal] = useState(false);

  //  const [tableData, setTableData] = useState([]);
  const [lockedTableData, setLockedTableData] = useState([]);
  const [editedItemIndex, setEditedItemIndex] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffectOnce(() => {
    //  set Job Items
    if (location.state?.jobItems && location.state?.job) {
      populateTableFromItems(location.state?.jobItems, location.state?.job);
      //  navigate(location.pathname, { replace: true });
    }
  });

  const convertQuantity = (Quantity, Item_Desc, type) => {
    return lowerCase(type) === "tons"
      ? pcsToTons(Quantity, Item_Desc, itemMeasurements)
      : Quantity;
  };

  const createJob = async (payload) => {
    const formData = new FormData();
    formData.append("payload", JSON.stringify(payload));

    for (const file of payload.poAttachments) {
      if (!file?.fileId) {
        formData.append("files", file);
      }
    }

    formData.append(
      "alreadyUploadedFiles",
      JSON.stringify(payload.poAttachments.filter((el) => el?.fileId))
    );

    let response = await fetch(`${backendUrl}/api/job/edit`, {
      method: "POST",
      credentials: "include",
      body: formData,
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };

  const editJobMutation = useMutation((payload) => createJob(payload), {
    onSuccess: ({ data, message }) => {
      toast.success(message);
      //  formik.resetForm();
      navigate(location.pathname, { replace: true });
    },
    onError: ({ message = "" }) => {
      toast.error(`Unable to perform action: ${message}`);
    },
  });

  // permit
  const createRodPermit = async (payload) => {
    const formData = new FormData();
    formData.append("payload", JSON.stringify(payload));

    let response = await fetch(`${backendUrl}/api/job/create-pending-permit`, {
      method: "POST",
      credentials: "include",
      body: formData,
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };

  const createRodPermitMutation = useMutation(
    (payload) => createRodPermit(payload),
    {
      onSuccess: ({ message, data }) => {
        toast.success(message);
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );
  // ----------------------------------------------------------
  const formik = useFormik({
    initialValues: {
      //  tax: "",
      taxType: "None",
      PaymentType: "Proforma",
      proformaNumber: "",
      splitPaymentType: "",
      printWaybill: true,
      salesDate: moment(),
      invoiceCat: "",
      OverwriteOfficer: "Retail",
      chequeNumber: "",
      BankName: "",
      cashAmount: "",
      sendEmail: false,
      hasMilestone: true,
      dueIn: 1,
      pendingTransaction: "",
      supplyNow: true,
      ShipTo: "",
      customerBalance: "",
      amountToPayFromCustomerCredit: "",
      shippingCost: 0,
      terms: [{ text: "" }],
      remark: "",
      otherCharges: 0,
      currency: generalSettings?.prevailingCurrency,
      linkedPaymentID: "",
      jobNumber: nanoid(12, "number"),
      poNumber: "",
      title: "",
      description: "",
      tableDataInForm: [initialServiceItem()],
      poAttachments: [],
      Expected_Completion_Date: moment(),
      Contract_Award_Date: moment(),
      Job_Acquisition_Source: "",
      Job_Progress_Status: ""
    },
    validationSchema: yup.object().shape({
      PaymentType: yup.string().required(),
      // invoiceCat: yup.string().required("required"),
    }),
    onSubmit: async (values) => {
      if (isEmpty(tableData)) return toast.error(`Please add an Item`);
      if (!selectedCustomer) return toast.error(`Please select a customer`);

      // send to pending
      if (
        await ConfirmDialog({
          title: "Edit",
          description: "Are you sure, you want to save?",
        })
      ) {
        editJobMutation.mutate({
          items: tableData
            .map((el) => ({
              ...el,
              Quantity: el.Quantity,
            }))
            .filter((el) => el.Item_Name && el.Bar_Code),
          ...values,
          subTotal,
          discount,
          amountDue,
          profit,
          balance,
          amount,
          customer: selectedCustomer,
          taxValue,
          costOFSales,
          // status: "Pending",
          terms: JSON.stringify(values.terms),
          jobId: location.state.job.id,
          transID: location.state.job.transID,
        });
      }
    },
    onReset: () => {
      setTableData([]);
    },
  });

  const tableData = useMemo(() => {
    return formik.values?.tableDataInForm
      ? cloneDeep(formik.values.tableDataInForm)
      : [];
  }, [formik.values?.tableDataInForm]);

  const setTableData = (items) => {
    formik.setFieldValue("tableDataInForm", [...items]);
  };

  const showSelectBankDialog = async (props = {}) => {
    const bank = await SelectBankDialog({
      ...props,
      selectedBank: formik.values.BankName,
    });
    if (bank) {
      formik.setFieldValue("BankName", bank.bank);
      if (props.hasChequeNumber) {
        formik.setFieldValue("chequeNumber", bank.chequeNumber);
      }
    }
  };

  useEffect(() => {
    if (
      ["Credit/Debit Card", "Direct Bank Transfer"].includes(
        formik.values.PaymentType
      )
    ) {
      showSelectBankDialog();
    } else if (formik.values.PaymentType === "Cheque") {
      showSelectBankDialog({
        hasChequeNumber: true,
      });
    }
    if (formik.values.PaymentType === "Split Payment") {
      formik.setFieldValue("splitPaymentType", "card");
    } else {
      formik.setFieldValue("splitPaymentType", "");
    }
  }, [formik.values.PaymentType]);

  /* Split Payment  */
  useEffect(() => {
    if (["cheque"].includes(formik.values.splitPaymentType)) {
      showSelectBankDialog({
        hasChequeNumber: true,
      });
    } else if (
      ["card", "directTransfer"].includes(formik.values.splitPaymentType)
    ) {
      showSelectBankDialog({
        hasChequeNumber: false,
      });
    }
  }, [formik.values.splitPaymentType]);

  const fetchSetUpData = async () => {
    let response = await fetch(`${backendUrl}/api/job/set-up`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    data.acquisitions = data.acquisitions.map((el) => ({
      value: el.Job_Acquisition_Source,
      label: el.Job_Acquisition_Source,
    }));

    data.progress = data.progress.map((el) => ({
      value: el.Job_Progress_Status,
      label: el.Job_Progress_Status,
    }));

    return data;
  };

  const { data = { acquisition: [], progress: [] }, refetch, isFetching } = useQuery(
    ["JOB_SET_UP"],
    () => fetchSetUpData(),
    {
      keepPreviousData: true,
    }
  );
  const populateTableFromItems = (items, job = {}) => {

    // convert qtys and clean up
    if (isEmpty(items)) {
      formik.setValues({
        ...formik.values,
        ...job,
        salesDate: new Date(job.dateLog),
        poAttachments: job?.poAttachments ? JSON.parse(job.poAttachments) : [],
      });

      return;
    }

    items = items.map((el) => {
      // check if sales type
      const value = qtyFormat(el.QTY, el.Serial_Number, itemMeasurements).split(
        "-"
      );
      const tons = value[0];

      return {
        ...el,
        saleType: el.Serial_Number
          ? Number(tons) > 0
            ? "Tons"
            : "Pieces"
          : "",
        Item_Desc: el.Serial_Number,
        PriceSold: currency(el.PriceSold, {
          symbol: "",
          separator: "",
        }).format(),
        Quantity: currency(el.QTY, {
          symbol: "",
          separator: "",
        }).format(),
        UnitCost: currency(el.UnitCost, {
          symbol: "",
          separator: "",
        }).format(),
        Discount: currency(el.Discount, {
          symbol: "",
          separator: "",
        }).format(),
        SubTotal: currency(el.SubTotal, {
          symbol: "",
          separator: "",
        }).format(),
        Profit: currency(el.Profit, {
          symbol: "",
          separator: "",
        }).format(),
        UnitPrice: currency(el.Unit_Price, {
          symbol: "",
          separator: "",
        }).format(),
      };
    });

    // console.log(items);

    if (items) {
      formik.resetForm();

      const {
        PayType,
        VAT,
        OverwriteOfficer,
        Date_Log,
        TransactionID,
        customer,
        ProductName,
        terms,
        shippingCost,
        currency,
        otherCharges,
        remark,
        taxType,
      } = items[0];

      formik.setValues({
        ...formik.values,
        ...job,
        taxValue: VAT,
        PaymentType: PayType,
        OverwriteOfficer,
        salesDate: Date_Log,
        pendingTransaction: TransactionID,
        invoiceCat: ProductName,
        ...(terms ? { terms: JSON.parse(terms) } : {}),
        shippingCost,
        currency: currency || generalSettings?.prevailingCurrency,
        otherCharges,
        remark,
        taxType: taxType || "None",
      });
      setTableData([...items, initialServiceItem]);
      setSelectedCustomer(customer);

      // locked
      setLockedTableData(items);
    }
  };

  useEffect(() => {
    formik.setFieldValue(
      "ShipTo",
      selectedCustomer?.LastName
        ? selectedCustomer?.LastName
        : defaultCustomer?.LastName
    );
  }, [selectedCustomer]);

  const handleSelectedPermit = (permit) => {
    setShowPermitModal(false);
    populateTableFromItems(permit.items);
  };

  const handleAddItem = (item) => {
    // console.log(item);
    if (lockedTableData.find((el) => el.Bar_Code === item.Bar_Code)) {
      return toast.error(`${item.Item_Name} can't be modified`);
    }

    // if sale rep version, ensure all other items have same product_name
    if (!isEmpty(tableData) && appSettings.requireSalesRep) {
      const firstItem = first(tableData);
      console.log(firstItem);
      if (firstItem.Product_Name !== item.Product_Name) {
        return toast.error(
          `Select Items with the same manufacturer (${firstItem.Product_Name
          }), `
        );
      }
    }
    const foundIndex = tableData.findIndex(
      (el) => el.Bar_Code === item.Bar_Code
    );
    if (foundIndex === -1) {
      setTableData([...tableData, item]);
    } else {
      const oldItem = tableData[foundIndex];
      if (
        (oldItem.saleType !== item.saleType && !appSettings.isBatchStandard) ||
        oldItem.Serial_Number !== item.Serial_Number
      ) {
        setTableData([...tableData, item]);
      } else {
        tableData[foundIndex] = item;
        setTableData([...tableData]);
      }
    }

    formik.setFieldValue("invoiceCat", item.Product_Name);
    setShowItemSelectorModal(false);
  };

  const handleEditItem = (item) => {
    if (lockedTableData.find((el) => el.Bar_Code === item.Bar_Code)) {
      return toast.error(`${item.Item_Name} can't be modified`);
    }
    const foundIndex = tableData.findIndex(
      (el) =>
        el.Bar_Code === item.Bar_Code &&
        el.saleType === item.saleType &&
        el.Serial_Number === item.Serial_Number
    );

    tableData[foundIndex] = item;
    setTableData([...tableData]);
    setEditedItemIndex(null);
  };

  const handleRemoveItem = (index) => {
    setTableData([...tableData.filter((el, i) => i !== index)]);
  };

  const discard = () => {
    formik.resetForm();
    setTableData([]);
    setSelectedCustomer(defaultCustomer);
  };

  const handleRowClick = (e, index) => {
    if (e.target.tagName === "TD") setEditedItemIndex(index);
  };

  // discount sum
  const discount = useMemo(() => {
    const sum = tableData
      ? tableData
        .map(
          (el) => el.Discount
          /*  currency(el.Discount, { symbol: "", separator: "" })
            .multiply(
              convertQuantity(el.Quantity, el.Serial_Number, el.saleType)
            )
            .format() */
        )
        .reduce(
          (a, b) =>
            currency(a, {
              precision: 2,
            }).add(b),
          0
        )
      : 0.0;
    return sum
      ? currency(sum, {
        symbol: "",
        separator: "",
      }).format()
      : "0.00";
  }, [tableData]);

  const subTotal = useMemo(() => {
    const sum = tableData
      ? tableData
        .map((el) => el.SubTotal)
        .reduce(
          (a, b) =>
            currency(a, {
              symbol: "",
              precision: 2,
            }).add(b),
          0
        )
      : 0.0;
    return sum
      ? currency(sum, {
        symbol: "",
        separator: "",
      })
        .add(discount)
        .format()
      : "0.00";
  }, [tableData, discount]);

  const chargesAfterTax = useMemo(() => {
    return currency(formik.values.loadingCharge, {
      symbol: "",
      separator: "",
    })
      .add(formik.values.offloadingCharge)
      .add(formik.values.posCharge)
      .add(formik.values.transportCharge)
      .add(formik.values.shippingCost)
      .add(formik.values.otherCharges);
  }, [
    formik.values.offloadingCharge,
    formik.values.loadingCharge,
    formik.values.posCharge,
    formik.values.transportCharge,
    formik.values.shippingCost,
    formik.values.otherCharges,
  ]);

  const taxValue = useMemo(() => {
    const taxType = taxOptions.find((el) => el.value === formik.values.taxType);
    return currency(subTotal, {
      symbol: "",
      separator: "",
    })
      .multiply(taxType?.percentage)
      .format();
  }, [subTotal, formik.values.taxType]);

  const amountDue = useMemo(() => {
    const sum = tableData
      ? tableData
        .map((el) => el.SubTotal)
        .reduce(
          (a, b) =>
            currency(a, {
              precision: 2,
            }).add(b),
          0
        )
      : 0.0;

    const total = sum
      ? currency(sum, {
        symbol: "",
        separator: "",
      })
        .multiply(100)
        .divide(100)
        .add(taxValue)
        .add(chargesAfterTax)
        .format()
      : "0.00";

    const value = formik.values.PaymentType;
    if (value === "Credit" || value === "Customer Account") {
      formik.setFieldValue("amountPaid", 0);
    } else {
      formik.setFieldValue("amountPaid", total);
    }

    formik.setFieldValue("amountToPayFromCustomerCredit", total);
    formik.setFieldValue("cashAmount", total);
    return total;
  }, [tableData, taxValue, formik.values.PaymentType, chargesAfterTax]);

  const amount = useMemo(() => {
    return currency(amountDue, {
      symbol: "",
      separator: "",
    })
      .subtract(formik.values.cashAmount)
      .format();
  }, [amountDue, formik.values.cashAmount]);

  const balance = useMemo(() => {
    // is Balance Zero for split Payment
    const totalCashForSplit = currency(formik.values.cashAmount, {
      symbol: "",
      separator: "",
    })
      .add(amount)
      .format();

    return currency(amountDue, {
      symbol: "",
      separator: "",
    })
      .subtract(
        formik.values.PaymentType === "Split Payment"
          ? totalCashForSplit
          : formik.values.amountPaid
      )
      .format();
  }, [
    amount,
    amountDue,
    formik.values.amountPaid,
    formik.values.PaymentType,
    formik.values.cashAmount,
  ]);

  const profit = useMemo(() => {
    const sum = tableData
      ? tableData
        .map((el) => el.Profit)
        .reduce(
          (a, b) =>
            currency(a, {
              precision: 2,
            }).add(b),
          0
        )
      : 0.0;
    return sum
      ? currency(sum, {
        symbol: "",
        separator: "",
      }).format()
      : "0.00";
  }, [tableData]);

  /* const grandTotal = */ useEffect(() => {
    // formik.setFieldValue("jobCost", amountDue);
    // return amountDue;
  }, [amountDue]);

  const costOFSales = useMemo(() => {
    return tableData
      .map(
        (el) =>
          currency(el.UnitCost, { symbol: "", separator: "" }).multiply(
            convertQuantity(el.Quantity, el.Serial_Number, el.saleType)
          ).value
      )
      .reduce(
        (a, b) =>
          currency(a, {
            precision: 2,
          }).add(b),
        0
      );
  }, [tableData]);

  const handlePaymentMethod = (value) => {
    formik.setFieldValue("PaymentType", value);
    if (value === "Credit" || value === "Customer Account") {
      formik.setFieldValue("amountPaid", 0);
    }
  };

  const currencySymbol = useMemo(() => {
    const foundCurrency = currenciesOptions.find(
      (el) => el.cc === formik.values.currency
    );
    return foundCurrency ? foundCurrency.symbol : "";
  }, [formik.values.currency]);

  const paymentFilter = useMemo(() => {
    /*   return selectedCustomer?.Cust_ID && selectedCustomer?.Cust_ID !== "000101"
      ? { Cust_ID: selectedCustomer?.Cust_ID, Remark: "" }
      : { Remark: formik.values.ShipTo, Cust_ID: "" }; */
    return { Cust_ID: selectedCustomer?.Cust_ID, Remark: "" };
  }, [selectedCustomer?.Cust_ID /* , formik.values.ShipTo */]);

  const customerPaymentsFilter = useDebounce(paymentFilter, 800);

  const fetchPayments = async ({ Cust_ID, Remark }) => {
    let response = await fetch(
      `${backendUrl}/api/customers/get-payments?${queryString.stringify({
        Cust_ID,
        /*     Remark, */
      })}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    data.payments = [
      {
        value: "",
        label: "None",
      },
      ...data.payments.map((el) => {
        const requiresApproval = false;
        return {
          ...el,
          value: el.Trans_ID,
          label: `${el.Remark} -  ${currency(el.Credit, {
            symbol: currencySymbol,
          }).format()} - ${el?.Post_Time ? formatDate(el?.Post_Time) : ""} ${requiresApproval ? "- Requires Approval" : ""
            }`,
          requiresApproval,
        };
      }),
    ];

    return data;
  };

  const { data: paymentsData, ...paymentsDataQuery } = useQuery(
    ["CUSTOMER_PAYMENTS", customerPaymentsFilter],
    () => fetchPayments(customerPaymentsFilter),
    {
      keepPreviousData: false,
      enabled:
        generalSettings?.linkPaymentToInvoice &&
        !!customerPaymentsFilter.Cust_ID /*  || !!customerPaymentsFilter.Remark */,
    }
  );

  const calculateServiceOtherValuesOnChange = ({
    index,
    Quantity,
    PriceSold,
    UnitCost,
    Discount = 0,
  }) => {
    const Profit = currency(PriceSold)
      .subtract(UnitCost)
      .multiply(Quantity).value;

    const SubTotal = currency(PriceSold)
      .subtract(Discount)
      .multiply(Quantity).value;

    formik.setFieldValue(`tableDataInForm[${index}].Profit`, Profit);
    formik.setFieldValue(`tableDataInForm[${index}].SubTotal`, SubTotal);
    formik.setFieldValue(`tableDataInForm[${index}].Discount`, Discount);
  };

  return (
    <main className="create-invoice">
      {/*   <PageHeader
      name={`Create Job/Contract`}
      description={``}
      icon={<RecieptIcon />}
    /> */}
      <div className="p-3 content">
        {" "}
        <FormikProvider value={formik}>
          {" "}
          <Form noValidate onSubmit={formik.handleSubmit} autoComplete="off">
            <div className="d-md-flex content-holder rounded">
              <section
                /*  style={
            formik.values.pendingTransaction
              ? {
                  pointerEvents: "none",
                }
              : {}
          } */
                className="item-details"
              >
                <div>
                  <header className="d-flex justify-content-between">
                    <h2 className="h4">Edit Job/Contract Details</h2>

                    <div className="d-flex gap-3">
                      <Link
                        className="btn  bg-light-blue"
                        to={`/operations/jobs/details/${encodeURIComponent(
                          formik.values.jobNumber
                        )}`}
                      >
                        <RecieptIcon />
                        {"    "}
                        Details
                      </Link>

                      <Form.Group>
                        <Select
                          classNamePrefix="form-select"
                          placeholder="Select Currency"
                          isSearchable={false}
                          options={currenciesOptions}
                          value={currenciesOptions.find(
                            (el) => el.value === formik.values.currency
                          )}
                          onChange={({ value }) => {
                            formik.setFieldValue("currency", value);
                          }}
                        />
                      </Form.Group>
                    </div>
                  </header>

                  <div className="py-4 border-bottom">
                    <Form.Group className="form-mb align-items-center mb-3">
                      <Form.Label className="mb-1 text-nowrap fw-bold">
                        Job/Contract Number
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Job/Contract Number"
                        name="jobNumber"
                        value={formik.values.jobNumber}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.jobNumber && !!formik.errors.jobNumber
                        }
                        readOnly={
                          authUser.Department !== "Operations" && !isAdmin
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.jobNumber}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="form-mb align-items-center mb-3">
                      <Form.Label className="mb-1 text-nowrap fw-bold">
                        PO Number
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter PO Number"
                        name="poNumber"
                        value={formik.values.poNumber}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.poNumber && !!formik.errors.poNumber
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.poNumber}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="form-mb align-items-center mb-3">
                      <Form.Label>PO Attachments</Form.Label>
                      <Attachments
                        files={formik.values.poAttachments}
                        setFiles={(files) =>
                          formik.setFieldValue("poAttachments", files)
                        }
                      />
                    </Form.Group>

                    <Form.Group className="form-mb align-items-center mb-3">
                      <Form.Label className="mb-1 text-nowrap fw-bold">
                        Title
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Title"
                        name="title"
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.title && !!formik.errors.title
                        }
                        readOnly={
                          authUser.Department !== "Operations" && !isAdmin
                        }
                      />{" "}
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.title}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="form-mb align-items-center">
                      <Form.Label className="mb-1 text-nowrap fw-bold">
                        Description
                      </Form.Label>
                      <Form.Control
                        style={{ height: "8rem" }}
                        as="textarea"
                        placeholder="Enter Description"
                        name="description"
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        row={10}
                        isInvalid={
                          formik.touched.description &&
                          !!formik.errors.description
                        }
                        readOnly={
                          authUser.Department !== "Operations" && !isAdmin
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.description}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  {/*  {authUser.Department !== "Operations" && (
                  <div className="d-flex justify-content-end total-info d-none">
                    <table className="table table-borderless balance">
                      <tbody>
                        <tr>
                          <td>Subtotal</td>
                          <td>
                            {currency(subTotal, {
                              symbol: currencySymbol,
                            })
                              .add(chargesAfterTax)
                              .format()}
                          </td>
                        </tr>

                        <tr>
                          <td>Discount</td>
                          <td>

                            {currency(discount, {
                              symbol: currencySymbol,
                            }).format()}
                          </td>
                        </tr>

                        <tr>
                          <td>Amount Due</td>
                          <td>
                            {currency(amountDue, {
                              symbol: currencySymbol,
                            }).format()}
                          </td>
                        </tr>

                        <tr>
                          <td>Balance</td>
                          <td>
                            {currency(balance, {
                              symbol: currencySymbol,
                            }).format()}
                          </td>
                        </tr>

                        <tr>
                          <td>Total</td>
                          <td>

                            <CurrencyCustomInput
                              currencySymbol={currencySymbol}
                              name="jobCost"
                              value={formik.values.jobCost}
                              onValueChange={(value, name) => {
                                formik.setFieldValue(name, value);
                              }}
                              placeholder="0.00"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )} */}
                </div>
                {/*  {authUser.Department !== "Operations" && (
                  <div className="d-flex justify-content-end total-info d-none">
                    <table className="table table-borderless balance">
                      <tbody>
                        <tr>
                          <td>Subtotal</td>
                          <td>
                            {currency(subTotal, {
                              symbol: currencySymbol,
                            })
                              .add(chargesAfterTax)
                              .format()}
                          </td>
                        </tr>

                        <tr>
                          <td>Discount</td>
                          <td>

                            {currency(discount, {
                              symbol: currencySymbol,
                            }).format()}
                          </td>
                        </tr>

                        <tr>
                          <td>Amount Due</td>
                          <td>
                            {currency(amountDue, {
                              symbol: currencySymbol,
                            }).format()}
                          </td>
                        </tr>

                        <tr>
                          <td>Balance</td>
                          <td>
                            {currency(balance, {
                              symbol: currencySymbol,
                            }).format()}
                          </td>
                        </tr>

                        <tr>
                          <td>Total</td>
                          <td>

                            <CurrencyCustomInput
                              currencySymbol={currencySymbol}
                              name="jobCost"
                              value={formik.values.jobCost}
                              onValueChange={(value, name) => {
                                formik.setFieldValue(name, value);
                              }}
                              placeholder="0.00"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )} */}
              </section>
              <section className="customer">
                <h2>Customer/Client</h2>

                <div className="d-flex justify-content-between">
                  <div className="avatar">
                    <UserSolidIcon />
                  </div>
                  {selectedCustomer ? (
                    <div className="customer-actions d-flex justify-content-between flex-grow-1">
                      <div>
                        <h3>{selectedCustomer?.LastName}</h3>
                        <p>{selectedCustomer.Cust_ID}</p>
                      </div>

                      <div>
                        {!formik.values.pendingTransaction && (
                          <Dropdown style={{ margin: 0 }}>
                            <Dropdown.Toggle
                              variant=""
                              className="bg-light-blue text-primary"
                              bsPrefix="change"
                            >
                              Change
                            </Dropdown.Toggle>

                            <Dropdown.Menu
                              popperConfig={{
                                strategy: "fixed",
                              }}
                              renderOnMount
                              className=""
                            >
                              <Dropdown.Item
                                as="button"
                                type="button"
                                onClick={() =>
                                  setShowCustomerSelectorModal(true)
                                }
                              >
                                Select Customer
                              </Dropdown.Item>
                              <Dropdown.Item
                                as="button"
                                type="button"
                                onClick={() =>
                                  setShowCreateNewCustomerModal(true)
                                }
                              >
                                Create New Customer
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="customer-actions flex-grow-1">
                      <h3>No customer selected</h3>
                      <p>Select customer or create new customer.</p>

                      <div className="d-grid mt-4">
                        <Button
                          onClick={() => setShowCustomerSelectorModal(true)}
                          variant="outline-primary"
                        >
                          Select Customer
                        </Button>
                        <Button
                          onClick={() => setShowCreateNewCustomerModal(true)}
                          variant="outline-primary"
                        >
                          + Create New Customer
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
                <div className="mb-0">
                  <Form.Group className="form-mb">
                    <Form.Label className="mb-1 text-nowrap fw-bold w-100">
                      Job/Contract Cost
                    </Form.Label>
                    <CurrencyCustomInput
                      currencySymbol={currencySymbol}
                      name="jobCost"
                      value={formik.values.jobCost}
                      onValueChange={(value, name) => {
                        formik.setFieldValue(name, value);
                      }}
                      placeholder="0.00"
                    />
                  </Form.Group>
                </div>

                <hr />
                <section className="date">
                  <Row className="form-mb">
                    <Form.Group as={Col}>
                      <Form.Label>Acquisition Source  <Button variant="" onClick={() => refetch()} size="sm" className="text-primary"><CachedIcon /></Button></Form.Label>
                      <Select
                        classNamePrefix="form-select"
                        placeholder="Select"
                        options={data?.acquisitions}
                        value={data?.acquisitions?.find(el => el.value === formik.values.Job_Acquisition_Source)}
                        onChange={(selected) => formik.setFieldValue('Job_Acquisition_Source', selected?.value)}
                        isLoading={isFetching}
                      />
                    </Form.Group>

                    <Form.Group as={Col}>
                      <Form.Label>Progress Status <Button variant="" onClick={() => refetch()} size="sm" className="text-primary"><CachedIcon /></Button></Form.Label>
                      <Select
                        classNamePrefix="form-select"
                        placeholder="Select"
                        options={data?.progress}
                        value={data?.progress?.find(el => el.value === formik.values.Job_Progress_Status)}
                        onChange={(selected) => formik.setFieldValue('Job_Progress_Status', selected?.value)}
                        isLoading={isFetching}
                      />
                    </Form.Group>
                  </Row>
                </section>
                <hr />

                <div className="d-flex flex-wrap">


                  <Form.Group className="form-mb">
                    <Form.Label className="mb-1 text-nowrap fw-bold">
                      Entry Date
                    </Form.Label>

                    <Datetime
                      timeFormat={false}
                      closeOnSelect={true}
                      closeOnClickOutside={true}
                      dateFormat="MMM DD, YYYY"
                      name="salesDate"
                      inputProps={{
                        className: `date-input form-control ${formik.touched.salesDate &&
                          !!formik.errors.salesDate
                          ? "is-invalid"
                          : ""
                          }`,
                        placeholder: "Select date",
                        readOnly: true,
                      }}
                      value={formik.values.salesDate}
                      onChange={(date) => {
                        formik.setFieldValue("salesDate", date, true);
                      }}
                      onBlur={() => formik.setFieldTouched("salesDate", true)}
                    />
                  </Form.Group>{" "}

                  <Form.Group className="form-mb">
                    <Form.Label className="mb-1 text-nowrap fw-bold">
                      Contract Award Date
                    </Form.Label>

                    <Datetime
                      timeFormat={false}
                      closeOnSelect={true}
                      closeOnClickOutside={true}
                      dateFormat="MMM DD, YYYY"
                      name="Contract_Award_Date"
                      inputProps={{
                        className: `date-input form-control ${formik.touched.Contract_Award_Date &&
                          !!formik.errors.Contract_Award_Date
                          ? "is-invalid"
                          : ""
                          }`,
                        placeholder: "Select date",
                        readOnly: true,
                      }}
                      value={formik.values.Contract_Award_Date}
                      onChange={(date) => {
                        formik.setFieldValue("Contract_Award_Date", date, true);
                      }}
                      onBlur={() => formik.setFieldTouched("Contract_Award_Date", true)}
                    />
                  </Form.Group>{" "}

                  <Form.Group className="form-mb">
                    <Form.Label className="mb-1 text-nowrap fw-bold">
                      Expected Completion Date
                    </Form.Label>

                    <Datetime
                      timeFormat={false}
                      closeOnSelect={true}
                      closeOnClickOutside={true}
                      dateFormat="MMM DD, YYYY"
                      name="Expected_Completion_Date"
                      inputProps={{
                        className: `date-input form-control ${formik.touched.Expected_Completion_Date &&
                          !!formik.errors.Expected_Completion_Date
                          ? "is-invalid"
                          : ""
                          }`,
                        placeholder: "Select date",
                        readOnly: true,
                      }}
                      value={formik.values.Expected_Completion_Date}
                      onChange={(date) => {
                        formik.setFieldValue("Expected_Completion_Date", date, true);
                      }}
                      onBlur={() => formik.setFieldTouched("Expected_Completion_Date", true)}
                    />
                  </Form.Group>{" "}
                </div>



                <hr />


                <section className="buttons">
                  <Button
                    type="button"
                    variant="outline-primary"
                    // className="border-0"
                    onClick={() => discard()}
                  >
                    Discard
                  </Button>
                  <Button type="submit" variant="primary">
                    Save
                  </Button>
                </section>
              </section>
            </div>{" "}
          </Form>
        </FormikProvider>
      </div>

      {/*   Modals */}

      {showCustomerSelectorModal && (
        <CustomerSelectModal
          setShowCustomerSelectorModal={setShowCustomerSelectorModal}
          setSelectedCustomer={setSelectedCustomer}
          selectedCustomer={selectedCustomer}
          withCredit={true}
        />
      )}

      {showItemSelectorModal && (
        <AddRodItemModal
          showItemSelectorModal={showItemSelectorModal}
          setShowItemSelectorModal={setShowItemSelectorModal}
          handleAddItem={handleAddItem}
          saleTypes={saleTypes}
          lockedTableData={lockedTableData}
          selectedCustomer={selectedCustomer}
        />
      )}

      {showCreateNewCustomerModal && (
        <NewCustomerModal
          showCreateNewCustomerModal={showCreateNewCustomerModal}
          setShowCreateNewCustomerModal={setShowCreateNewCustomerModal}
          setSelectedCustomer={setSelectedCustomer}
        />
      )}

      {editedItemIndex !== null && (
        <EditIronRodItemModal
          setEditedItemIndex={setEditedItemIndex}
          handleEditItem={handleEditItem}
          selectedItemToEdit={{
            ...tableData[editedItemIndex],
            //  quantityInStock: tableData[editedItemIndex].Quantity,
          }}
          saleTypes={saleTypes}
        />
      )}

      {showPermitModal && (
        <PermitModal
          setShowPermitModal={setShowPermitModal}
          handleSelectedPermit={handleSelectedPermit}
          batchData={true}
        />
      )}

      <ModalLoader show={editJobMutation.isLoading || loadingPrint} />
    </main>
  );
}
