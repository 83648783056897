import {
  Button,
  Form,
  Table,
  Overlay,
  InputGroup,
  Dropdown,
} from "react-bootstrap";
import { CSVLink } from "react-csv";
import DetailedIcon, {
  CalendarIcon,
  ExportIcon,
  FilterCollapseIcon,
  FilterIcon,
  PrintIcon,
  ExcelIcon,
  PDFIcon,
  EditIcon,
  MoneyDollarIcon,
} from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import CachedIcon from "mdi-react/CachedIcon";
import { useEffect, useState, useRef, useMemo } from "react";
import {
  FilterTwoIcon,
  SummaryChartIconUp,
  SummaryChartIconDown,
  SummaryCloseIcon,
} from "../Icons";
import useDebounce, {
  useEffectOnce,
  useIsAdmin,
  useIsHMB,
  useIsRSMOH,
  useIsSales,
  useIsStore,
  useIsWaiter,
  useLocalStorage,
  useQueryParams,
  useScrollTop,
} from "../../utils/hooks";
import {
  appSettings,
  backendApis,
  services,
  supplyOptions,
  supplyOptionsMin,
} from "../../config";
import queryString from "query-string";
import { useQuery } from "react-query";
import { reportActions } from "../../utils/reactQueryActions";
import { endOfDay, format, parse, startOfDay } from "date-fns";
import {
  customerFullName,
  employeeFullName,
  fetchActionsUtil,
  formatForQty,
  getStockQuantityAfter,
  getStockQuantityBefore,
  paginationOptions,
  qtyFormat,
  qtyFormatToString,
  reactSelectTheme,
  transactionFromOptions,
} from "../../utils/helpers";
import ReactPaginate from "react-paginate";
import currency from "currency.js";
import Select from "react-select";
import ReactDatePicker from "react-datepicker";
import DatePickerCustomInput from "./../utils/DatePickerCustomInput";
import CustomerSelectorPopover from "../utils/CustomerSelectorPopover";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import { isEmpty, isNull, uniqBy } from "lodash";
import { useAuth } from "../../hooks/useAuth";
import { useStoreActions, useStoreState } from "easy-peasy";
import ItemsTable from "../utils/ItemsTable";
import { Popover } from "react-tiny-popover";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import NoTableItem from "../utils/NoTableItem";
import { useDownloadExcel } from "../../hooks/useDownloadExcel";
import ModalLoader from "../utils/ModalLoader";
import TableComponent from "../TableComponent";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ConvertQuantity from "../utils/ConvertQuantity";
import SaleItemsTable from "../utils/SaleItemsTable";
import EyeOutlineIcon from "mdi-react/EyeOutlineIcon";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import ChartBarIcon from "mdi-react/ChartBarIcon";
import { useSearchParams } from "react-router-dom";
import { SalesAnalysisChart } from "../Chart/SalesAnalysisChart";
import { IsPrivileged } from "../DisplayChildElement";
import { OverlayTooltip } from "../Component";
import ItemSaleAnalysisModal from "../ItemSaleAnalysisModal";
import { toast } from "react-toastify";
import TransactionsTable from "../utils/TransactionsTable";

export default function SalesAnalysis({ isWarehouse }) {
  useScrollTop();
  const navigate = useNavigate();

  const reportTypeOptions = [
    {
      value: "Detailed",
      label: "Detailed",
    },
    {
      value: "Summary",
      label: "Summary",
    },
    {
      value: "Full",
      label: "Full",
    },
    {
      value: "State/City",
      label: "State/City",
    },
  ];

  const initialFilterParams = {
    reportType: "Detailed",
    startDate: format(new Date(), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
    customerId: "",
    //  searchInDate: true,
    isBatchStandard: appSettings.isBatchStandard,
    ProductName: "",
    Warehouse: "All",
    Cat_Name: "",
    requireSalesRep: appSettings.requireSalesRep,
  };

  const isStore = useIsStore();
  const location = useLocation();
  const isHMB = useIsHMB();
  const isRSMOH = useIsRSMOH();
  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const { backendUrl, isIronRod, isCement, user } = useAuth();
  const isAdmin = useIsAdmin();
  const [btnSummryActive, setBtnSummaryActive] = useState(false);
  const [showMoreSummary, setShowMoreSummary] = useState(true);
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);
  const [showCustomerSelector, setShowCustomerSelector] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [excelData, setExcelData] = useState([]);
  // const [searchParams, setSearchParams] = useSearchParams();
  const generalSettings = useStoreState((state) => state?.generalSettings);
  const [showSalesAnalysisChart, setShowSalesAnalysisChart] = useState(false);
  const [selectedSale, setSelectedSale] = useState();
  const [showItemSaleAnalysisModal, setShowItemSaleAnalysisModal] = useState();
  // const isSales = useIsSales();
  const isWaiter = useIsWaiter();
  const CSVLinkRef = useRef(null);
  const isExternalBody = useMemo(() => {
    return isHMB || isRSMOH;
  }, [isHMB, isRSMOH]);

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(
    excelData,
    CSVLinkRef
  );

  const [consolidate, setConsolidate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newData, setNewData] = useState([]);
  const [showLoadingTitle, setShowLoadingTitle] = useState(null);
  const [showTransactionPopover, setShowTransactionPopover] = useState(false);

  const close = () => {
    setShowMoreSummary(true);
    setBtnSummaryActive(false);
  };

  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,

    // Get the date in url and parse so date picker can use it
    date: queryParams?.date
      ? parse(queryParams?.date, "yyyy-MM-dd", new Date())
      : "",
  });

  useEffectOnce(() => {
    if (location.state && location.state?.customer) {
      setSelectedCustomer(location.state?.customer);
    }
  });

  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({
      ...queryParams,
      ...debouncedFilterParams,
    });
  }, [debouncedFilterParams]);

  const fetchSales = async (queryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/reports/sales-analysis?&${queryString.stringify(
        queryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    data.items = data.items.map((el) => ({
      label: el.Item_Name,
      value: el.Item_Name,
    }));

    data.branch = [
      {
        label: "All",
        value: "",
      },
      ...data.branch
        .map((el) => ({
          label: el.Branch,
          value: el.Branch,
        }))
        .filter((el) => Boolean(el.value)),
    ];

    data.salesRep = data.salesRep
      .map((el) => ({
        label: employeeFullName(el),
        value: el.EmployeeID,
      }))
      .filter((el) => el.label);

    data.overwriteOfficer = data.overwriteOfficer.map((el) => ({
      label: el.OverwriteOfficer,
      value: el.OverwriteOfficer,
    }));

    data.type = data.type.map((el) => ({
      label: el.Item_Type,
      value: el.Item_Type,
    }));

    data.productName = [
      {
        label: "All",
        value: "",
      },
      ...data.productName
        .map((el) => ({
          label: el.ProductName,
          value: el.ProductName,
        }))
        .filter((el) => el.value),
    ];

    data.states = [
      {
        label: "All",
        value: "",
      },
      ...data.states
        .map((el) => ({
          label: el.state,
          value: el.state,
        }))
        .filter((el) => el.value),
    ];

    data.lgas = [
      {
        label: "All",
        value: "",
      },
      ...data.lgas
        .map((el) => ({
          label: el.LGA,
          value: el.LGA,
        }))
        .filter((el) => el.value),
    ];

    data.salesWarehouse = [
      {
        label: "All",
        value: "All",
      },
      {
        label: "Sales Inventory",
        value: null,
      },
      ...data.salesWarehouse
        .map((el) => ({
          label: el.W_name,
          value: el.W_ID,
        }))
        .filter((el) => el.label !== null),
    ];

    data.saleCategories = [
      {
        label: "All",
        value: "",
      },
      ...data.saleCategories
        .map((el) => ({
          label: el.Cat_Name,
          value: el.Cat_Name,
        }))
        .filter((el) => el.value),
    ];

    //console.log(data);
    return data;
  };

  const {
    data = {
      count: 0,
      sales: [],
      quantityInStockRemaining: 0,
      productName: [],
      states: [],
      lgas: [],
      salesUsers: [],
      salesWarehouse: [],
      saleCategories: [],
    },
    refetch,
    isFetching,
    isSuccess,
  } = useQuery(
    [reportActions.GET_SALES, queryParams],
    () =>
      fetchSales({
        ...queryParams,
        isWaiter,
        user: user?.Name,
        //  Cust_ID:  user.Staff_ID,
      }),
    {
      keepPreviousData: true,
    }
  );

  const companyUsers = useMemo(() => {
    let currentUsers = data?.salesUsers?.map((user) => ({
      label: user?.UserName,
      value: user?.UserName,
    }));

    return currentUsers;
  }, [data]);

  // download excel function
  const { limit, page, ...rest } = queryParams;
  const newQuery = {
    ...rest,
    isCement,
    isAdmin,
    isIronRod,
    isHMB: isExternalBody,
    isExternalBody,
  };

  const onDownloadExcelData = async () => {
    let exData = await fetchExcelData(
      `${backendUrl}/api/reports/sales-analysis?${queryString.stringify(rest)}`,
      "GET"
    );

    const company = exData.data?.company?.CompName;

    const warehouse = [
      {
        label: "Sales Inventory",
        value: null,
      },
      ...exData?.data?.salesWarehouse
        .map((el) => ({
          label: el.W_name,
          value: el.W_ID,
        }))
        .filter((el) => el.label !== null),
    ];

    exData =
      queryParams.reportType !== "Summary"
        ? exData.data.sales.map((row) => ({
          ...row,
          Date_Log: format(new Date(row?.Date_Log), "dd-MMM-yyyy hh:mm:ss a"),
        }))
        : exData.data.sales;

    const date =
      rest.startDate && rest.endDate
        ? `Date Prepared: Between ${format(
          new Date(rest.startDate),
          "E MMM d yyyy k:mm:ss z"
        )} to ${format(new Date(rest.endDate), "E MMM d yyyy k:mm:ss z")}`
        : "";

    const Summary = [
      "S/N",
      isCement ? "ATC" : "Item Code",
      "Item Name",
      "QTY Sold",
      "Subtotal",
      isAdmin ? "Profit" : isCement ? "Loading Charge" : "",
      "Warehouse",
      isCement ? "Offloading Charge" : "",
    ];

    const SummaryData = exData.map((d, i) => [
      i + 1,
      d.Bar_Code,
      d.Item_Name,
      isIronRod
        ? qtyFormatToString(
          qtyFormat(d.qtySold, d.Serial_Number, itemMeasurements)
        )
        : d.qtySold,

      currency(d.SubTotal, {
        symbol: "",
      }).format(),
      isAdmin
        ? currency(d.Profit, {
          symbol: "",
        }).format()
        : isCement
          ? currency(d?.deliveryInfo?.LoadingCharge, {
            symbol: "",
          }).format()
          : "",
      warehouse?.find((wh) => wh.value === d.Warehouse)?.label,
      isCement
        ? currency(d?.deliveryInfo?.OffloadingCharge, {
          symbol: "",
        }).format()
        : "",
    ]);

    const Full = [
      "S/N",
      "Date",
      isCement ? "ATC" : "Item Code",
      "Description",
      "QTY",
      "Price",
      "Subtotal",
      "Discount",
      "VAT",
      "Amount",
      isAdmin ? "Profit" : "",
      "Valuation",
      "Cost",
      "Sales Type",
      "Cashier",
      "Invoice Number",
      "Manufacturer",
      "Customer",
      "Process By",
      "Warehouse",
      "Orderby",
      isCement ? "Loading Charge" : "",
      isCement ? "Offloading Charge" : "",
    ];

    const FullData = exData.map((d, i) => [
      i + 1,
      d.Date_Log ? format(new Date(d.Date_Log), "yyyy-MM-dd") : "...",
      d.Bar_Code,
      d.Item_Name,
      isIronRod
        ? qtyFormatToString(qtyFormat(d.QTY, d.Serial_Number, itemMeasurements))
        : d.QTY,

      currency(d.PriceSold, {
        symbol: "",
      }).format(),
      currency(d.SubTotal, {
        symbol: "",
      }).format(),

      currency(d.Discount, {
        symbol: "",
      }).format(),
      0,
      currency(d.SubTotal, {
        symbol: "",
      }).format(),
      isAdmin
        ? currency(d.Profit, {
          symbol: "",
        }).format()
        : "",
      currency(d.UnitCost, {
        symbol: "",
      })
        .multiply(d.QTY)
        .format(),
      currency(d.UnitCost, {
        symbol: "",
      }).format(),
      d.OverwriteOfficer,
      d.UserName,
      d.TransactionID,
      d.ProductName,
      // customerFullName(d.customer),
      d?.customer?.LastName === "Walk-In"
        ? `${d?.transaction?.ShipTo} (Walk-In)`
        : `${customerFullName(d.customer)} ${d?.TransactionFrom ? d?.TransactionFrom : ""
        }`,
      d.UserName,
      warehouse?.find((wh) => wh.value === d.Warehouse)?.label,
      "",
      isCement
        ? currency(d?.deliveryInfo?.LoadingCharge, {
          symbol: "",
        }).format()
        : "",
      isCement
        ? currency(d?.deliveryInfo?.OffloadingCharge, {
          symbol: "",
        }).format()
        : "",
    ]);

    const Detailed = [
      ...(generalSettings.itemsRequiresSerialNumber ? ["Serial Number"] : []),
      queryParams?.customerId ? "Customer" : "",
      isCement ? "ATC" : "Item Code",
      !queryParams?.customerId ? "Item Name" : "",
      "QTY",
      "Price Sold",
      !isExternalBody ? "Unit Cost" : "",
      "Subtotal",
      isExternalBody ? "Shipping Status" : "",
      isAdmin ? "Profit" : "",
      "Warehouse",
      !queryParams?.customerId ? "Sales Date" : "",
      isCement ? "Loading Charge" : "",
      isCement ? "Offloading Charge" : "",
    ];

    const DetailedData = exData.map((d) => [
      ...(generalSettings.itemsRequiresSerialNumber
        ? [d.SerialNumberItems]
        : []),
      queryParams?.customerId
        ? d?.customer?.LastName === "Walk-In"
          ? `${d?.transaction?.ShipTo} (Walk-In)`
          : `${customerFullName(d.customer)} ${d?.TransactionFrom ? d?.TransactionFrom : ""
          }`
        : "",
      d.Bar_Code,
      !queryParams?.customerId ? d.Item_Name : "",
      isIronRod
        ? qtyFormatToString(qtyFormat(d.QTY, d.Serial_Number, itemMeasurements))
        : d.QTY,
      currency(d.PriceSold, {
        symbol: "",
      }).format(),
      !isExternalBody
        ? currency(d.UnitCost, {
          symbol: "",
        }).format()
        : "",
      currency(d.SubTotal, {
        symbol: "",
      }).format(),
      isExternalBody ? d?.Shipping_Status : "",
      isAdmin
        ? currency(d.Profit, {
          symbol: "",
        }).format()
        : "",
      warehouse?.find((wh) => wh.value === d.Warehouse)?.label,
      !queryParams?.customerId ? d.Date_Log : "",

      isCement
        ? currency(d?.deliveryInfo?.LoadingCharge, {
          symbol: "",
        }).format()
        : "",

      isCement
        ? currency(d?.deliveryInfo?.OffloadingCharge, {
          symbol: "",
        }).format()
        : "",
    ]);

    const headers =
      queryParams.reportType === "Summary"
        ? Summary
        : queryParams.reportType === "Full"
          ? Full
          : queryParams.reportType === "Detailed"
            ? Detailed
            : [""];

    exData =
      queryParams.reportType === "Summary"
        ? SummaryData
        : queryParams.reportType === "Full"
          ? FullData
          : queryParams.reportType === "Detailed"
            ? DetailedData
            : [""];

    exData = [
      [company],
      ["Sales Analysis Report"],
      [date],
      [""],
      headers,
      ...exData,
      [""],
      ["Summary"],
      [
        "Total Sales",
        currency(data.totalSales, {
          symbol: "",
        }).format(),
      ],
      [
        "Total Cost",
        currency(data.totalCost, {
          symbol: "",
        }).format(),
      ],
      isAdmin
        ? [
          "Total Profit",
          currency(data.totalProfit, {
            symbol: "",
          }).format(),
        ]
        : [""],
      [
        "Quantity Sold",
        currency(data.qtySold, {
          symbol: "",
          precision: 2,
          format: formatForQty,
        }).format(),
      ],
      isCement
        ? [
          "Total Loading Charge",
          currency(data.totalLoadingCharge, {
            symbol: "",
            precision: 2,
          }).format(),
        ]
        : [""],
      isCement
        ? [
          "Total Offloading Charge",
          currency(data.totalOffloadingCharge, {
            symbol: "",
            precision: 2,
          }).format(),
        ]
        : [""],
    ];
    // console.log(exData);
    setExcelData(exData);
  };

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      // ...searchParamsToObject(searchQuery.entries()),
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectedCustomer = (customer) => {
    setSelectedCustomer(customer);
    setShowCustomerSelector(false);
    // search({ customerId: customer.Cust_ID });
    setFilterParams({
      ...filterParams,
      customerId: customer.Cust_ID,
    });
  };

  const clearSelectedCustomer = () => {
    // search({ customerId: "" });
    setSelectedCustomer(null);
    setFilterParams({
      ...filterParams,
      customerId: "",
    });
  };

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const search = (params) => {
    setQueryParams({
      ...queryParams,
      ...filterParams,

      // format the date, before setting it in the url
      date: filterParams?.date
        ? format(new Date(filterParams?.date), "yyyy-MM-dd")
        : "",
      ...params,
    });
  };

  const reset = () => {
    setFilterParams(initialFilterParams);
    setQueryParams({
      ...queryParams,
      ...initialFilterParams,
    });
  };

  const handleSelectedItem = (item) => {
    setFilterParams({
      ...filterParams,
      barcode: item.Bar_Code,
      itemName: item.Item_Name,
    });
    setShowItemsPopover(false);
  };

  const SummaryTableHead = () => {
    return (
      <thead>
        <tr>
          <th />
          <th>S/N</th>
          <th>{isCement ? "ATC" : "Item Code"}</th>
          <th>Item Name</th>
          <th>QTY Sold</th>
          <th>Subtotal</th>
          {isAdmin ? <th>Profit</th> : null}
          <th>Warehouse</th>
          {/*  <th>Sales Date</th> */}
          {isCement || !appSettings.isBatchStandard ? (
            <>
              <th>Loading Charge</th>
              <th>Offloading Charge</th>
              {isIronRod ? (
                <>
                  <th>Transport Charge</th>
                  <th>Pos Charge</th>
                </>
              ) : null}
            </>
          ) : null}
          {consolidate && <th>Branch</th>}
        </tr>
      </thead>
    );
  };

  const SummaryTableData = (el, index) => {
    return (
      <>
        {" "}
        <td>
          <TableDropdown
            TransactionID={el.TransactionID}
            sale={el}
            setSelectedSale={setSelectedSale}
            setShowItemSaleAnalysisModal={setShowItemSaleAnalysisModal}
          />
        </td>
        <td>{data?.startIndex + index + 1}</td>
        <td>{el.Bar_Code}</td>
        <td>{el.Item_Name}</td>
        <td>
          <ConvertQuantity quantity={el.qtySold} desc={el.Serial_Number} />
        </td>
        <td>
          {currency(el.SubTotal, {
            symbol: "",
          }).format()}
        </td>
        {isAdmin ? (
          <td>
            {currency(el.Profit, {
              symbol: "",
            }).format()}
          </td>
        ) : null}
        <td>
          {data.salesWarehouse?.find((wh) => wh.value === el.Warehouse)?.label}
        </td>
        {/*  <td>
                      {el.Date_Log
                        ? format(new Date(el.Date_Log), "yyyy-MM-dd")
                        : "..."}
                    </td> */}
        {isCement || !appSettings.isBatchStandard ? (
          <>
            <td>
              {currency(el?.deliveryInfo?.LoadingCharge, {
                symbol: "",
              }).format()}
            </td>
            <td>
              {currency(el?.deliveryInfo?.OffloadingCharge, {
                symbol: "",
              }).format()}
            </td>
            {isIronRod ? (
              <>
                <td>
                  {currency(el?.deliveryInfo?.TransportCharge, {
                    symbol: "",
                  }).format()}
                </td>
                <td>
                  {currency(el?.deliveryInfo?.PosCharge, {
                    symbol: "",
                  }).format()}
                </td>
              </>
            ) : null}
          </>
        ) : null}
        {consolidate && <td>{el?.company}</td>}
      </>
    );
  };

  const FullTableHead = () => {
    return (
      <thead>
        <tr>
          <th />
          <th>S/N</th>
          <th>Date</th>
          <th>{isCement ? "ATC" : "Item Code"}</th>
          <th>Description</th>
          <th>QTY</th>

          <th>Price</th>
          <th>Subtotal</th>
          <th>Discount</th>
          <th>VAT</th>
          <th>Amount</th>
          {isAdmin ? <th>Profit</th> : null}
          <th>Valuation</th>
          <th>Cost</th>
          <th>Sales Type</th>
          <th>Cashier</th>
          <th>Invoice Number</th>
          <th>Manufacturer</th>
          <th>Cust ID</th>
          <th>Business Name</th>
          <th>Process By</th>
          <th>Warehouse</th>
          {appSettings.requireSalesRep && <th>Sales Rep</th>}
          <th>Orderby</th>

          {isCement || !appSettings.isBatchStandard ? (
            <>
              <th>Loading Charge</th>
              <th>Offloading Charge</th>
              {isIronRod ? (
                <>
                  <th>Transport Charge</th>
                  <th>Pos Charge</th>
                </>
              ) : null}
            </>
          ) : null}
          {consolidate && <th>Branch</th>}
        </tr>
      </thead>
    );
  };

  const FullTableData = (el, index) => {
    return (
      <>
        <td>
          <TableDropdown
            TransactionID={el.TransactionID}
            sale={el}
            setSelectedSale={setSelectedSale}
            setShowItemSaleAnalysisModal={setShowItemSaleAnalysisModal}
          />
        </td>
        <td>{data?.startIndex + index + 1}</td>
        <td>
          {el.Date_Log ? format(new Date(el.Date_Log), "yyyy-MM-dd") : "..."}
        </td>
        <td>{el.Bar_Code}</td>
        <td>{el.Item_Name}</td>
        <td>
          <ConvertQuantity quantity={el.QTY} desc={el.Serial_Number} />
        </td>

        <td>
          {currency(el.PriceSold, {
            symbol: "",
          }).format()}
        </td>
        <td>
          {currency(el.SubTotal, {
            symbol: "",
          }).format()}
        </td>
        <td>
          {currency(el.Discount, {
            symbol: "",
          }).format()}
        </td>
        <td>{0}</td>
        <td>
          {currency(el.SubTotal, {
            symbol: "",
          }).format()}
        </td>
        {isAdmin ? (
          <td>
            {currency(el.Profit, {
              symbol: "",
            }).format()}
          </td>
        ) : null}
        <td>
          {currency(el.UnitCost, {
            symbol: "",
          })
            .multiply(el.QTY)
            .format()}
        </td>
        <td>
          {currency(el.UnitCost, {
            symbol: "",
          }).format()}
        </td>
        <td>{el.OverwriteOfficer}</td>
        <td>{el.UserName}</td>
        <td>{el.TransactionID}</td>
        <td>{el.ProductName}</td>
        <td>{el.Cust_ID}</td>
        <td>
          {" "}
          {el?.customer?.LastName === "Walk-In"
            ? `${el?.transaction?.ShipTo} (Walk-In)`
            : customerFullName(el.customer)}
          {el?.TransactionFrom && ` (${el?.TransactionFrom})`}
        </td>
        <td>{el.UserName}</td>
        <td>
          {data.salesWarehouse?.find((wh) => wh.value === el.Warehouse)?.label}
        </td>
        {appSettings.requireSalesRep && (
          <td>{employeeFullName(el?.Sale_Rep_Employees)}</td>
        )}
        <td> </td>
        {isCement || !appSettings.isBatchStandard ? (
          <>
            <td>
              {currency(el?.deliveryInfo?.LoadingCharge, {
                symbol: "",
              }).format()}
            </td>
            <td>
              {currency(el?.deliveryInfo?.OffloadingCharge, {
                symbol: "",
              }).format()}
            </td>
            {isIronRod ? (
              <>
                <td>
                  {currency(el?.deliveryInfo?.TransportCharge, {
                    symbol: "",
                  }).format()}
                </td>
                <td>
                  {currency(el?.deliveryInfo?.PosCharge, {
                    symbol: "",
                  }).format()}
                </td>
              </>
            ) : null}
          </>
        ) : null}
        {consolidate && <td>{el?.company}</td>}
      </>
    );
  };

  const DetailedTableHead = () => {
    return (
      <thead>
        <tr>
          <th />
          <th>Transaction ID</th>
          <th>Cust ID</th>
          {/* queryParams?.customerId && */ <th>Business Name</th>}
          {generalSettings?.itemsRequiresSerialNumber && (
            <>
              <th>Serial Number</th>
            </>
          )}
          <th>{isCement ? "ATC" : "Item Code"}</th>
          <th>Item Name</th>
          {(isStore || isAdmin) &&
            !generalSettings?.itemsRequiresSerialNumber ? (
            <>
              <th>QTY Before Sale </th>
              <th>QTY Sold </th>
              <th>QTY After Sale </th>
            </>
          ) : (
            <>
              <th>QTY Sold</th>
            </>
          )}
          <th>Price Sold</th>
          {!isExternalBody ? <th>Unit Cost</th> : null}
          <th>Subtotal</th>
          {isAdmin ? <th>Profit</th> : null}
          <th>Warehouse</th>
          {appSettings.requireSalesRep && <th>Sales Rep</th>}
          {(!queryParams?.customerId || isExternalBody) && <th>Sales Date</th>}
          {(isCement || !appSettings.isBatchStandard) && !isExternalBody ? (
            <>
              <th>Loading Charge</th>
              <th>Offloading Charge</th>
              {isIronRod ? (
                <>
                  <th>Transport Charge</th>
                  <th>Pos Charge</th>
                </>
              ) : null}
            </>
          ) : null}

          {isExternalBody && <th>Shipping Status</th>}
          {consolidate && <th>Branch</th>}
        </tr>
      </thead>
    );
  };

  const DetailedTableData = (el, index) => {
    return (
      <>
        <td>
          <TableDropdown
            TransactionID={el.TransactionID}
            sale={el}
            setSelectedSale={setSelectedSale}
            setShowItemSaleAnalysisModal={setShowItemSaleAnalysisModal}
          />
        </td>
        <td>{el.TransactionID}</td>
        <td>{el.Cust_ID}</td>
        <td>
          {el?.customer?.LastName === "Walk-In"
            ? `${el?.transaction?.ShipTo} (Walk-In)`
            : customerFullName(el.customer)}{" "}
          {el?.TransactionFrom && ` (${el?.TransactionFrom})`}
        </td>
        {generalSettings?.itemsRequiresSerialNumber && (
          <td>{el?.SerialNumberItems}</td>
        )}
        <td>{el.Bar_Code}</td>
        <td>{el.Item_Name}</td>
        {(isStore || isAdmin) && !generalSettings?.itemsRequiresSerialNumber ? (
          <>
            <td>
              <ConvertQuantity
                quantity={getStockQuantityBefore(el.Warranty)}
                desc={el.Serial_Number}
              />
            </td>

            <td>
              <ConvertQuantity quantity={el.QTY} desc={el.Serial_Number} />
            </td>

            <td>
              <ConvertQuantity
                quantity={getStockQuantityAfter(el.Warranty)}
                desc={el.Serial_Number}
              />
              {el.Shipping_Status !== "Supplied" && (
                <OverlayTooltip
                  text={`${el.Shipping_Status} `}
                  component={<span className="text-info">*</span>}
                />
              )}
            </td>
          </>
        ) : (
          <>
            <td>
              <ConvertQuantity quantity={el.QTY} desc={el.Serial_Number} />
            </td>
          </>
        )}

        <td>
          {currency(el.PriceSold, {
            symbol: "",
          }).format()}
        </td>
        {!isExternalBody ? (
          <td>
            {currency(el.UnitCost, {
              symbol: "",
            }).format()}{" "}
            {el.Shipping_Status !== "Supplied" && (
              <OverlayTooltip
                text={`${el.Shipping_Status
                  } from Batch: Amount might change, if Supplied from another Batch`}
                component={<span className="text-info">*</span>}
              />
            )}
          </td>
        ) : null}
        <td>
          {currency(el.SubTotal, {
            symbol: "",
          }).format()}
        </td>
        {isAdmin ? (
          <td>
            {currency(el.Profit, {
              symbol: "",
            }).format()}{" "}
            {el.Shipping_Status !== "Supplied" && (
              <OverlayTooltip
                text={`${el.Shipping_Status
                  } from Batch: Amount might change, if Supplied from another Batch`}
                component={<span className="text-info">*</span>}
              />
            )}
          </td>
        ) : null}
        <td>
          {data.salesWarehouse?.find((wh) => wh.value === el.Warehouse)?.label}
        </td>
        {appSettings.requireSalesRep && (
          <td>{employeeFullName(el?.Sale_Rep_Employees)}</td>
        )}

        {(!queryParams?.customerId || isExternalBody) && (
          <td>
            {el.Date_Log ? format(new Date(el.Date_Log), "yyyy-MM-dd") : "..."}
          </td>
        )}
        {(isCement || !appSettings.isBatchStandard) && !isExternalBody ? (
          <>
            <td>
              {currency(el?.deliveryInfo?.LoadingCharge, {
                symbol: "",
              }).format()}
            </td>
            <td>
              {currency(el?.deliveryInfo?.OffloadingCharge, {
                symbol: "",
              }).format()}
            </td>
            {isIronRod ? (
              <>
                <td>
                  {currency(el?.deliveryInfo?.TransportCharge, {
                    symbol: "",
                  }).format()}
                </td>
                <td>
                  {currency(el?.deliveryInfo?.PosCharge, {
                    symbol: "",
                  }).format()}
                </td>
              </>
            ) : null}
          </>
        ) : null}

        {isExternalBody && <td>{el?.Shipping_Status}</td>}
        {consolidate && <td>{el?.company}</td>}
      </>
    );
  };

  const stateAndCityTableHead = () => {
    return (
      <thead>
        <tr>
          <th />
          <th>Transaction ID</th>
          <th>Cust ID</th>
          <th>Business Name</th>
          <th>State</th>
          <th>City</th>
          {generalSettings?.itemsRequiresSerialNumber && (
            <>
              <th>Serial Number</th>
            </>
          )}
          <th>{isCement ? "ATC" : "Item Code"}</th>
          <th>Item Name</th>
          <th>QTY Sold</th>
          <th>Price Sold</th>
          <th>Unit Cost</th>
          <th>Subtotal</th>
          {isAdmin ? <th>Profit</th> : null}
          <th>Warehouse</th>
          <th>Sales Date</th>
          {consolidate && <th>Branch</th>}
        </tr>
      </thead>
    );
  };

  const stateAndCityTableData = (el, index) => {
    return (
      <>
        <td>
          <TableDropdown
            TransactionID={el.TransactionID}
            sale={el}
            setSelectedSale={setSelectedSale}
            setShowItemSaleAnalysisModal={setShowItemSaleAnalysisModal}
          />
        </td>
        <td>{el.TransactionID}</td>
        <td>{el.Cust_ID}</td>
        <td>
          {el?.customer?.LastName === "Walk-In"
            ? `${el?.transaction?.ShipTo} (Walk-In)`
            : customerFullName(el.customer)}{" "}
          {el?.TransactionFrom && ` (${el?.TransactionFrom})`}
        </td>
        <td>{el.state || "..."}</td>
        <td>{el.LGA || "..."}</td>
        {generalSettings?.itemsRequiresSerialNumber && (
          <td>{el?.SerialNumberItems}</td>
        )}
        <td>{el.Bar_Code}</td>
        <td>{el.Item_Name}</td>
        <td>
          <ConvertQuantity quantity={el.QTY} desc={el.Serial_Number} />
        </td>
        <td>
          {currency(el.PriceSold, {
            symbol: "",
          }).format()}
        </td>
        {!isExternalBody ? (
          <td>
            {currency(el.UnitCost, {
              symbol: "",
            }).format()}
          </td>
        ) : null}
        <td>
          {currency(el.SubTotal, {
            symbol: "",
          }).format()}
        </td>
        {isAdmin ? (
          <td>
            {currency(el.Profit, {
              symbol: "",
            }).format()}
          </td>
        ) : null}
        <td>
          {data.salesWarehouse?.find((wh) => wh.value === el.Warehouse)?.label}
        </td>
        {(!queryParams?.customerId || isExternalBody) && (
          <td>
            {el.Date_Log ? format(new Date(el.Date_Log), "yyyy-MM-dd") : "..."}
          </td>
        )}
        {consolidate && <td>{el?.company}</td>}
      </>
    );
  };

  const manageTransaction = (event, el) => {
    if (event.detail === 2 && el.TransactionID) {
      if (isExternalBody) {
        return navigate(`/hmb-rsmoh/manage-transaction`, {
          state: {
            TransactionID: el.Trans_ID,
          },
        });
      }
      navigate(`/inventory-for-sales/manage-transaction`, {
        state: {
          TransactionID: el.TransactionID,
        },
      });
    }
  };

  const warehouseLocal = JSON.parse(
    window.localStorage.getItem("warehouseName")
  );

  useEffect(() => {
    if (isWarehouse) {
      setFilterParams({
        ...filterParams,
        Warehouse: warehouseLocal?.whID,
      });
    }
  }, [isWarehouse]);

  const consolidateItems = async (filters) => {
    setShowLoadingTitle("Consolidating...");
    setIsLoading(true);

    const consItems = await Promise.all(
      backendApis.map((endPoints) => {
        return new Promise(async (resolve, reject) => {
          try {
            const items = await fetchActionsUtil(
              `${endPoints.url
              }/api/reports/sales-analysis-public?&${queryString.stringify(
                filters
              )}`
            );
            const mainItems = items?.data?.sales?.map((el) => ({
              ...el,
              company: endPoints.name,
            }));
            resolve(mainItems);
          } catch (error) {
            console.log(error);
            reject(error);
          }
        });
      })
    );

    setShowLoadingTitle("Consolidating Completed ✔️");
    setIsLoading(false);
    return consItems;
  };

  useEffect(() => {
    const changeDisplay = async () => {
      try {
        if (consolidate && !filterParams.startDate) {
          scrollToMonth();
          return toast.info(
            "Select Date Range, so that other branches (BU) can be consolidated"
          );
        }

        let QData = data?.sales;
        if (consolidate) {
          QData = await consolidateItems(queryParams);

          QData = QData?.flat(1);
        } else {
          QData = data?.sales;
        }
        setNewData(QData);
      } catch (error) {
        console.log({ error });
      }
    };

    changeDisplay();
  }, [consolidate, queryParams, data?.sales, filterParams.startDate]);

  const monthRef = useRef(null);

  const scrollToMonth = () => {
    monthRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const onConsolidate = () => {
    if (!consolidate && !filterParams.startDate) {
      scrollToMonth();
      return toast.info(
        "Select Date Range, so that other branches (BU) can be consolidated"
      );
    }
    setConsolidate(!consolidate);
  };


  const handleSelectedTransaction = (transaction) => {
    // console.log(transaction)
    setFilterParams({
      ...filterParams,
      TransactionID: transaction.TransactionID,
      ...(transaction?.Date_Log ? {
        startDate: format(startOfDay(new Date(transaction?.Date_Log)), "yyyy-MM-dd"),
        endDate: format(endOfDay(new Date(transaction?.Date_Log)), "yyyy-MM-dd")
      } : {})
    });
    setShowTransactionPopover(false);
  };

  return (
    <IsPrivileged roleName={["Sales Analysis", "Sales History"]}>
      <main className="cash-book">
        <div className="content">
          <div className="content-main">
            <div className={`filter-area ${showFilter ? "open" : "close"}`}>
              <div className="filter-header">
                <h2>
                  <FilterTwoIcon /> Filters
                </h2>

                <Button
                  variant="white"
                  onClick={() => setShowFilter(!showFilter)}
                >
                  <FilterCollapseIcon />
                </Button>
              </div>
              <div className="filter-body">
                <Form>
                  <div className="body">
                    {!isWarehouse && (
                      <Form.Group className="mb-2-5">
                        <Form.Label>Warehouse</Form.Label>
                        <Select
                          menuPosition="fixed"
                          menuPlacement="auto"
                          placeholder="Warehouse"
                          theme={reactSelectTheme}
                          classNamePrefix={"form-select"}
                          options={data.salesWarehouse || []}
                          value={data.salesWarehouse?.find(
                            (el) => el.value === filterParams?.Warehouse
                          )}
                          isSearchable={true}
                          onChange={({ value }) =>
                            setFilterParams({
                              ...filterParams,
                              Warehouse: value,
                            })
                          }
                        />
                      </Form.Group>
                    )}

                    {generalSettings.store && !isWaiter && (
                      <Form.Group className="mb-2-5">
                        <Form.Label>Users</Form.Label>
                        <Select
                          classNamePrefix="form-select"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          name="users"
                          placeholder="All"
                          theme={reactSelectTheme}
                          isSearchable={true}
                          onChange={(selected) => {
                            setFilterParams({
                              ...filterParams,
                              users: selected.value,
                            });
                          }}
                          value={companyUsers?.find(
                            (el) => el.value === filterParams.user
                          )}
                          options={companyUsers || []}
                        />
                      </Form.Group>
                    )}
                    {generalSettings.store && (
                      <Form.Group className="mb-2-5">
                        <Form.Label>From</Form.Label>
                        <Select
                          classNamePrefix="form-select"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          name="branch"
                          placeholder="All"
                          theme={reactSelectTheme}
                          isSearchable={true}
                          onChange={(selected) => {
                            setFilterParams({
                              ...filterParams,
                              TransactionFrom: selected.value,
                            });
                          }}
                          value={transactionFromOptions.find(
                            (el) => el.value === filterParams.TransactionFrom
                          )}
                          options={transactionFromOptions}
                        />
                      </Form.Group>
                    )}

                    <Form.Group className="mb-2-5">
                      <Form.Label>Branch</Form.Label>
                      <Select
                        classNamePrefix="form-select"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        name="branch"
                        placeholder="All"
                        theme={reactSelectTheme}
                        isSearchable={true}
                        key={data?.branch}
                        onChange={(selected) => {
                          setFilterParams({
                            ...filterParams,
                            branch: selected.value,
                          });
                        }}
                        defaultValue={
                          queryParams?.branch && data?.branch
                            ? data?.branch.find(
                              (el) => el.value === queryParams?.branch
                            )
                            : {
                              value: "",
                              label: "All",
                            }
                        }
                        options={data?.branch}
                      />
                    </Form.Group>

                    <Form.Group className="mb-2-5">
                      <Form.Label>Sales Rep</Form.Label>
                      <Select
                        classNamePrefix="form-select"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        name="salesRep"
                        placeholder="All"
                        theme={reactSelectTheme}
                        isSearchable={true}
                        key={data?.salesRep}
                        onChange={(selected) => {
                          setFilterParams({
                            ...filterParams,
                            salesRep: selected.value,
                          });
                        }}
                        defaultValue={
                          queryParams?.salesRep && data?.salesRep
                            ? data?.salesRep.find(
                              (el) => el.value === queryParams?.salesRep
                            )
                            : {
                              value: "",
                              label: "All",
                            }
                        }
                        options={data?.salesRep}
                      />
                    </Form.Group>

                    <Form.Group className="mb-2-5">
                      <Form.Label>Product / Manufacturer</Form.Label>
                      <Select
                        name="ProductName"
                        classNamePrefix="form-select"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        placeholder="All"
                        key={data?.ProductName}
                        onChange={(selected) => {
                          setFilterParams({
                            ...filterParams,
                            ProductName: selected.value,
                          });
                        }}
                        value={
                          data?.productName.find(
                            (el) => el.value === queryParams?.ProductName
                          ) || ""
                        }
                        options={data?.productName || []}
                      />
                    </Form.Group>

                    <Form.Group className="mb-2-5">
                      <Form.Label>Category</Form.Label>
                      <Select
                        name="ProductName"
                        classNamePrefix="form-select"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        placeholder="All"
                        key={data?.Cat_Name}
                        onChange={(selected) => {
                          setFilterParams({
                            ...filterParams,
                            Cat_Name: selected.value,
                          });
                        }}
                        value={
                          data?.saleCategories.find(
                            (el) => el.value === queryParams?.Cat_Name
                          ) || ""
                        }
                        options={data?.saleCategories || []}
                      />
                    </Form.Group>

                    <Form.Group className="mb-2-5">
                      <Form.Label>Sales Type</Form.Label>
                      <Select
                        name="salesType"
                        classNamePrefix="form-select"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        placeholder="All"
                        isSearchable={false}
                        key={data?.overwriteOfficer}
                        onChange={(selected) => {
                          setFilterParams({
                            ...filterParams,
                            salesType: selected.value,
                          });
                        }}
                        defaultValue={
                          queryParams?.salesType && data?.overwriteOfficer
                            ? data?.overwriteOfficer.find(
                              (el) => el.value === queryParams?.salesType
                            )
                            : {
                              value: "",
                              label: "All",
                            }
                        }
                        options={data?.overwriteOfficer || []}
                      />
                    </Form.Group>

                    <Form.Group className="mb-2-5">
                      <Form.Label>Report Type</Form.Label>
                      <Select
                        classNamePrefix="form-select"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        placeholder="All"
                        theme={reactSelectTheme}
                        isSearchable={false}
                        onChange={(selected) => {
                          setFilterParams({
                            ...filterParams,
                            reportType: selected.value,
                          });
                        }}
                        defaultValue={
                          queryParams?.reportType
                            ? reportTypeOptions.find(
                              (el) => el.value === queryParams?.reportType
                            )
                            : reportTypeOptions[0]
                        }
                        options={reportTypeOptions}
                        isDisabled={isExternalBody}
                      />
                    </Form.Group>

                    {filterParams?.reportType === "State/City" && (
                      <>
                        <Form.Group className="mb-2-5">
                          <Form.Label>State</Form.Label>
                          <Select
                            classNamePrefix="form-select"
                            menuPosition="fixed"
                            menuPlacement="auto"
                            placeholder="All"
                            theme={reactSelectTheme}
                            isSearchable={false}
                            onChange={(selected) => {
                              setFilterParams({
                                ...filterParams,
                                state: selected.value,
                                LGA: "",
                              });
                            }}
                            defaultValue={data?.states.find(
                              (el) => el.value === queryParams?.state
                            )}
                            options={data?.states}
                            isDisabled={isExternalBody}
                          />
                        </Form.Group>

                        <Form.Group className="mb-2-5">
                          <Form.Label>City</Form.Label>
                          <Select
                            classNamePrefix="form-select"
                            menuPosition="fixed"
                            menuPlacement="auto"
                            placeholder="All"
                            theme={reactSelectTheme}
                            isSearchable={false}
                            onChange={(selected) => {
                              setFilterParams({
                                ...filterParams,
                                LGA: selected.value,
                              });
                            }}
                            value={data?.lgas.find(
                              (el) => el.value === queryParams?.LGA
                            )}
                            options={data?.lgas}
                            isDisabled={isExternalBody}
                            isLoading={isFetching}
                          />
                        </Form.Group>
                      </>
                    )}

                    <hr />
                    <Form.Group className="mb-2-5">
                      <Form.Label>Business Name</Form.Label>
                      <Select
                        classNamePrefix="form-select"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        placeholder="All"
                        theme={reactSelectTheme}
                        isSearchable={false}
                        options={[
                          {
                            value: "All Customers",
                            label: "All Customers",
                          },
                          {
                            value: "Selected Customer",
                            label: "Selected Customer",
                          },
                        ]}
                        onChange={(selected) =>
                          selected.value === "Selected Customer"
                            ? setShowCustomerSelector(true)
                            : clearSelectedCustomer()
                        }
                      />
                      <CustomerSelectorPopover
                        showCustomerSelector={showCustomerSelector}
                        setShowCustomerSelector={setShowCustomerSelector}
                        handleSelectedCustomer={handleSelectedCustomer}
                        selectedCustomer={selectedCustomer}
                      />
                    </Form.Group>

                    <Form.Group className="mb-2-5">
                      <Form.Label>Type</Form.Label>
                      <Select
                        classNamePrefix="form-select"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        name="type"
                        placeholder="All"
                        theme={reactSelectTheme}
                        isSearchable={true}
                        key={data?.type}
                        onChange={(selected) => {
                          setFilterParams({
                            ...filterParams,
                            type: selected.value,
                          });
                        }}
                        defaultValue={
                          queryParams?.type && data?.type
                            ? data?.type.find(
                              (el) => el.value === queryParams?.type
                            )
                            : {
                              value: "",
                              label: "All",
                            }
                        }
                        options={data?.type}
                      />
                    </Form.Group>

                    {generalSettings?.itemsRequiresSerialNumber && (
                      <Form.Group className="mb-2-5">
                        <Form.Label>Serial Number</Form.Label>
                        <Form.Control
                          name="SerialNumberItems"
                          value={filterParams?.SerialNumberItems}
                          placeholder="Enter Serial Number"
                          onChange={(event) => {
                            setFilterParams({
                              ...filterParams,
                              SerialNumberItems: event.target.value,
                            });
                          }}
                        />
                      </Form.Group>
                    )}

                    <Form.Group className="mb-2-5">
                      <Form.Label>Item Code</Form.Label>

                      <InputGroup>
                        <Form.Control
                          name="barcode"
                          onChange={(e) => handleFilterParamsChange(e)}
                          defaultValue={queryParams.barcode}
                          value={filterParams.barcode}
                          placeholder="Input Barcode"
                        />

                        <Popover
                          isOpen={showItemsPopover}
                          onClickOutside={() => setShowItemsPopover(false)}
                          content={() => (
                            <SaleItemsTable
                              handleSelectedItem={handleSelectedItem}
                            />
                          )}
                          position="bottom"
                        >
                          <InputGroup.Text
                            onClick={() =>
                              setShowItemsPopover(!showItemsPopover)
                            }
                          >
                            <MagnifyIcon />
                          </InputGroup.Text>
                        </Popover>
                      </InputGroup>
                    </Form.Group>

                    <Form.Group className="mb-2-5">
                      <Form.Label>Item Name</Form.Label>
                      <Form.Control
                        name="itemName"
                        value={filterParams?.itemName}
                        placeholder="Enter Item Name"
                        onChange={(e) => { }}
                      />
                    </Form.Group>


                    <Form.Group className="mb-2-5">
                      <Form.Label>Transaction ID</Form.Label>{" "}
                      <InputGroup>
                        <Form.Control
                          name="TransactionID"
                          value={filterParams?.TransactionID}
                          placeholder="Enter Transaction ID"
                          onChange={(e) => handleFilterParamsChange(e)}
                        />{" "}
                        <Popover
                          isOpen={showTransactionPopover}
                          onClickOutside={() =>
                            setShowTransactionPopover(false)
                          }
                          content={() => (
                            <TransactionsTable
                              handleSelectedTransaction={
                                handleSelectedTransaction
                              }
                            />
                          )}
                          position="bottom"
                        >
                          <InputGroup.Text
                            onClick={() =>
                              setShowTransactionPopover(!showTransactionPopover)
                            }
                          >
                            <MagnifyIcon />
                          </InputGroup.Text>
                        </Popover>{" "}
                      </InputGroup>
                    </Form.Group>

                    <hr />

                    {/*   <Form.Group className="mb-2-5">
                    <Form.Label>Date</Form.Label>
                    <div>
                      <ReactDatePicker
                        selected={filterParams?.date}
                        onChange={(date) =>
                          setFilterParams({
                            ...filterParams,
                            date,
                          })
                        }
                        customInput={<DatePickerCustomInput />}
                        dateFormat="MMMM dd, yyyy"
                        showPopperArrow={false}
                        placeholderText="Select date"
                      />
                    </div>
                  </Form.Group>

                  <div className="mb-2-5">
                    <Form.Check
                      inline
                      label="Search in Date"
                      name="searchInDate"
                      type={"checkbox"}
                      id={`searchInDate`}
                      defaultChecked={JSON.parse(
                        filterParams?.searchInDate || true
                      )}
                      onChange={(e) => handleFilterParamsChange(e)}
                    />
                  </div> */}

                    <Form.Group className="mb-2-5" ref={monthRef}>
                      <Form.Label>Date Range</Form.Label>
                      <div className="position-relative">
                        <RsDateRangePicker
                          placement="auto"
                          value={
                            filterParams.startDate && filterParams.endDate
                              ? [
                                parse(
                                  filterParams.startDate,
                                  "yyyy-MM-dd",
                                  new Date()
                                ),
                                parse(
                                  filterParams.endDate,
                                  "yyyy-MM-dd",
                                  new Date()
                                ),
                              ]
                              : []
                          }
                          onClean={() => clearDateRange()}
                          onOk={(date) => filterByDateRange(date)}
                        />
                      </div>
                    </Form.Group>
                  </div>

                  <div className="buttons rounded">
                    <Button onClick={() => reset()} variant="white">
                      Reset
                    </Button>
                    <Button onClick={() => search()} variant="primary">
                      Search
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
            <div className="content-body">
              <header>
                <h1 className="d-flex align-items-center gap-3">
                  {!showFilter && (
                    <button
                      onClick={() => setShowFilter(!showFilter)}
                      className="btn filter"
                    >
                      <FilterTwoIcon />
                    </button>
                  )}
                  Sales/Cash Spreadsheet
                  <button
                    title="Refresh"
                    onClick={() => refetch()}
                    className="btn text-primary"
                  >
                    <CachedIcon />
                  </button>
                  {selectedCustomer && (
                    <p>
                      Business Name: <br />{" "}
                      <b>{customerFullName(selectedCustomer)}</b>
                    </p>
                  )}
                  {queryParams.barcode && data?.itemFromStore ? (
                    <p>
                      {data?.itemFromStore.Item_Name}, Quantity in Stock: <br />{" "}
                      <b>
                        {data?.itemFromStore ? (
                          <ConvertQuantity
                            quantity={data.quantityInStockRemaining}
                            desc={data.itemFromStore.Item_Desc}
                          />
                        ) : null}
                      </b>
                    </p>
                  ) : null}
                </h1>

                <div className="actions z-10">
                  <Form.Check
                    type="switch"
                    label="Consolidate"
                    className="ga-form-check fs-6"
                    checked={consolidate}
                    onChange={() => onConsolidate()}
                  />
                  <Select
                    menuPosition="fixed"
                    menuPlacement="auto"
                    placeholder="Shipping Status"
                    classNamePrefix={"form-select"}
                    options={supplyOptionsMin}
                    value={supplyOptionsMin.find(
                      (el) => el.value === filterParams?.Shipping_Status
                    )}
                    isSearchable={false}
                    onChange={({ value }) =>
                      setFilterParams({
                        ...filterParams,
                        Shipping_Status: value,
                      })
                    }
                    isClearable
                  />
                  <CSVLink
                    className="btn print d-none"
                    filename={`sales-analysis(${format(
                      new Date(),
                      "dd-MMM-yyyy hh:mm:ss a"
                    )}).csv`}
                    data={excelData}
                    ref={CSVLinkRef}
                  />

                  {/* <button /> */}
                  <Dropdown>
                    <Dropdown.Toggle
                      variant=""
                      className="btn print"
                      disabled={isfetchingExcel}
                      bsPrefix=""
                    >
                      Export
                      <ExportIcon color="#008000" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      popperConfig={{
                        strategy: "fixed",
                      }}
                      renderOnMount
                      className="text-center"
                    >
                      <Dropdown.Item as="div">
                        <a
                          href={`${backendUrl}/api/reports/excel/sales-analysis?${queryString.stringify(
                            newQuery
                          )}`}
                          // target="blank"
                          title="Export"
                          download
                        >
                          Excel <ExcelIcon color="#008000" />
                        </a>
                      </Dropdown.Item>

                      <Dropdown.Item as="div">
                        <a
                          href={`${backendUrl}/api/reports/pdf/sales-analysis?${queryString.stringify(
                            newQuery
                          )}`}
                          target="blank"
                        >
                          PDF
                          <PDFIcon color="#ff0000" />
                        </a>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Button
                    variant=""
                    className="print text-black"
                    onClick={() => setShowSalesAnalysisChart(true)}
                  >
                    <ChartBarIcon /> Chart
                  </Button>
                  <button
                    onClick={() => setBtnSummaryActive(!btnSummryActive)}
                    className="btn summaryBtn "
                  >
                    Show Summary
                  </button>
                </div>
              </header>

              <div className="px-md-4">
                <TableComponent
                  responsive
                  borderless
                  striped
                  tableHeadsFunction={
                    queryParams.reportType === "Summary"
                      ? SummaryTableHead
                      : queryParams.reportType === "Full"
                        ? FullTableHead
                        : queryParams.reportType === "Detailed"
                          ? DetailedTableHead
                          : queryParams.reportType === "State/City"
                            ? stateAndCityTableHead
                            : null
                  }
                  mainDataArray={newData}
                  tableDataRowFunction={
                    queryParams.reportType === "Summary"
                      ? SummaryTableData
                      : queryParams.reportType === "Full"
                        ? FullTableData
                        : queryParams.reportType === "Detailed"
                          ? DetailedTableData
                          : queryParams.reportType === "State/City"
                            ? stateAndCityTableData
                            : null
                  }
                  className="product-table text-nowrap"
                  tableRowClick={(e, el) => manageTransaction(e, el)}
                />

                {!isFetching && isSuccess && isEmpty(newData) ? (
                  <NoTableItem queryParams={queryParams} />
                ) : null}
              </div>

              {!consolidate && (
                <>
                  {!data?.paginationDisabled ? (
                    <div className="d-flex justify-content-between px-3 align-items-center pagination">
                      <div className="pagination_left">
                        <p className="m-0 p-0">Show</p>

                        <select
                          value={queryParams.limit}
                          name="limit"
                          className="form-select "
                          onChange={(e) => handleSearchQueryChange(e)}
                        >
                          <option value="10">10 rows</option>
                          <option value="20">20 rows</option>
                          <option value="30">30 rows</option>
                          <option value="40">40 rows</option>
                          <option value="50">50 rows</option>
                          <option value="100">100 rows</option>
                        </select>
                      </div>

                      <ReactPaginate
                        {...paginationOptions}
                        pageCount={data.count / queryParams.limit}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={0}
                        onPageChange={({ selected }) => {
                          document.body.scrollTop = document.documentElement.scrollTop = 0;
                          setQueryParams({
                            ...queryParams,
                            page: selected + 1,
                          });
                        }}
                        forcePage={queryParams.page - 1}
                      />
                    </div>
                  ) : (
                    <div className="py-2" />
                  )}
                </>
              )}
              {/* <div className="p-4 border-top">
          <h2>Summary</h2>
          <div className="summary">
            <table className="table table-borderless balance">
              <tbody>
                <tr>
                  <td>Total Sales</td>
                  <td>218,134,177.92</td>
                </tr>

                <tr>
                  <td>Total Profit</td>
                  <td>9,276,961.63</td>
                </tr>

                <tr>
                  <td>Total Losses</td>
                  <td>N/A</td>
                </tr>

                <tr>
                  <td>Qty Sold</td>
                  <td>19884</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> */}

              <div
                className={
                  btnSummryActive
                    ? `summaryNew border-top open`
                    : `summaryNew border-top`
                }
              >
                <div className="summaryHeader">
                  <h2>Summary</h2>
                  {btnSummryActive && (
                    <button onClick={() => close()} className="btn p-0">
                      <SummaryCloseIcon />
                    </button>
                  )}
                </div>

                <div className="gridCont">
                  <div className="gridChild gridChildBorderLeftBlue">
                    <div className="gridChildLeft">
                      <p className="gridChld1">
                        {currency(data.totalSales, {
                          symbol: "",
                        }).format()}
                      </p>
                      <p className="gridChld2">Total Sales</p>
                    </div>
                  </div>

                  {!isExternalBody && (
                    <div className="gridChild gridChildBorderLeftOrange">
                      <div className="gridChildLeft">
                        <p className="gridChld1">
                          {currency(data.totalCost, {
                            symbol: "",
                          }).format()}
                        </p>
                        <p className="gridChld2">Total Cost</p>
                      </div>
                    </div>
                  )}

                  {isAdmin && (
                    <div className="gridChild gridChildBorderLeftOrange">
                      <div className="gridChildLeft">
                        <p className="gridChld1">
                          {currency(data.totalProfit, {
                            symbol: "",
                          }).format()}
                        </p>
                        <p className="gridChld2">Total Profit</p>
                      </div>
                    </div>
                  )}

                  <div className="gridChild gridChildBorderLeftBrown d-none">
                    <div className="gridChildLeft">
                      <p className="gridChld1">{0}</p>
                      <p className="gridChld2">Total Losses</p>
                    </div>
                  </div>

                  <div className="gridChild gridChildBorderLeftDarkGreen">
                    <div className="gridChildLeft">
                      <p className="gridChld1">
                        {currency(data.qtySold, {
                          symbol: "",
                          precision: 2,
                          format: formatForQty,
                        }).format()}
                      </p>
                      <p className="gridChld2">Quantity Sold</p>
                    </div>
                  </div>

                  {(isCement || !appSettings.isBatchStandard) &&
                    !isExternalBody ? (
                    <>
                      <div className="gridChild gridChildBorderLeftDarkPurple">
                        <div className="gridChildLeft">
                          <p className="gridChld1">
                            {currency(data.totalLoadingCharge, {
                              symbol: "",
                              precision: 2,
                            }).format()}
                          </p>
                          <p className="gridChld2">Total Loading Charge</p>
                        </div>
                      </div>

                      <div className="gridChild gridChildBorderLeftBrownGreen">
                        <div className="gridChildLeft">
                          <p className="gridChld1">
                            {currency(data.totalOffloadingCharge, {
                              symbol: "",
                              precision: 2,
                            }).format()}
                          </p>
                          <p className="gridChld2">Total Offloading Charge</p>
                        </div>
                      </div>

                      {isIronRod && !appSettings.isBatchStandard ? (
                        <>
                          <div className="gridChild gridChildBorderLeftBrownGreen">
                            <div className="gridChildLeft">
                              <p className="gridChld1">
                                {currency(data.totalTransportCharge, {
                                  symbol: "",
                                  precision: 2,
                                }).format()}
                              </p>
                              <p className="gridChld2">
                                Total Transport Charge
                              </p>
                            </div>
                          </div>
                          <div className="gridChild gridChildBorderLeftBrownGreen">
                            <div className="gridChildLeft">
                              <p className="gridChld1">
                                {currency(data.totalPosCharge, {
                                  symbol: "",
                                  precision: 2,
                                }).format()}
                              </p>
                              <p className="gridChld2">Total Pos Charge</p>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        {showSalesAnalysisChart && (
          <SalesAnalysisChart
            showSalesAnalysisChart={showSalesAnalysisChart}
            setShowSalesAnalysisChart={setShowSalesAnalysisChart}
            dateRange={
              filterParams.startDate && filterParams.endDate
                ? {
                  startDate: filterParams.startDate,
                  endDate: filterParams.endDate,
                }
                : {}
            }
          />
        )}

        {showItemSaleAnalysisModal && selectedSale ? (
          <ItemSaleAnalysisModal
            selectedSale={selectedSale}
            setSelectedSale={setSelectedSale}
            showItemSaleAnalysisModal={showItemSaleAnalysisModal}
            setShowItemSaleAnalysisModal={setShowItemSaleAnalysisModal}
          />
        ) : null}
        <ModalLoader
          show={isfetchingExcel || isLoading}
          title={showLoadingTitle}
        />
      </main>
    </IsPrivileged>
  );
}

function TableDropdown({
  TransactionID,
  sale,
  setSelectedSale,
  setShowItemSaleAnalysisModal,
}) {
  const navigate = useNavigate();
  const isHMB = useIsHMB();
  const isRSMOH = useIsRSMOH();
  const isAdmin = useIsAdmin();

  const isExternalBody = useMemo(() => {
    return isHMB || isRSMOH;
  }, [isHMB, isRSMOH]);

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle
          variant=""
          className="bg-white border-0"
          bsPrefix="print more"
        >
          <DotsVerticalIcon />
        </Dropdown.Toggle>
        <Dropdown.Menu
          popperConfig={{
            strategy: "fixed",
          }}
          renderOnMount
          className="dropdown-with-icons"
        >
          <Dropdown.Item
            as="button"
            onClick={() => {
              if (isExternalBody) {
                navigate(`/hmb-rsmoh/manage-transaction`, {
                  state: {
                    TransactionID,
                  },
                });
                return;
              }
              navigate(`/inventory-for-sales/manage-transaction`, {
                state: {
                  TransactionID,
                },
              });
            }}
          >
            <EyeOutlineIcon className="text-light" />
            Open Transaction
          </Dropdown.Item>
          {appSettings.hasIncentive && (
            <Dropdown.Item
              as="button"
              onClick={() => {
                setSelectedSale(sale);
                setShowItemSaleAnalysisModal(true);
              }}
            >
              <MoneyDollarIcon className="text-light" />
              Price Analysis
            </Dropdown.Item>
          )}

          {isAdmin && !isExternalBody ? (
            <Dropdown.Item as="div">
              <Link to={`/sales-and-invoicing/edit-invoice/${TransactionID}`}>
                <EditIcon /> Edit Invoice
              </Link>
            </Dropdown.Item>
          ) : null}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
